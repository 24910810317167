import axiosInstance from '../axiosInstance';
import React, { useEffect, useState } from 'react';
import { Button, Card, Form, InputGroup, ListGroup, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';

function DisburseFinanceChecking() {
  const { disburseId } = useParams();
  const { serverIP } = useSelector(store => store.allsettings);
  const [disburse, setDisburse] = useState([]);

  const [modalEdit, setModalEdit] = useState(false);
  const [checkId, setCheckId] = useState('');
  const [checksignedby, setChecksignedby] = useState('');

  useEffect(() => {
    getDisbursement();
  }, []);

  const getDisbursement = () => {
    axiosInstance
      .get(`${serverIP}/disbursements/disbursements/${disburseId}`)
      .then(res => {
        setDisburse(res.data);
        console.log(res.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  function getDateDifferenceInDays(dateString) {
    // Parse the given date string into a Date object
    const givenDate = new Date(dateString);
    // Get today's date
    const today = new Date();
    // Calculate the difference in time (milliseconds)
    const differenceInTime = givenDate - today;
    // Convert the time difference from milliseconds to days
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
    return differenceInDays;
  }

  const updateCheckDetail = () => {
    // console.log("updateCheckDetail");
    axiosInstance
      .patch(`${serverIP}/disbursements/disbursements/${disburseId}`, {
        financePerson: data.id,
        checkId,
        checksignedby,
        checkissuedby: data.id
      })
      .then(res => {
        getDisbursement();
        toast.success('success');
        console.log(res.data);
        setModalEdit(false);
      })
      .catch(error => {
        toast.error('error');

        console.log(error);
        setModalEdit(false);
      });
  };

  return (
    <div className="container">
      <ToastContainer />

      <Modal show={modalEdit} onHide={() => setModalEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title> የቼክ መረጃ አስተካክል </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">የቼክ ቁጥር </InputGroup.Text>
            <Form.Control
              placeholder="የቼክ ቁጥር"
              aria-describedby="basic-addon1"
              value={checkId}
              onChange={e => setCheckId(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">ቼክ ፈራሚ ሰዎች </InputGroup.Text>
            <Form.Control
              placeholder="ቼክ ፈራሚ ሰዎች"
              aria-describedby="basic-addon1"
              value={checksignedby}
              onChange={e => setChecksignedby(e.target.value)}
            />
          </InputGroup>

          {/* <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">ቼክ የተቆረጠበት ቀን </InputGroup.Text>
            <Form.Control
              placeholder="ቼክ የተቆረጠበት ቀን "
              aria-describedby="basic-addon1"
              value={checksignedby}
              onChange={(e) => setChecksignedby(e.target.value)}
            />
          </InputGroup>

          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1"> ቼክ የተሰጠበት ቀን </InputGroup.Text>
            <Form.Control
              placeholder="ቼክ የተሰጠበት ቀን"
              aria-describedby="basic-addon1"
              value={checksignedby}
              onChange={(e) => setChecksignedby(e.target.value)}
            />
          </InputGroup> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={() => updateCheckDetail()}>
            አስተካክል
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="row">
        <div className="col-sm-6">
          <Card>
            <Card.Header>የወጪ መረጃ </Card.Header>
            <Card.Body>
              <ListGroup as="ul" style={{ padding: 0 }}>
                <ListGroup.Item>መጠን: {disburse.amount?.toLocaleString()}</ListGroup.Item>
                <ListGroup.Item>ያለበት ሁኔታ: {disburse.isDisbursed ? 'ወስዷል' : 'አልወሰደም'}</ListGroup.Item>
                <ListGroup.Item>የቀጠሮ ቀን: {disburse.appointmentDate}</ListGroup.Item>
                <ListGroup.Item>የቀሩ ቀናት: {getDateDifferenceInDays(disburse.appointmentDate) + ' ቀናት '}</ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </div>
        <div className="col-sm-6">
          <Card>
            <Card.Header>የቼክ መረጃ</Card.Header>
            <Card.Body>
              <ListGroup>
                <ListGroup.Item>የቼክ ቁጥር ፡ {disburse.checkId} </ListGroup.Item>
                <ListGroup.Item>ቼክ የፈረሙ ፡ {disburse.checksignedby} </ListGroup.Item>
                <ListGroup.Item>ቼክ የተቆረጠበት ቀን ፡ {disburse.checkIssuedDate} </ListGroup.Item>
              </ListGroup>
            </Card.Body>
            <Card.Footer className="flex justify-between">
              <Button
                variant="warning"
                onClick={() => {
                  setModalEdit(true);
                  setCheckId(disburse.checkId);
                  setChecksignedby(disburse.checksignedby);
                }}
              >
                የቼክ መረጃ አስተካክል{' '}
              </Button>
              {disburse?.financePerson !== null && (
                <Link
                  to={`/rptdisbursement/${disburseId}`}
                  className="font-medium text-blue-600 dark:text-blue-300 underline hover:underline"
                >
                  ሪፖርት
                </Link>
              )}
            </Card.Footer>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default DisburseFinanceChecking;
