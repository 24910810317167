import React, { useEffect, useState } from "react";
import SpauseSchema from "../Schema/SpauseSchema";
import { Button, Table } from "react-bootstrap";
import TextFormikInput from "../ReusableFormiks/TextFormikInput";
import { useSelector } from "react-redux";
import Modal from "react-bootstrap/Modal";
import { FaPencilAlt, FaTrashAlt } from "react-icons/fa";
import { useParams } from "react-router-dom";

import { Formik, Form, Field, ErrorMessage } from "formik";
import axiosInstance from "../axiosInstance";

function SpauseComp({ successnotify, errornotify }) {
  const { serverIP } = useSelector((store) => store.allsettings);
  const { customerId } = useParams();
  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [fullName, setfullName] = useState("");
  const [targetObj, setTargetObj] = useState({});

  const [spus, setspus] = useState([]);

  const [lstRegions, setlstRegions] = useState([]);
  const [lstZones, setlstZones] = useState([]);
  const [lstWoredas, setlstWoredas] = useState([]);

  const [regionId, setRegionId] = useState(0);
  const [zoneId, setZoneId] = useState(0);
  const [woredaId, setworedaId] = useState(0);

  useEffect(() => {
    getSpauses();

    getRegions();
    getAllZones();
    getAllWoredas();
  }, []);

  const getRegions = () => {
    axiosInstance
      .get(`${serverIP}/regions/regions/activeregions`)
      .then((res) => {
        setlstRegions(res.data);
        console.log("regions loaded");
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllZones = (regID) => {
    axiosInstance
      .get(`${serverIP}/zones/zone`)
      .then((res) => {
        setlstZones(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllWoredas = (zonID) => {
    axiosInstance
      .get(`${serverIP}/woredas/woreda`)
      .then((res) => {
        console.log(res.data);
        setlstWoredas(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllZonesByRegionId = (regID) => {
    setRegionId(regID);

    axiosInstance
      .get(`${serverIP}/zones/byparentid/${regID}`)
      .then((res) => {
        setlstZones(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getAllWoredasByZoneId = (zonID) => {
    axiosInstance
      .get(`${serverIP}/woredas/byparentid/${zonID}`)
      .then((res) => {
        console.log(res.data);
        setlstWoredas(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getSpauses = () => {
    axiosInstance
      .get(`${serverIP}/spause/spausebyparent/${customerId}`)
      .then((res) => {
        setspus(res.data);

        setRegionId(res.data.regionId.id);
        setZoneId(res.data.zoneId.id);
        setworedaId(res.data.woredaId.id);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const saveSpouse = (values) => {
    axiosInstance
      .post(`${serverIP}/spause/detail`, {
        amFullName: values.amFullName,
        gender: values.gender,
        mobileNo: values.mobileNo,

        regionId: values.regionId,
        zoneId: values.zoneId,
        woredaId: values.woredaId,

        houseNum: values.houseNum,
        parent: customerId,
      })
      .then((res) => {
        successnotify();
        getSpauses();
        setModalAdd(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const deleteSpause = () => {
    console.log(targetObj);
    axiosInstance
      .delete(`${serverIP}/spause/detail/${targetObj.id}`)
      .then((res) => {
        getSpauses();
        setModalDelete(false);
        successnotify();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const updateSpause = (values) => {
    console.log("values start");
    console.log(values);
    console.log("values end");
    axiosInstance
      .patch(`${serverIP}/spause/detail/${targetObj.id}`, {
        amFullName: values.amFullName,
        gender: values.gender,
        mobileNo: values.mobileNo,

        // regionId: values.regionId,
        // zoneId: values.zoneId,
        // woredaId: values.woredaId,

        regionId: values.regionId,
        zoneId: values.zoneId,
        woredaId: values.woredaId,

        houseNum: values.houseNum,
        parent: customerId,
      })
      .then((res) => {
        successnotify();
        getSpauses();
        setModalEdit(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      {/* Modals Start  */}
      {/* Modal ADD Start  */}
      <Modal show={modalAdd} onHide={() => setModalAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={{
            amFullName: "",

            regionId: "",
            zoneId: "",
            woredaId: "",

            houseNum: "",
            gender: "",
            mobileNo: "",
          }}
          validationSchema={SpauseSchema}
          onSubmit={saveSpouse}
        >
          {({ errors }) => (
            <>
              <Form>
                <Modal.Body>
                  <div style={{ display: "flex" }}>
                    <TextFormikInput name="amFullName" placeholder="ሙሉ ስም" />
                  </div>

                 

                  <div className="w-full">
                    <label> ክልል </label>
                    <Field
                      as="select"
                      name="regionId"
                      className="form-control"
                      style={{
                        border: errors.regionId ? "1px solid red" : "",
                      }}
                    >
                      <option value=""> ክልል ይምረጡ </option>
                      {lstRegions.length > 0 &&
                        lstRegions.map((region) => {
                          return (
                            <option key={region.id} value={region.id}>
                              {region.amDescription}
                            </option>
                          );
                        })}
                    </Field>
                    <ErrorMessage
                      style={{ color: "red" }}
                      name="regionId"
                      component="div"
                    />
                  </div>


                  <div className="w-full">
                    <label> ዞን (ክ/ከተማ) </label>
                    <Field
                      as="select"
                      name="zoneId"
                      className="form-control"
                      style={{
                        border: errors.zoneId ? "1px solid red" : "",
                      }}
                    >
                      <option value=""> ዞን (ክ/ከተማ) ይምረጡ </option>
                      {lstZones.length > 0 &&
                        lstZones.map((zone) => {
                          return (
                            <option key={zone.id} value={zone.id}>
                              {zone.amDescription}
                            </option>
                          );
                        })}
                    </Field>
                    <ErrorMessage
                      style={{ color: "red" }}
                      name="zoneId"
                      component="div"
                    />
                  </div>

               

                  <div className="w-full">
                    <label> ወረዳ </label>
                    <Field
                      as="select"
                      name="woredaId"
                      className="form-control"
                      style={{
                        border: errors.woredaId ? "1px solid red" : "",
                      }}
                    >
                      <option value=""> ወረዳ ይምረጡ </option>
                      {lstWoredas.length > 0 &&
                        lstWoredas.map((woreda) => {
                          return (
                            <option key={woreda.id} value={woreda.id}>
                              {woreda.amDescription}
                            </option>
                          );
                        })}
                    </Field>
                    <ErrorMessage
                      style={{ color: "red" }}
                      name="woredaId"
                      component="div"
                    />
                  </div>

                  <div style={{ display: "flex" }}>
                    <TextFormikInput name="houseNum" placeholder="የቤት ቁጥር" />
                  </div>
                  <div style={{ display: "flex" }}>
                    <TextFormikInput name="mobileNo" placeholder="ስልክ ቁጥር" />
                    <TextFormikInput name="gender" placeholder="ፆታ" />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button type="submit" variant="primary btn-sm">
                    Submit
                  </Button>
                </Modal.Footer>
              </Form>
            </>
          )}
        </Formik>
      </Modal>
      {/* Modal Add End  */}

      {/* Modal Edit Start  */}
      <Modal show={modalEdit} onHide={() => setModalEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "orange" }}> Edit </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            amFullName: targetObj.amFullName,

            regionId: targetObj.regionId,
            zoneId: targetObj.zoneId,
            woredaId: targetObj.woredaId,

            houseNum: targetObj.houseNum,
            gender: targetObj.gender,
            mobileNo: targetObj.mobileNo,
          }}
          validationSchema={SpauseSchema}
          onSubmit={updateSpause}
        >
          {({ errors }) => (
            <>
              <Form>
                <Modal.Body>
                  <div style={{ display: "flex" }}>
                    <TextFormikInput
                      name="amFullName"
                      value={fullName}
                      placeholder="ሙሉ ስም"
                    />
                  </div>

                  <div style={{ display: "flex" }}>
                    <TextFormikInput name="houseNum" placeholder="የቤት ቁጥር" />
                  </div>
                  <div style={{ display: "flex" }}>
                    <TextFormikInput
                      name="mobileNo"
                      placeholder="Mobiile Number"
                    />
                    <TextFormikInput name="gender" placeholder="ፆታ" />
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button type="submit" variant="warning btn-sm">
                    አስተካክል
                  </Button>
                </Modal.Footer>
              </Form>
            </>
          )}
        </Formik>
      </Modal>
      {/* Modal Edit End  */}

      {/* Modal Delete Start  */}
      <Modal show={modalDelete} onHide={() => setModalDelete(false)}>
        <Modal.Header style={{ color: "red" }} closeButton>
          <Modal.Title color="red"> Delete </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you wnt to delete <strong> {targetObj.enName} </strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={deleteSpause} variant="danger">
            አጥፋ
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Delete End  */}
      {/* Modals End  */}

      <div className="row">
        <div className="col-sm-12">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              padding: 0,
              margin: 0,
            }}
          >
            {spus.length === 0 && (
              <Button
                variant="primary"
                className="btn-sm"
                onClick={() => setModalAdd(true)}
              >
                ጨምር
              </Button>
            )}
          </div>
          <Table striped bordered hover style={{ marginTop: 10 }}>
            <thead>
              <tr>
                <th>ሙሉስም </th>
                <th>ሞባይል</th>
                <th>አድራሻ</th>
                <th>ሁኔታ</th>
              </tr>
            </thead>
            <tbody>
              {spus.map((sp) => {
                return (
                  <tr>
                    <td> {sp.amFullName} </td>
                    <td> {sp.mobileNo} </td>
                    <td>
                      {`${sp.regionId.amDescription} /  ${sp.zoneId.amDescription} / 
                        ${sp.woredaId.amDescription}`}
                    </td>
                    {/* <td> {sp.subcity + "/" + sp.woreda} </td> */}
                    <td
                      style={{
                        display: "flex",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <FaTrashAlt
                        onClick={() => {
                          setModalDelete(true);
                          setTargetObj(sp);
                        }}
                        color="red"
                      />
                      &nbsp;
                      <FaPencilAlt
                        onClick={() => {
                          setModalEdit(true);
                          setTargetObj(sp);
                        }}
                        color="orange"
                      />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default SpauseComp;
