// Condition  (minimum 13 out of 25)

function firstC(targetClient) {
  console.log('targetClient');
  console.log(targetClient);
  const sum_single =
    targetClient?.singlegeneralfiles?.length > 0
      ? targetClient?.singlegeneralfiles?.reduce((accum, obj) => {
        return accum + (obj.isUploaded ? obj.markValue : 0);
      }, 0)
      : 0;

  const sum_married =
    targetClient?.marriedgeneralfiles?.length > 0
      ? targetClient?.marriedgeneralfiles?.reduce((accum, obj) => {
        return accum + (obj.isUploaded ? obj.markValue : 0);
      }, 0)
      : 0;

  const sum_general =
    targetClient?.customercommonfiles.length > 0
      ? targetClient?.customercommonfiles?.reduce((accum, obj) => {
        return accum + obj?.isUploaded ? obj?.markValue : 0;
      }, 0)
      : 0;

  return sum_general + targetClient.isMarried ? sum_married : sum_single;
}

// Capital
function secondC(targetClient, targetLoan) {
  return 0;
}

// Ability To Pay (Capacity) (minimum 13 out of 25)
function thirdC(targetClient, targetLoan) {
  let monthlypay = Number(targetLoan.totalDueForPeriod) === 0 ? 1 : targetLoan.totalDueForPeriod;
  let bankStatement = Number(targetLoan.bankstateent);
  let monthlySaving = Number(targetClient.monthlySaving);

  let babkstmt = (0.025 * bankStatement) / monthlypay;
  let totalMonthlySaving = (0.025 * monthlySaving) / monthlypay;
  const totalsum = babkstmt + totalMonthlySaving;
  return totalsum > 1 ? 25 : totalsum * 25;
}

function fourthC(targetClient, targetLoan) {
  return targetClient.characterEvaluation;
}

function fifthC(targetLoan) {
  const sum_car =
    targetLoan?.collateralcar.length > 0
      ? targetLoan?.collateralcar?.reduce((accum, obj) => {
        return (
          accum +
          (obj.insuranceValue +
            (obj?.garageReport[0]?.garageValue ? obj?.garageReport[0]?.garageValue : 0) +
            (obj?.marketvalue[0]?.marketValue ? obj?.marketvalue[0]?.marketValue : 0)) /
          3
        );
      }, 0)
      : 0;

  const sum_home =
    targetLoan?.collateralhome.length > 0
      ? targetLoan?.collateralhome?.reduce((accum, obj) => {
        return accum + obj.homearea * obj.hometype?.pricepercaremeter;
      }, 0)
      : 0;

  const sum_salary =
    targetLoan?.collateralemployee.length > 0
      ? targetLoan?.collateralemployee?.reduce((accum, obj) => {
        return accum + (obj.grossSalary * 100) / 15;
      }, 0)
      : 0;

  const sum_stock =
    targetLoan?.collateralstock.length > 0
      ? targetLoan?.collateralstock?.reduce((accum, obj) => {
        return accum + obj.priceperstock * obj.stockqty;
      }, 0)
      : 0;

  let total_sum = sum_car + sum_home + sum_salary + sum_stock;

  let calculatedResult =
    total_sum > targetLoan.approvedPrincipal ? 25 : (total_sum / targetLoan.approvedPrincipal) * 25;

  return calculatedResult;
}

function collateralSum(targetLoan) {
  const sum_car =
    targetLoan?.collateralcar.length > 0
      ? targetLoan?.collateralcar?.reduce((accum, obj) => {
        return (
          accum +
          (obj.insuranceValue +
            (obj?.garageReport[0]?.garageValue ? obj?.garageReport[0]?.garageValue : 0) +
            (obj?.marketvalue[0]?.marketValue ? obj?.marketvalue[0]?.marketValue : 0)) /
          3
        );
      }, 0)
      : 0;

  const sum_home =
    targetLoan?.collateralhome.length > 0
      ? targetLoan?.collateralhome?.reduce((accum, obj) => {
        return accum + obj.homearea * obj.hometype?.pricepercaremeter;
      }, 0)
      : 0;

  const sum_salary =
    targetLoan?.collateralemployee.length > 0
      ? targetLoan?.collateralemployee?.reduce((accum, obj) => {
        return accum + (obj.grossSalary * 100) / 15;
      }, 0)
      : 0;

  const sum_stock =
    targetLoan?.collateralstock.length > 0
      ? targetLoan?.collateralstock?.reduce((accum, obj) => {
        return accum + obj.priceperstock * obj.stockqty;
      }, 0)
      : 0;

  return sum_car + sum_home + sum_salary + sum_stock;
}

module.exports = { firstC, secondC, thirdC, fourthC, fifthC, collateralSum };
