import React from "react";

const NewDesign = () => {
  return (
    <div className="p-8 max-w-6xl mx-auto bg-gray-100">
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <div className="text-4xl font-bold text-blue-700">
          RATIOS Credit Analysis
        </div>
        <div className="text-xl font-bold text-red-600">Amigos SACCO</div>
      </div>

      {/* Credit Union Information */}
      <div className="bg-white p-4 rounded-lg shadow-md mb-6">
        <div className="grid grid-cols-3 gap-4">
          <div>
            <div className="font-semibold">
              Name: <span className="font-normal">ROBEL ALEMAYO</span>
            </div>
            <div className="font-semibold">
              Address: <span className="font-normal">AA/KOTEBE</span>
            </div>
            <div className="font-semibold">
              Member Since: <span className="font-normal">AA/KOTEBE</span>
            </div>
            <div className="font-semibold">
              Today's Date: <span className="font-normal"> 2/2/2024 </span>
            </div>
            <div className="font-semibold">
              Employer: <span className="font-normal">AA/KOTEBE</span>
            </div>
          </div>
          <div>
            <div className="font-semibold">
              Account #: <span className="font-normal">0</span>
            </div>
            <div className="font-semibold">
              Term (months): <span className="font-normal">120</span>
            </div>
            <div className="font-semibold">
              Interegst Rate: <span className="font-normal">0</span>
            </div>
            <div className="font-semibold">
              Work Telephone: <span className="font-normal">0935811576</span>
            </div>
            <div className="font-semibold">
              Monthly Payment: <span className="font-normal">22,899</span>
            </div>
            <div className="font-semibold">
              Position: <span className="font-normal"> Admin </span>
            </div>
          </div>
          <div>
            <div className="font-semibold">
              Acount: <span className="font-normal">$3,000,000</span>
            </div>
            <div className="font-semibold">
              Use: <span className="font-normal"> 120 </span>
            </div>
            <div className="font-semibold">
              Type : <span className="font-normal">0.1%</span>
            </div>
            <div className="font-semibold">
              Productive (G), provident(I) :{" "}
              <span className="font-normal">0.1%</span>
            </div>
            <div className="font-semibold">
              How Long : <span className="font-normal">0.1%</span>
            </div>
          </div>
        </div>
      </div>

      <div className="flex gap-2">
        <div className="grid grid-rows-2 gap-2 w-full">
          {/* C1 Character */}
          <div className="col-span-1 bg-white rounded-lg shadow-md">
            <div className="font-bold bg-yellow-100 p-3 rounded-md">
              C1 CHARACTER (58.0%)
            </div>
            <div className="mt-2 px-3">
              <div className="font-semibold">
                A. ID & Marriage Status or Residence
              </div>
              <div className="font-normal">Married with registered paper</div>
              <div className="mt-2 font-semibold">
                B. Work/Business Ownership
              </div>
              <div className="font-normal">Self-Owned (20+ years)</div>
              <div className="mt-2 font-semibold">
                C. Credit and Saving Experience
              </div>
              <div className="font-normal">Good history, platinum savings</div>
            </div>
          </div>

          {/* C2 Capital */}
          <div className="col-span-1 bg-white rounded-lg shadow-md">
            <div className="font-bold bg-yellow-100 p-3 rounded-md">
              C2 CAPITAL (80.0%)
            </div>
            <div className="mt-2 px-3">
              <div className="font-semibold">A. Total Assets</div>
              <div className="font-normal">Cash and Equipment: $500,000</div>
              <div className="mt-2 font-semibold">B. Liabilities</div>
              <div className="font-normal">Loans: $200,000</div>
              <div className="mt-2 font-semibold">C. Net Capital</div>
              <div className="font-normal">Remaining capital: $300,000</div>
            </div>
          </div>
        </div>

        {/* Sections C1 to C5 */}
        <div className="grid grid-rows-3 gap-2 w-full">
          {/* C3 Ability to Pay */}
          <div className="col-span-1 bg-white rounded-lg shadow-md">
            <div className="font-bold bg-yellow-100 p-3 rounded-md">
              C3 ABILITY TO PAY (21.2%)
            </div>
            <div className="mt-2 px-3">
              <div className="font-semibold">A. Monthly Income</div>
              <ul className="list-disc list-inside">
                <li>From production: $240,000</li>
                <li>Profits: $15,000</li>
              </ul>
              <div className="mt-2 font-semibold">B. Monthly Expenditures</div>
              <ul className="list-disc list-inside">
                <li>COGS: $200,000</li>
                <li>Interest Expense: $14,000</li>
              </ul>
            </div>
          </div>

          {/* C4 Conditions */}
          <div className="col-span-1 bg-white rounded-lg shadow-md">
            <div className="font-bold bg-yellow-100 p-3 rounded-md">
              C4 CONDITIONS (76.7%)
            </div>
            <div className="mt-2 px-3">
              <div className="font-semibold">A. Main Source of Income</div>
              <div className="font-normal">Business location in A, B areas</div>
              <div className="mt-2 font-semibold">
                B. Conditions & References
              </div>
              <div className="font-normal">Good references in industry</div>
            </div>
          </div>

          {/* C5 Collateral */}
          <div className="col-span-1 bg-white rounded-lg shadow-md">
            <div className="font-bold bg-yellow-100 p-3 rounded-md">
              C5 COLLATERAL (100%)
            </div>
            <div className="mt-2 px-3">
              <div className="font-semibold">A. Personal Guaranty</div>
              <div className="font-normal">First guarantor: $1,050,000</div>
              <div className="mt-2 font-semibold">B. Collateral Guaranty</div>
              <div className="font-normal">Property valuation: $2,000,000</div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer Section */}
      <div className="mt-6 bg-white p-4 rounded-lg shadow-md">
        <div className="flex justify-between">
          <div>
            <div className="font-semibold">
              TOTAL SCORE: <span className="font-normal">67.2%</span>
            </div>
            <div className="font-semibold">
              LEVEL OF RISK: <span className="font-normal">POTENTIAL</span>
            </div>
          </div>
          <div>
            <div className="font-semibold">Signed: ____________________</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewDesign;
