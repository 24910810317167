import React from "react";
import { Text, Font, View, Image, StyleSheet } from "@react-pdf/renderer";
import AmigosLogo from "../Images/new.PNG";
import AmharicFont from "../font/ebrima.ttf";

const styles = StyleSheet.create({
  container: {
    marginTop: -15,
    display: "flex",
    flexDirection: "row",
    borderBottomWidth: 2,
    borderBottomColor: "#FF7700",
    borderBottomStyle: "solid",
    justifyContent: "space-between",
    paddingBottom: 5,
  },
  ReporIimage: {
    height: 35,
    width: "30%",
  },
  name: {
    fontSize: 12,
    fontFamily: "AmharicFont",
    justifyContent: "center",
  },
  typename: {
    fontSize: 12,
    display: "flex",
    flexDirection: "column",
    fontFamily: "AmharicFont",
    justifyContent: "center",
    alignItems: "center",
  },
});

Font.register({
  family: "AmharicFont",
  src: AmharicFont,
});

function HeaderHistory({ type }) {
  return (
    <View style={styles.container}>
      <Image style={styles.ReporIimage} src={AmigosLogo} />
      <View style={{ display: "flex", flexDirection:"column", justifyContent: "center" }}>
        <Text style={styles.name}> አሚጎስ የገንዘብ ቁጠባና ብድር ህ/ስ/ማ </Text>
        <Text style={styles.name}> {type} </Text>
      </View>
      <Image style={styles.ReporIimage} src={AmigosLogo} />
    </View>
  );
}

export default HeaderHistory;
