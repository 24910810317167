import React from 'react';
import { Document, Page } from '@react-pdf/renderer';
import { Text, View, Image } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';

import GuaranteeStyles from './Styles/ReportStyle';
import AmigosLogo from './Images/Head.PNG';
import getEthiopicDate from '../Common/modules/GregorianToEthiopian';

import { numberToWords } from '../Common/modules/EnNumToWord';
import TextBoldUnderlined from './Reusables/TextBoldUnderlined';

function RptShareProhibition({ loan, stock }) {
  const curdate = new Date();

  return (
    <PDFViewer style={{ width: '100%', height: 700 }}>
      <Document title="የአክስዮን እግድ">
        <Page style={GuaranteeStyles.prohibitionbody} wrap>
          <Image style={GuaranteeStyles.CarProhibition_ReporIimage} src={AmigosLogo} />

          {/* date and letter number  */}
          <View style={{ paddingTop: 10 }}>
            <Text style={GuaranteeStyles.textRight}>
              ቀን ፡ &nbsp;
              {getEthiopicDate(curdate.getDay(), +' ' + curdate.getMonth() + 1, +' ' + curdate.getFullYear())}
            </Text>
            <Text style={GuaranteeStyles.textRight}>
              ቁጥር ፡ አ/ቀ/ብ/OP/___________/
              {/* {getEthiopicDate(
                curdate.getDay(),
                +" " + curdate.getMonth() + 1,
                +" " + curdate.getFullYear()
              )} */}
            </Text>
          </View>

          <View style={{ paddingTop: 30 }}>
            <Text style={GuaranteeStyles.subject}>ለ {stock.bankId.amName}</Text>
            <Text style={GuaranteeStyles.subject}>አዲስ አበባ</Text>
          </View>

          <View style={{ paddingTop: 10 }}>
            <Text style={GuaranteeStyles.subjectCenter}>ጉዳዩ ፡- እገዳ እንዲደረግልን ስለመጠየቅ ይመለከታል፡፡</Text>
          </View>

          <View style={{ paddingTop: 10 }}>
            <Text style={GuaranteeStyles.h1}>
              የድርጅታችን አባል የሆኑት {loan.customer?.gender === 'MALE' ? ' አቶ ' : ' ወ/ሮ(ወ/ሪት) '}
              <TextBoldUnderlined text={loan.customer.amDisplayName} />
              ከማህበራችን ብር
              <Text style={GuaranteeStyles.h1b}>
                {` ${loan.total_loan?.toLocaleString()} ( ${numberToWords(loan.total_loan)} ) `}
              </Text>
              በብድር የወሰዱ ሲሆን ለብድሩ መያዣነት ያቀረቡትን ማለትም፡፡
            </Text>
          </View>

          {/* Table Start */}

          <View style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
            <View style={{ width: '10%' }}></View>
            <View style={{ width: '80%' }}>
              <View
                style={{
                  width: '100%',
                  fontSize: 10,
                  paddingTop: 15,
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <View style={{ width: '40%' }}>
                  <Text
                    style={{
                      ...GuaranteeStyles.border_rbl,
                      backgroundColor: 'gray'
                    }}
                  >
                    የዋስትናው አይነት
                  </Text>
                </View>
                <View style={{ width: '60%' }}>
                  <Text
                    style={{
                      ...GuaranteeStyles.border_rbl,
                      backgroundColor: 'gray'
                    }}
                  >
                    አክስዮን
                  </Text>
                </View>
              </View>
              <View
                style={{
                  width: '100%',
                  fontSize: 10,
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <View style={{ width: '40%' }}>
                  <Text style={GuaranteeStyles.border_rbl}> የዋስትና ሰጪ ስም </Text>
                </View>
                <View style={{ width: '60%' }}>
                  <Text style={GuaranteeStyles.border_rb}>
                    {stock.stockgp?.fullname || loan.customer.amDisplayName}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  width: '100%',
                  fontSize: 10,
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <View style={{ width: '40%' }}>
                  <Text
                    style={{
                      ...GuaranteeStyles.border_rbl,
                      backgroundColor: 'gray'
                    }}
                  >
                    የደብዳቤ ቁጥር ቁጥር
                  </Text>
                </View>
                <View style={{ width: '60%' }}>
                  <Text
                    style={{
                      ...GuaranteeStyles.border_rbl,
                      backgroundColor: 'gray'
                    }}
                  >
                    {stock.letternum}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  width: '100%',
                  fontSize: 10,
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <View style={{ width: '40%' }}>
                  <Text style={GuaranteeStyles.border_rbl}>የአክስዮን መጠን </Text>
                </View>
                <View style={{ width: '60%' }}>
                  <Text style={GuaranteeStyles.border_rb}>{stock?.stockqty * stock.priceperstock}</Text>
                </View>
              </View>
              <View
                style={{
                  width: '100%',
                  fontSize: 10,
                  display: 'flex',
                  flexDirection: 'row'
                }}
              >
                <View style={{ width: '40%' }}>
                  <Text
                    style={{
                      ...GuaranteeStyles.border_rbl,
                      backgroundColor: 'gray'
                    }}
                  >
                    ሴሪ ቁጥር
                  </Text>
                </View>
                <View style={{ width: '60%' }}>
                  <Text
                    style={{
                      ...GuaranteeStyles.border_rbl,
                      backgroundColor: 'gray'
                    }}
                  >
                    {stock?.serialnum ? stock?.serialnum : ' '}
                  </Text>
                </View>
              </View>
            </View>
            <View style={{ width: '10%' }}></View>
          </View>
          {/* Table End */}

          <View style={{ paddingTop: 10 }}>
            <Text style={GuaranteeStyles.h1}>
              እንዲታገድ ፣ እንዳይሸጥ እንዳይለወጥ እንዲሁመ ለሌላ ሶስተኛ ወገን እንዳይተላለፍ በአሚጎስ የገ/ቁ/በ/ህ/ስ/ማ ስም ተመዝገቦ እንዲቆይ እየጠየቅን ፣ የዋስትናው
              ደኩመንት በድርጅታችን ሰራተኛ በኩል እንድትልኩልን የዘወትር የስራ ትብብራችሁን እንጠይቃለን፡፡
            </Text>
          </View>

          {/* ከሰላምታ ጋር  */}
          <View style={{ paddingTop: 10 }}>
            <Text style={GuaranteeStyles.subjectRight}>ከሰላምታ ጋር </Text>
          </View>

          <View style={{ marginTop: 180, rowGap: 5 }}>
            <View
              style={{
                width: '100%',
                fontSize: 10,
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <View style={{ width: '50%' }}>
                <Text style={GuaranteeStyles.h1}>አዘጋጅ ፡ ____________________________</Text>
              </View>
              <View style={{ width: '50%' }}>
                <Text style={GuaranteeStyles.h1}>ያረጋገጠው ፡ _________________________</Text>
              </View>
            </View>
            <View
              style={{
                width: '100%',
                fontSize: 10,
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <View style={{ width: '50%' }}>
                <Text style={GuaranteeStyles.h1}>የስራ ድርሻ ፡ _________________________</Text>
              </View>
              <View style={{ width: '50%' }}>
                <Text style={GuaranteeStyles.h1}>የስራ ድርሻ ፡ _________________________</Text>
              </View>
            </View>
            <View
              style={{
                width: '100%',
                fontSize: 10,
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <View style={{ width: '50%' }}>
                <Text style={GuaranteeStyles.h1}>ፊርማ ፡ _________________________</Text>
              </View>
              <View style={{ width: '50%' }}>
                <Text style={GuaranteeStyles.h1}>ፊርማ ፡ _________________________</Text>
              </View>
            </View>
            <View style={{ marginTop: 60 }}></View>

            <Image style={GuaranteeStyles.CarProhibition_ReporIimage} src={AmigosLogo} />
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}

export default RptShareProhibition;
