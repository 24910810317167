import React, { useEffect, useState } from 'react';
import { Card, ListGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import axiosInstance from '../axiosInstance';

function LoanApplicationFollowup() {
  const { serverIP } = useSelector(store => store.allsettings);
  const { data } = useSelector(store => store.customer);

  const [lonaPlanID, setlonaPlanID] = useState(null);

  const [selfLoanApp, setSelfLoanApp] = useState({});
  const [ssloancomment, setssloancomment] = useState([]);

  const [txtComment, settxtComment] = useState({});
  const { loanId } = useParams();

  useEffect(() => {
    getTheSelfLoanApplication();
  }, []);

  const getTheSelfLoanApplication = () => {
    axiosInstance
      .get(`${serverIP}/ssloanrequest/ssloanrequest/${loanId}`)
      .then(res => {
        console.log(res.data);
        setSelfLoanApp(res.data);
        setssloancomment(res.data.ssloancomment);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const postComment = () => {
    console.log('object postComment - ' + txtComment);
    axiosInstance
      .post(`${serverIP}/sscomments/ssloancomments`, {
        comment: txtComment,
        commentedBy: data.id,
        loan: loanId
      })
      .then(res => {
        getTheSelfLoanApplication();
      })
      .catch(err => {});
  };

  const SaveLoanPlan = () => {
    console.log('lonaPlanID - ' + lonaPlanID);
    axiosInstance
      .patch(`${serverIP}/ssloanrequest/ssloanrequest/${loanId}`, {
        loanPlanId: lonaPlanID
      })
      .then(res => {
        console.log(res.data);
        setSelfLoanApp(res.data);
        setssloancomment(res.data.ssloancomment);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <div className="flex flex-col sm:flex-row w-full gap-2">
      <div className="w-full sm:w-3/4">
        <Card>
          <Card.Header> የተበዳሪ የቁጠባ መረጃ </Card.Header>
          <Card.Body>
            <ListGroup>
              <ListGroup.Item>{`Compulsary Saving: ${selfLoanApp?.amount_compulsary?.toLocaleString()}`}</ListGroup.Item>
              <ListGroup.Item>{`amount_funeral: ${selfLoanApp?.amount_funeral?.toLocaleString()}`}</ListGroup.Item>
              <ListGroup.Item>{`amount_loansaving: ${selfLoanApp?.amount_loansaving?.toLocaleString()}`}</ListGroup.Item>
              <ListGroup.Item>{`amount_share: ${selfLoanApp?.amount_share?.toLocaleString()}`}</ListGroup.Item>
              <ListGroup.Item>{`amount_timedeposit: ${selfLoanApp?.amount_timedeposit?.toLocaleString()}`}</ListGroup.Item>
              <ListGroup.Item>{`amount_voluntary: ${selfLoanApp?.amount_voluntary?.toLocaleString()}`}</ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Card>

        <Card className="mt-3">
          <Card.Header> የተጠየቀ የብድር መረጃ </Card.Header>
          <Card.Body>
            <ListGroup>
              <ListGroup.Item>{`requestedAmount: ${selfLoanApp?.requestedAmount?.toLocaleString()}`}</ListGroup.Item>
              <ListGroup.Item>{`multiplier: ${selfLoanApp?.multiplier?.toLocaleString()}`}</ListGroup.Item>
              <ListGroup.Item
                className={`${selfLoanApp?.loanPlanId === null ? 'bg-red-400' : 'bg-green-500'}`}
              >{`የብድሩ ሁኔታ: ${selfLoanApp?.loanPlanId === null ? ' ፕላን አልተያዘም' : ' ፕላን ተይዟል'}`}</ListGroup.Item>
            </ListGroup>
          </Card.Body>
        </Card>

        <Card className="mt-3">
          <Card.Header> የተያዘው Loan Plan ID ማስገብያ </Card.Header>
          <Card.Body>
            <input
              type="number"
              className="form-control"
              onChange={e => setlonaPlanID(e.target.value)}
              name="loan_plan_id"
              placeholder="ID"
            />
          </Card.Body>
          <Card.Footer className="flex justify-end">
            <button onClick={SaveLoanPlan} class="px-3 py-2 bg-gray-500 text-white rounded-md hover:bg-green-600">
              Save
            </button>
          </Card.Footer>
        </Card>
      </div>
      <div className="w-full sm:w-1/4">
        <Card>
          <Card.Header>የተሰጡ ኮመንቶች </Card.Header>
          <Card.Body>
            <div class="p-4 bg-gray-100 max-w-md mx-auto rounded-lg max-h-90 overflow-y-auto">
              {ssloancomment.length > 0 &&
                ssloancomment.map(comment => {
                  return comment.commentedBy !== null ? (
                    <div class="flex items-end space-x-2 mb-4">
                      <div class="flex w-8 h-8 rounded-full items-center justify-center bg-gray-400">
                        {Array.from(comment.commentedBy.first_name)[0]}.{Array.from(comment.commentedBy.first_name)[0]}
                      </div>
                      <div class="bg-white py-1 px-3 rounded-lg shadow-md max-w-xs">
                        <p class="text-gray-700">{comment.comment} </p>
                        <span class="text-xs text-gray-400 block text-right mt-1">10:30 AM</span>
                      </div>
                    </div>
                  ) : (
                    <div class="flex items-end space-x-2 mb-4 justify-end">
                      <div class="bg-green-200  py-1 px-3 rounded-lg shadow-md max-w-xs">
                        <p class="text-gray-700">{comment.comment}</p>
                        <span class="text-xs text-gray-500 block text-right mt-1">10:32 AM</span>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div class="flex items-center space-x-2 mt-4 bg-white p-2 rounded-full shadow-md">
              <input
                type="text"
                placeholder="Type a message..."
                onChange={e => settxtComment(e.target.value)}
                class="flex-1 px-4 py-2 text-sm border rounded-full focus:outline-none focus:ring-2 focus:ring-green-500"
              />
              <button
                onClick={e => {
                  postComment();
                }}
                class="px-3 py-2 bg-green-500 text-white rounded-full hover:bg-green-600"
              >
                Send
              </button>
            </div>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}

export default LoanApplicationFollowup;
