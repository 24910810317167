import React, { useEffect, useState } from 'react';
import { Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LoanStatus from '../Common/LoanStatus';
import { setTargetClient, setTargetLoan } from '../Common/redux/systemLookups';
import axiosInstance from '../axiosInstance';

import { Link, useNavigate } from 'react-router-dom';

/**
 * This function is the main component of the Loan Processor System. It is responsible for managing the entire system, including the processing of loans, the tracking of customer information, and the monitoring of audit activities.
 *
 * @param {object} props - This is an object that contains the properties of the component.
 * @param {object} props.allsettings - This is an object that contains all of the settings for the application.
 * @param {string} props.allsettings.serverIP - This is a string that contains the IP address of the server.
 *
 * @returns {JSX.Element} - This function returns the JSX code for the Loan Processor System.
 */
function AuditorHome() {
  const [t, i18n] = useTranslation('global');
  const { serverIP } = useSelector(store => store.allsettings);
  const [loans, setLoans] = useState([]);
  const [filteredLoans, setFilteredLoans] = useState([]);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    getlpscustomers();
  }, []);

  /**
   * This function is responsible for retrieving the list of loans from the server.
   */
  const getlpscustomers = () => {
    axiosInstance
      .get(`${serverIP}/loan/onpregressloans`)
      .then(function (response) {
        console.log(response.data);
        setLoans(response.data);
        setFilteredLoans(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const redirectPage = async (custId, loanId) => {
    try {
      await getLoan(loanId);
      await getCustomer(custId);
      navigate(`/judgeloanauditor/${custId}/${loanId}`);
    } catch (error) {
      console.error('Error in redirecting:', error);
    }
  };

  const getLoan = async loanId => {
    try {
      const res = await axiosInstance.get(`${serverIP}/loan/loans/${loanId}`);
      dispatch(setTargetLoan({ targetLoan: res.data }));
    } catch (err) {
      console.error('Error fetching loan:', err);
    }
  };

  const getCustomer = async custId => {
    try {
      const res = await axiosInstance.get(`${serverIP}/customer/customers/${custId}`);
      dispatch(setTargetClient({ client: res.data }));
    } catch (err) {
      console.error('Error fetching customer:', err);
    }
  };

  return (
    <div>
      <div className="row">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder={t('applicant.externalID')}
            aria-describedby="basic-addon2"
            onChange={e =>
              setFilteredLoans(loans.filter(x => x.customer.entityExternalId.toString().includes(e.target.value)))
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>{t('applicant.externalID')}</th>
                <th>{t('applicant.fullName')}</th>
                <th>{t('applicant.assigned')}</th>
                <th> የተፈቀደው የብድር መጠን </th>
                <th> ኦፊሰር </th>
                <th> ሪፖርት </th>
                <th> ግምገማ </th>
                <th> ዉሳኔ </th>
              </tr>
            </thead>
            <tbody>
              {filteredLoans.map(loan => {
                return (
                  <>
                    {loan.teamleaderchecking && loan.auditorchecking && (
                      <tr>
                        <td> {loan.customer.entityExternalId} </td>
                        <td> {loan.customer.amDisplayName} </td>
                        <td>
                          <LoanStatus loan={loan} />
                        </td>
                        <td>
                          {loan.toauditor && !loan.auditorApproved ? (
                            <Link to={`/loanonauditor/${loan.customer?.id}/${loan.id}`}>
                              {loan.r_approvedPrincipal?.toLocaleString()}
                            </Link>
                          ) : (
                            <>{loan.r_approvedPrincipal?.toLocaleString()}</>
                          )}
                        </td>
                        <td>{loan.submittedBy?.first_name + ' ' + loan.submittedBy?.last_name}</td>
                        <td>
                          {loan.auditorchecking ? (
                            <Link
                              to={`/reports/${loan?.customer?.id}/${loan?.id}`}
                              className="font-medium text-blue-400 dark:text-blue-200 underline hover:underline text-xl"
                            >
                              ሪፖርት
                            </Link>
                          ) : (
                            <></>
                          )}
                        </td>
                        <td>
                          <Link
                            to={`/loanevaluation/${loan?.customer?.id}/${loan?.id}`}
                            className="font-medium text-blue-400 dark:text-blue-200 underline hover:underline text-xl"
                          >
                            ግምገማ
                          </Link>
                        </td>
                        <td>
                          <Link
                            className="font-medium text-blue-600 dark:text-blue-500 underline hover:underline text-xl"
                            disabled={true}
                            onClick={() => redirectPage(loan?.customer?.id, loan?.id)}
                          >
                            ውሳኔ
                          </Link>
                          {/* <Link
                            to={`/judgeloanauditor/${loan?.customer?.id}/${loan?.id}`}
                            className="font-medium text-blue-400 dark:text-blue-200 underline hover:underline text-xl"
                          >
                            ውሳኔ
                          </Link> */}
                        </td>
                      </tr>
                    )}
                  </>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
}

export default AuditorHome;
