import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Alert, Table } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import axiosInstance from '../axiosInstance';

function DisburseNew() {
  const [t, i18n] = useTranslation('global');
  const { serverIP } = useSelector(store => store.allsettings);
  const [finishedLns, setFinishedLns] = useState([]);
  const [filteredLoans, setFilteredLoans] = useState([]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    getFinishedLoans();
  }, []);

  const getFinishedLoans = async () => {
    try {
      const res = await axiosInstance.get(`${serverIP}/loan/loans`);
      setFinishedLns(res.data);
      setFilteredLoans(res.data);
    } catch (err) {
      console.error('Error fetching loan:', err);
    }
  };

  const redirectPage = async loanId => {
    try {
      //   await getLoan(loanId);
      navigate(`/disburseapplication/${loanId}`);
    } catch (error) {
      console.error('Error in redirecting:', error);
    }
  };

  const getLoan = async loanId => {
    try {
      const res = await axiosInstance.get(`${serverIP}/loan/loans/${loanId}`);
    } catch (err) {
      console.error('Error fetching loan:', err);
    }
  };

  return (
    <div>
      <Alert> የሚለቀቁ ብድሮች </Alert>

      <div className="row">
        <div class="input-group mb-3">
          <input
            type="text"
            class="form-control"
            placeholder={t('applicant.clientID')}
            onChange={e =>
              setFilteredLoans(finishedLns.filter(x => x.customer.entityExternalId.toString().includes(e.target.value)))
            }
          />
        </div>
      </div>

      {filteredLoans?.length > 0 && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>{t('applicant.externalID')}</th>
              <th>{t('applicant.fullName')}</th>
              <th> የተፈቀደው የብድር መጠን </th>
              <th> የወጪ ሁኔታ </th>
            </tr>
          </thead>
          <tbody>
            {filteredLoans.map(loan => {
              return (
                <>
                  {loan.auditorapproved && (
                    <tr key={loan.id}>
                      <td>{loan.customer.entityExternalId + ' - ' + loan.customer.id}</td>
                      <td> {loan.customer.amDisplayName} </td>
                      <td> {loan.approvedPrincipal?.toLocaleString()} </td>
                      <td>
                        <Link
                          to={`/disburseapplication/${loan?.id}`}
                          className="font-medium text-blue-400 dark:text-blue-200 underline hover:underline text-xl"
                          onClick={() => redirectPage(loan?.id)}
                        >
                          {loan.financeCheking ? 'ፋይናንስ ' : 'ፕላነር '}
                          {loan?.disbursement_detail?.length}
                        </Link>
                      </td>
                    </tr>
                  )}
                </>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export default DisburseNew;
