import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sideBar: true,
  Blue: "#035397",
  Orange: "#FF7700",
  Gray: "#F4F2F2",
  targetClient: [],
  targetLoan: [],
  systemOfficers: [],

  loanCommitee: [],
  targetWithdraw: [],
};

const sysytemLookupSlice = createSlice({
  name: "systemLookups",
  initialState: initialState,
  reducers: {
    setSideBar: (state, action) => {
      return {
        ...state,
        sideBar: !state.sideBar,
      };
    },

    setTargetClient: (state, action) => {
      return {
        ...state,
        targetClient: action.payload.client,
      };
    },

    setTargetLoan: (state, action) => {
      return {
        ...state,
        targetLoan: action.payload.targetLoan,
      };
    },

    setSystemOfficers: (state, action) => {
      return {
        ...state,
        systemOfficers: action.payload.officers,
      };
    },

    setLoanCommitee: (state, action) => {
      return {
        ...state,
        loanCommitee: action.payload.commitee,
      };
    },

    setTargetWithdraw: (state, action) => {
      return {
        ...state,
        targetWithdraw: action.payload.withdraw,
      };
    },

    resetStore: (state, action) => {
      return {
        sideBar: true,
        Blue: "#035397",
        Orange: "#FF7700",
        Gray: "#F4F2F2",
        targetClient: [],
        targetLoan: [],
        systemOfficers: [],

        loanCommitee: [],
        targetWithdraw: [],
      };
    },
  },
});

export const {
  setSideBar,
  setTargetClient,
  setTargetLoan,
  setSystemOfficers,

  setTargetWithdraw,
  resetStore,
} = sysytemLookupSlice.actions;
export default sysytemLookupSlice.reducer;
