import React, { useState, useEffect } from 'react';
import { Accordion, Alert } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import LoanCover from './LoanCover';
import AgreementDoc from './AgreementDoc';
import RequestFormDoc from './RequestFormDoc';

import RptCarGuarantee from './RptCarGuarantee';
import RptHomeGuarantee from './RptHomeGuarantee';
import RptSalaryGuarantee from './RptSalaryGuarantee';
import RptShareGuarantee from './RptShareGuarantee';

import RptHomeProhibition from './RptHomeProhibition';
import RptCarProhibition from './RptCarProhibition';
import RptShareProhibition from './RptShareProhibition';
import axiosInstance from '../axiosInstance';

function Reports() {
  const { serverIP } = useSelector(store => store.allsettings);
  const { customerId, loanId } = useParams();
  const [customer, setCustomer] = useState([]);
  const [loan, setLoan] = useState([]);
  const [committee, setCommittee] = useState([]);

  useEffect(() => {
    getLoan();
    getCustomer();
    getActiveCommittee();
  }, []);

  const getCustomer = async () => {
    await axiosInstance
      .get(`${serverIP}/customer/customers/${customerId}`)
      .then(res => {
        console.log('customer');
        console.log(res.data);
        setCustomer(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getLoan = async () => {
    await axiosInstance
      .get(`${serverIP}/loan/loans/${loanId}`)
      .then(res => {
        console.log('LOAN');
        console.log(res.data);
        setLoan(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getActiveCommittee = async () => {
    await axiosInstance
      .get(`${serverIP}/loancommittee/active`)
      .then(res => {
        console.log(res.data);
        setCommittee(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-sm-12">
          <Alert style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>
              <b>{customer.amDisplayName}</b>
              {' - ' + customer.mobileNo}
            </div>
            <div>
              <b>{customer.gender}</b>
              <b>{customer.isMarried ? ' - Married - ' : ' - Single - '}</b>
              <b> {customer.entityExternalId} </b>
            </div>
          </Alert>
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <Accordion defaultActiveKey="1">
            <Accordion.Item eventKey="1">
              <Accordion.Header>ከቨር</Accordion.Header>
              <Accordion.Body>
                <LoanCover customer={customer} loan={loan} committee={committee} />
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header> የብድር ውል ስምምነት </Accordion.Header>
              <Accordion.Body>
                <AgreementDoc />
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="3">
              <Accordion.Header> መጠየቅያ ፎርም </Accordion.Header>
              <Accordion.Body>
                <RequestFormDoc />
              </Accordion.Body>
            </Accordion.Item>

            {loan?.collateralcar?.length > 0 &&
              loan.collateralcar.map(car => {
                return (
                  <>
                    <Accordion.Item eventKey="carg">
                      <Accordion.Header>
                        <b style={{ color: 'green' }}> መኪና ዋስትና</b> &nbsp;
                        <i> {car.cargp?.fullname} </i>
                      </Accordion.Header>
                      <Accordion.Body>
                        <RptCarGuarantee loan={loan} car={car} />
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="carp">
                      <Accordion.Header>
                        <b style={{ color: 'red' }}> መኪና እግድ </b> &nbsp;
                        <i>{car.cargp?.fullname}</i>
                      </Accordion.Header>
                      <Accordion.Body>
                        <RptCarProhibition loan={loan} car={car} />
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                );
              })}

            {loan?.collateralhome?.length > 0 &&
              loan.collateralhome.map(home => {
                return (
                  <>
                    <Accordion.Item eventKey="homeg">
                      <Accordion.Header>የቤት - {home?.hometype?.amName}</Accordion.Header>
                      <Accordion.Body>
                        <RptHomeGuarantee loan={loan} home={home} />
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="homep">
                      <Accordion.Header>
                        <b style={{ color: 'red' }}> የቤት እግድ </b> &nbsp;
                        <i>{home.homegp?.fullname}</i>
                      </Accordion.Header>
                      <Accordion.Body>
                        <RptHomeProhibition loan={loan} home={home} />
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                );
              })}

            {loan?.collateralemployee?.length > 0 &&
              loan.collateralemployee.map(salary => {
                return (
                  <Accordion.Item eventKey="employeeg">
                    <Accordion.Header> ደመወዝ </Accordion.Header>
                    <Accordion.Body>
                      <RptSalaryGuarantee loan={loan} salary={salary} />
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}

            {loan?.collateralstock?.length > 0 &&
              loan.collateralstock.map(stock => {
                return (
                  <>
                    <Accordion.Item eventKey="stockg">
                      <Accordion.Header> አክሲዮን </Accordion.Header>
                      <Accordion.Body>
                        <RptShareGuarantee loan={loan} stock={stock} />
                      </Accordion.Body>
                    </Accordion.Item>

                    <Accordion.Item eventKey="stockp">
                      <Accordion.Header>
                        <b style={{ color: 'red' }}> የአክስዮን እግድ </b> &nbsp;
                        <i>{stock.stockgp?.fullname}</i>
                      </Accordion.Header>
                      <Accordion.Body>
                        <RptShareProhibition loan={loan} stock={stock} />
                      </Accordion.Body>
                    </Accordion.Item>
                  </>
                );
              })}
          </Accordion>
        </div>
      </div>
    </div>
  );
}

export default Reports;
