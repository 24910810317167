import React, { useEffect, useState } from 'react';
import { Button, InputGroup, Form, Card, ListGroup, Alert } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaMoneyBillWave, FaUserAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import LoadingSpinner from '../Common/LoadingSpinner';
import { setTargetWithdraw } from '../Common/redux/systemLookups';
import LpsWithdraws from './LpsWithdraws';
import axiosInstance from '../axiosInstance';

function Withdraw() {
  const { serverIP } = useSelector(store => store.allsettings);
  const { data } = useSelector(store => store.customer);
  const [t] = useTranslation('global');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [externalId, setExternalId] = useState(0);
  const [djangoUser, setDjangoUser] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [clientInitial, setClientInitial] = useState([]);
  const [clientDetail, setClientDetail] = useState({});

  const [loanSaving, setLoanSaving] = useState([]);
  const [voluntary, setVoluntary] = useState([]);
  const [funeral, setFuneral] = useState([]);
  const [compulsary, setCompulsary] = useState([]);
  const [timeDeposit, setTimeDeposit] = useState([]);

  const [loanSavingSum, setLoanSavingSum] = useState(0);
  const [voluntarySum, setVoluntarySum] = useState(0);
  const [funeralSum, setFuneralSum] = useState(0);
  const [compulsarySum, setCompulsarySum] = useState(0);
  const [timeDepositSum, setTimeDepositSum] = useState(0);

  const [savingAccountTotal, setSavingAccountTotal] = useState(0);
  const [shareAccountTotal, setShareAccountTotal] = useState(0);

  const [allAccount, setAllAccount] = useState([]);

  useEffect(() => {
    const sum_lnsaving = loanSaving?.reduce((accum, obj) => {
      return accum + (obj.accountBalance ? obj.accountBalance : 0);
    }, 0);
    setLoanSavingSum(sum_lnsaving);

    const sum_voluntary = voluntary?.reduce((accum, obj) => {
      return accum + (obj.accountBalance ? obj.accountBalance : 0);
    }, 0);
    setVoluntarySum(sum_voluntary);

    const sum_funeral = funeral?.reduce((accum, obj) => {
      return accum + (obj.accountBalance ? obj.accountBalance : 0);
    }, 0);
    setFuneralSum(sum_funeral);

    const sum_compulsary = compulsary?.reduce((accum, obj) => {
      return accum + (obj.accountBalance ? obj.accountBalance : 0);
    }, 0);
    setCompulsarySum(sum_compulsary);

    const sum_timed = timeDeposit?.reduce((accum, obj) => {
      return accum + (obj.accountBalance ? obj.accountBalance : 0);
    }, 0);
    setTimeDepositSum(sum_timed);

    setAllAccount([
      {
        customerId: djangoUser[0]?.id,
        amount: sum_lnsaving,
        savingTypeId: 1,
        savingType: 'Loan Saving',
        amSavingType: 'የብድር ቁጠባ',
        plannedby: data.id
      },
      {
        customerId: djangoUser[0]?.id,
        amount: sum_voluntary,
        savingTypeId: 2,
        savingType: 'Voluntary Saving',
        amSavingType: 'የፍላጎት ቁጠባ',
        plannedby: data.id
      },
      {
        customerId: djangoUser[0]?.id,
        amount: sum_funeral,
        savingTypeId: 3,
        savingType: 'Funeral Saving',
        amSavingType: 'ፉነራል ቁጠባ',
        plannedby: data.id
      },
      {
        customerId: djangoUser[0]?.id,
        amount: sum_compulsary,
        savingTypeId: 4,
        savingType: 'Compulsary Saving',
        amSavingType: 'ኮምፑልሳሪ ቁጠባ',
        plannedby: data.id
      },
      {
        customerId: djangoUser[0]?.id,
        amount: sum_timed,
        savingTypeId: 5,
        savingType: 'Time Deposit',
        amSavingType: 'የጊዜ ቁጠባ',
        plannedby: data.id
      },
      {
        customerId: djangoUser[0]?.id,
        amount: shareAccountTotal,
        savingTypeId: 6,
        savingType: 'Share Account Total',
        amSavingType: 'ጠቅላላ የአክስዮን ቁጠባ',
        plannedby: data.id
      }
    ]);
  }, [loanSaving, voluntary, funeral, compulsary, timeDeposit]);

  function formatDateForDjango(year, month, date) {
    const formattedDate = new Date(year, month - 1, date);
    // Format the date to match Django's expected input format
    return formattedDate.toISOString().split('T')[0];
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const getClientByExternalId = async externalId => {
    setClientInitial([]);
    setClientDetail({});
    setIsLoading(true);

    try {
      const res_ci = await axiosInstance.get(
        `${serverIP}/fineract/clientbyexternalid?entityExternalId=${Number(externalId)}`
      );
      setClientInitial(res_ci.data);

      const res_lps = await axiosInstance.get(`${serverIP}/withdrawcustomers/clientbyexternalid/${externalId}`);
      setDjangoUser(res_lps.data);

      const res_ud = await axiosInstance.get(
        `${serverIP}/fineract/userdetail?entityAccountNo=${res_ci.data[0]?.entityId}`
      );
      setClientDetail(res_ud.data);

      const res_as = await axiosInstance.get(
        `${serverIP}/fineract/allsaving?entityAccountNo=${res_ci.data[0]?.entityId}`
      );

      const sum_savings = res_as.data.savingsAccounts?.reduce((accum, obj) => {
        return accum + (obj.accountBalance ? obj.accountBalance : 0);
      }, 0);

      setLoanSaving(res_as.data.savingsAccounts?.filter(x => x.productName.toString().includes('LOAN')));

      setVoluntary(res_as.data.savingsAccounts?.filter(x => x.productName.toString().includes('VOLUN')));

      setFuneral(res_as.data.savingsAccounts?.filter(x => x.productName.toString().includes('FUN')));

      setCompulsary(res_as.data.savingsAccounts?.filter(x => x.productName.toString().includes('COMP')));

      setTimeDeposit(res_as.data.savingsAccounts?.filter(x => x.productName.toString().includes('TIME')));

      const sum_shares = res_as.data.shareAccounts?.reduce((accum, obj) => {
        return accum + obj.totalApprovedShares;
      }, 0);

      setShareAccountTotal(sum_shares);
      setSavingAccountTotal(sum_savings);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const copyCustomer = () => {
    axiosInstance
      .post(`${serverIP}/withdrawcustomers/withdrawcustomers`, {
        entityAccountNo: clientDetail.accountNo,
        entityExternalId: clientDetail.externalId,

        activationDate: formatDateForDjango(
          clientDetail?.activationDate[0],
          clientDetail?.activationDate[1],
          clientDetail?.activationDate[2]
        ),
        memberSince: formatDateForDjango(
          clientDetail?.activationDate[0],
          clientDetail?.activationDate[1],
          clientDetail?.activationDate[2]
        ),
        active: clientDetail.active,
        displayName: clientDetail.displayName,
        amDisplayName: clientDetail.displayName,
        firstname: clientDetail.firstname,
        amFirstname: clientDetail.firstname,
        middlename: clientDetail.middlename,
        amMiddlename: clientDetail.middlename,
        lastname: clientDetail.lastname,
        amLastname: clientDetail.lastname,
        gender: clientDetail.gender?.name,
        mobileNo: clientDetail.mobileNo,
        address: 'አዲስ አበባ',
        amAddress: 'አዲስ አበባ',
        dateOfBirth: formatDateForDjango(
          clientDetail?.dateOfBirth[0],
          clientDetail?.dateOfBirth[1],
          clientDetail?.dateOfBirth[2]
        ),
        isMarried: false
      })
      .then(res => {
        // searchUserOn_Django();
        getClientByExternalId(externalId);
        console.log(res.data);
      })
      .catch(err => {
        console.log(' catch');
        console.log(err);
      });
  };

  const saveAllAccounts = () => {
    console.log(allAccount);
    allAccount.map(account => {
      axiosInstance
        .post(`${serverIP}/withdrawals/withdrawal`, account)
        .then(res => {
          console.log(res.data);
          getClientByExternalId(externalId);
        })
        .catch(err => {
          console.log(err);
        });
    });
  };

  const redirectPage = async customerId => {
    try {
      await getCustomerWithdrawalPlans(customerId);
      navigate(`/withdrawtab/${customerId}`);
    } catch (error) {
      console.error('Error in redirecting:', error);
    }
  };

  const getCustomerWithdrawalPlans = async customerId => {
    try {
      const res = await axiosInstance.get(`${serverIP}/withdrawals/withcustomerId/${customerId}`);
      dispatch(setTargetWithdraw({ withdraw: res.data }));
    } catch (err) {
      console.error('Error fetching loan:', err);
    }
  };

  return (
    <div>
      {/* {JSON.stringify(data)} */}
      <div className="row">
        <div className="col-sm-12">
          <InputGroup className="mb-3">
            <Form.Control
              placeholder={t('applicant.clientID')}
              value={externalId}
              onChange={ev => {
                setExternalId(ev.target.value);
              }}
              onKeyDown={ev => {
                if (ev.key === 'Enter') {
                  getClientByExternalId(externalId);
                }
              }}
            />
            <Button onClick={() => getClientByExternalId(externalId)} variant="outline-secondary" id="button-addon2">
              {t('applicant.search')}
            </Button>
          </InputGroup>
        </div>
      </div>
      <div className="row">
        {(clientInitial.length > 0 || Object.keys(clientInitial).length > 0) &&
        (Object.keys(clientDetail).length > 0 || clientDetail.length > 0) ? (
          <>
            <div className="col-sm-6">
              <Card>
                <Card.Header className="flex flex-row items-center gap-2">
                  <FaUserAlt /> የተበዳሪ መረጃ
                </Card.Header>
                <ListGroup>
                  <ListGroup.Item>ሙሉ ስም : {clientDetail.displayName}</ListGroup.Item>
                  <ListGroup.Item>
                    {t('applicant.entityAccountNo')}:{clientDetail.accountNo}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {t('applicant.account')}:{clientDetail.externalId}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {t('applicant.gender')}: {clientDetail.gender?.name}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {t('applicant.mobileNo')}: {clientDetail.mobileNo}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {t('applicant.maritalStatus')}:
                    {clientDetail.isMarried ? t('review.Married') : t('review.Unmarried')}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {/* activationDate: {amisisUser.activationDate} */}
                    {t('applicant.activationDate')}:
                    {formatDateForDjango(
                      clientDetail?.activationDate[0],
                      clientDetail?.activationDate[1],
                      clientDetail?.activationDate[2]
                    )}
                  </ListGroup.Item>
                  <ListGroup.Item>
                    {t('applicant.dateOfBirth')}:
                    {formatDateForDjango(
                      clientDetail?.dateOfBirth[0],
                      clientDetail?.dateOfBirth[1],
                      clientDetail?.dateOfBirth[2]
                    )}
                  </ListGroup.Item>
                </ListGroup>
                <ListGroup></ListGroup>
                <Card.Footer>
                  {djangoUser.length > 0 ? (
                    <Alert> የደንበኛ መረጃ ተገልብጧል </Alert>
                  ) : (
                    <Button onClick={copyCustomer}>{t('applicant.copyUser')}</Button>
                  )}
                </Card.Footer>
              </Card>
            </div>
            <div className="col-sm-6">
              <Card>
                <Card.Header className="flex flex-row items-center gap-2">
                  <FaMoneyBillWave /> <div>የአካውንት መረጃ </div>
                </Card.Header>
                <ListGroup>
                  <ListGroup>
                    <ListGroup.Item>{`የብድር ቁጠባ፡ ${loanSavingSum?.toLocaleString()}`}</ListGroup.Item>
                    <ListGroup.Item>{`የፍላጎት ቁጠባ፡ ${voluntarySum?.toLocaleString()}`}</ListGroup.Item>
                    <ListGroup.Item>{`ፉነራል ቁጠባ፡ ${funeralSum?.toLocaleString()}`}</ListGroup.Item>
                    <ListGroup.Item>{`የጊዜ ቁጠባ፡ ${timeDepositSum?.toLocaleString()}`}</ListGroup.Item>
                    <ListGroup.Item>{`ኮምፑልሳሪ ቁጠባ፡ ${compulsarySum?.toLocaleString()}`}</ListGroup.Item>
                    <ListGroup.Item>{`ጠቅላላ ቁጠባ፡ ${savingAccountTotal?.toLocaleString()}`}</ListGroup.Item>
                    <ListGroup.Item>{`ጠቅላላ የአክስዮን ቁጠባ፡ ${shareAccountTotal?.toLocaleString()}`}</ListGroup.Item>
                  </ListGroup>
                </ListGroup>
                <Card.Footer>
                  {djangoUser.length > 0 ? (
                    <>
                      {djangoUser[0]?.tobewithdrawen?.length > 0 ? (
                        <Link
                          onClick={() => {
                            redirectPage(djangoUser[0]?.id);
                          }}
                          className="font-medium text-blue-600 dark:text-blue-500 underline hover:underline text-xl"
                        >
                          Start Planning Withdraw
                        </Link>
                      ) : (
                        <Button onClick={saveAllAccounts}>Save All Accounts</Button>
                      )}
                    </>
                  ) : (
                    <Alert>Copy User Detail First</Alert>
                  )}
                </Card.Footer>
              </Card>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
      <LpsWithdraws />
    </div>
  );
}

export default Withdraw;
