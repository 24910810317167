import axiosInstance from '../axiosInstance';
import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import LoanStatus from '../Common/LoanStatus';
import DisburseStatus from '../Common/DisburseStatus';

function AuditorFinishedLoans() {
  const { serverIP } = useSelector(store => store.allsettings);
  const [finishedLns, setFinishedLns] = useState([]);
  // const [filteredLoans, setFilteredLoans] = useState([]);

  useEffect(() => {
    getFinishedLoans();
  }, []);

  const getFinishedLoans = async () => {
    await axiosInstance
      .get(`${serverIP}/loan/finishedloans`)
      .then(res => {
        setFinishedLns(res.data);
        // setFilteredLoans(res.data);
        console.log(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th> ቁጥር </th>
            <th> ሙሉ ስም </th>
            <th> የተፈቀደው የብድር መጠን </th>
            <th> ድርጊት</th>
            <th> ሁኔታ</th>
            <th> ሪፖርት </th>
            <th> Disburse</th>
          </tr>
        </thead>
        <tbody>
          {finishedLns.length > 0 &&
            finishedLns.map(loan => {
              return (
                <tr key={loan.id}>
                  <td>{loan.customer.entityExternalId + ' - ' + loan.customer.id}</td>
                  <td> {loan.customer.amDisplayName} </td>
                  <td> {loan.approvedPrincipal?.toLocaleString()} </td>

                  <td>
                    {loan.teamleaderchecking ? (
                      ' - '
                    ) : (
                      <Link
                        className="font-medium text-blue-600 dark:text-blue-500 underline hover:underline text-xl"
                        disabled={true}
                        onClick={() =>
                          // redirectPage(loan?.customer?.id, loan?.id)
                          console.log('object')
                        }
                      >
                        ማስታካከያ
                      </Link>
                    )}
                  </td>
                  <td>
                    <LoanStatus loan={loan} />
                  </td>
                  <td>
                    <Link
                      to={`/reports/${loan?.customer?.id}/${loan?.id}`}
                      className="font-medium text-blue-400 dark:text-blue-200 underline hover:underline text-xl"
                    >
                      ሪፖርት
                    </Link>
                  </td>
                  <td>
                    <DisburseStatus loan={loan} />
                  </td>
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
}

export default AuditorFinishedLoans;
