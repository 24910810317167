const ones = [
  "",
  "ሓደ",
  "ክልተ",
  "ሰለስተ",
  "አርባዕተ",
  "ሓሙሽተ",
  "ሽዱሽተ",
  "ሸውዓተ",
  "ሸሞንተ",
  "ትሸዓንተ",
  "ዓሰርተ",
  "ዓሰርተ ሓደ",
  "ዓሰርተ ክልተ",
  "ዓሰርተ ሰለስተ",
  "ዓሰርተ አርባዕተ",
  "ዓሰርተ ሓሙሽተ",
  "ዓሰርተ ሽዱሽተ",
  "ዓሰርተ ሸውዓተ",
  "ዓሰርተ ሸሞንተ",
  "ዓሰርተ ትሸዓንተ",
];

const tens = ["", "", "ዒስራ", "ሰላሳ", "አርባዓ", "ሓምሳ", "ስልሳ", "ሰብዓ", "ሰማንያ", "ቴስዓ"];

const scales = ["", "ሽሕን", "ሚልየንን", "ቢልየን", "ትሪልየን"];

function convertHundreds(num) {
  let result = "";
  if (num > 99) {
    result += ones[Math.floor(num / 100)] + " ሚእቲን ";
    num %= 100;
  }
  if (num > 19) {
    result += tens[Math.floor(num / 10)] + "ን ";
    num %= 10;
  }
  if (num > 0) {
    result += ones[num] + " ";
  }
  return result.trim();
}

function numberToWords(theoriginalnumber) {
  let AmnewText = "";
  function isFloat(n) {
    return n === +n && n !== (n | 0);
  }
  if (isFloat(theoriginalnumber)) {
    let strnum = theoriginalnumber.toString();
    let dotindex = strnum.indexOf(".");

    let firstHalf = strnum.substring(0, dotindex);
    let secondHalf = strnum.substring(dotindex + 1);
    AmnewText = numberConvert(firstHalf);
    AmnewText = AmnewText + ` ቅርሽን ${secondHalf}/100 ሳንቲምን ጥራሕ`;
    // AmnewText =
    //   AmnewText + " ቅርሽን " + numberConvert(secondHalf) + " ሳንቲም ጥራሕ";
  } else {
    AmnewText = numberConvert(theoriginalnumber) + " ቅርሺ ጥራሕ";
  }
  return AmnewText;
}

function numberConvert(num) {
  if (num === 0) return "ዜሮ";
  if (num < 0) return " - " + numberToWords(-num);

  let result = "";
  let scaleIndex = 0;

  while (num > 0) {
    const chunk = num % 1000;
    if (chunk > 0) {
      result = convertHundreds(chunk) + " " + scales[scaleIndex] + " " + result;
    }
    num = Math.floor(num / 1000);
    scaleIndex++;
  }

  return result.trim();
}

export { numberToWords };
