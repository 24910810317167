import axiosInstance from '../../axiosInstance';
import React, { useEffect, useState } from 'react';
import { Table, Modal, Alert, Button, InputGroup } from 'react-bootstrap';

import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import { FaEye, FaFileAlt } from 'react-icons/fa';
import { PulseLoader } from 'react-spinners';

function CarFiles() {
  const { serverIP } = useSelector(store => store.allsettings);
  const { data } = useSelector(store => store.customer);
  const { carId, loanId } = useParams();
  const [show, setShow] = useState(false);
  const [viewObj, setViewObj] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [file, setFile] = useState(null);
  const [targetLoan, setTargetLoan] = useState([]);
  const [carfiles, setCarFiles] = useState([]);

  const [marketEstimatedValue, setMarketEstimatedValue] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    getLoan();
    getCarFiles();
  }, []);

  if (isLoading) {
    return (
      <div className="h-28 flex items-center justify-center bg-gray-100">
        <div className="flex flex-col gap-3 items-center justify-center">
          <PulseLoader size={30} color="orange" loading="true" />
          <div style={{ color: '#FFA500' }}>Loading ...</div>
        </div>
      </div>
    );
  }

  const handleUpload = async fileid => {
    if (!file) {
      toast.error('No File Selected');
      return;
    }

    try {
      const fd = new FormData();
      fd.append('file', file);
      fd.append('parent', carId);
      fd.append('fileType', file.type);
      fd.append('fileUrl', file);
      fd.append('isUploaded', true);

      const fileuploaded = await axiosInstance.patch(`${serverIP}/car_commonfiles/carfiles/${fileid}`, fd, {
        headers: {
          'Custom-Header': 'value'
        }
      });
    } catch (error) {
      console.log('error uploading file');
    } finally {
      getLoan();
      getCarFiles();
    }
  };

  const handleDeleteFile = fileid => {
    try {
      setIsLoading(true);
      axiosInstance
        .patch(`${serverIP}/car_commonfiles/carfiles/${fileid}`, {
          isUploaded: false
        })
        .then(res => {
          toast.success('file deleted successfully');
          getLoan();
          getCarFiles();
        })
        .catch(err => {
          toast.error('file upload error');
          console.log(err);
        });
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  };

  const loadData = async () => {
    setIsLoading(true);
    console.log('loading data...');
    try {
      const chk = await axiosInstance.get(`${serverIP}/ddlcar/ddlcars`);
      const transformedArray = chk?.data?.map(item => ({
        parent: carId,
        user: data.id,
        amDesc: item.amName,
        checkListId: item.id,
        isMandatory: item.isMandatory,
        markValue: item.markValue
      }));
      console.log('transformedArray');
      console.log(transformedArray);
      // First POST request
      transformedArray.map(tr => {
        axiosInstance.post(`${serverIP}/car_commonfiles/carfiles`, tr);
      });

      // Second POST request
      const insert_GarageValue = await axiosInstance.post(`${serverIP}/car_garagevalue/garagereports`, {
        collateralcar: carId
      });
      console.log('Response insert_GarageValue:', insert_GarageValue.data);

      // Third POST request
      const insert_MarketValue = await axiosInstance.post(`${serverIP}/car_marketvalue/carmarketvalues`, {
        collateralcar: carId
      });
      console.log('Response insert_MarketValue:', insert_MarketValue.data);
      // Do something with the responses if needed
      getLoan();
      getCarFiles();
      // navigate(`/carcollateralfiles/${loanId}/${carId}`);

      // navigate(`/carcollateralfiles/${loanId}/${carId}`);
      // window.location.reload();
      setIsLoading(false);
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const reLoadData = async () => {
    try {
      setIsLoading(true);
      const commonfiles = await axiosInstance.delete(`${serverIP}/car_commonfiles/carfiles/deletebyparent/${carId}`);
      const garageReport = await axiosInstance.delete(
        `${serverIP}/car_garagevalue/garagereports/deletebyparent/${carId}`
      );
      const marketValue = await axiosInstance.delete(
        `${serverIP}/car_marketvalue/carmarketvalues/deletebyparent/${carId}`
      );
    } catch (error) {
    } finally {
      getLoan();
      getCarFiles();
      setIsLoading(false);
    }
  };

  const getLoan = async () => {
    try {
      const theLoan = await axiosInstance.get(`${serverIP}/loan/loans/${loanId}`);
      console.log('Target Loan ');
      setTargetLoan(theLoan.data);
    } catch (error) {
    } finally {
    }
  };

  const getCarFiles = async () => {
    try {
      const theCarFiles = await axiosInstance.get(`${serverIP}/car_commonfiles/carfiles/bycarid/${carId}`);
      console.log('theCarFiles.data');
      console.log(theCarFiles.data);
      setCarFiles(theCarFiles.data);
    } catch (error) {
    } finally {
    }
  };

  return (
    <div>
      {/* Modal Start  */}
      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title> {viewObj.amDesc} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={serverIP + viewObj.fileUrl} style={{ width: '100%', height: '100%' }} />
        </Modal.Body>
      </Modal>
      {/* Modal End  */}

      {carfiles?.length > 0 ? (
        <>
          <Alert style={{ display: 'flex', justifyContent: 'space-between' }} className="mt-1 py-2 items-center">
            <div>የመኪና ዋስ ፋይል ዝርዝር</div>
            <Button className="btn btn-warning btn-sm" onClick={reLoadData}>
              የፋይል ዝርዝር አጥፋ
            </Button>
          </Alert>
          <Table striped bordered hover style={{ margin: 1 }}>
            <thead>
              <tr>
                <th> የፋይል ስም </th>
                <th> እይ </th>
                <th> አስፈላጊነት </th>
                <th> ድርጊት </th>
                <th> አጥፋ </th>
              </tr>
            </thead>
            <tbody>
              {carfiles.map(upload => {
                return (
                  <tr className={upload.isUploaded ? `` : `table-warning`}>
                    <td>{upload.amDesc}</td>
                    <td>
                      {upload.isUploaded ? (
                        <>
                          {upload.fileType === 'application/pdf' ? (
                            <a target="_blank" href={serverIP + upload.fileUrl} rel="noopener noreferrer">
                              <FaFileAlt />
                            </a>
                          ) : (
                            <FaEye
                              onClick={() => {
                                setViewObj(upload);
                                setShow(true);
                              }}
                            />
                          )}
                        </>
                      ) : (
                        <></>
                      )}
                    </td>
                    <td> {upload.isMandatory ? 'ግዴታ' : 'አማራጭ'} </td>
                    <td style={{ width: '30%', padding: 0 }}>
                      {data.groups[0] === 'ROLE_OFFICER' ? (
                        <>
                          <InputGroup style={{ paddingTop: 5 }}>
                            <input
                              onChange={e => {
                                setFile(e.target.files[0]);
                                console.log(e.target.files[0]);
                              }}
                              type="file"
                              className="form-control"
                            />
                            <Button variant="outline-primary" onClick={() => handleUpload(upload.id)}>
                              {!upload.isUploaded ? <> ጫን </> : <>አዘምን</>}
                            </Button>
                          </InputGroup>
                        </>
                      ) : (
                        <>{upload.isUploaded ? 'ተጭኗል' : ' '}</>
                      )}
                    </td>
                    <td style={{ width: '10%', padding: 0 }}>
                      {upload.isUploaded && (
                        <Button variant="outline-danger" onClick={() => handleDeleteFile(upload.id)}>
                          አጥፋ
                        </Button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </>
      ) : (
        <Alert className="flex justify-between p-2">
          <div> የመኪና መረጃዎች ሳብ </div>
          <Button className="btn-sm" onClick={() => loadData()}>
            ሳብ
          </Button>
        </Alert>
      )}
    </div>
  );
}

export default CarFiles;
