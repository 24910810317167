import axiosInstance from '../axiosInstance';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, Modal, Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';

function Loan() {
  const [t, i18n] = useTranslation('global');
  const { serverIP } = useSelector(store => store.allsettings);
  const [allLoans, setAllLoans] = useState([]);
  const [targetObj, setTargetObj] = useState({});
  const [modalDelete, setModalDelete] = useState(false);

  useEffect(() => {
    getAllLoans();
  }, []);

  const getAllLoans = async () => {
    try {
      const res = await axiosInstance.get(`${serverIP}/loan/loans`);
      setAllLoans(res.data);
    } catch (err) {
      console.error('Error fetching loan:', err);
    }
  };

  const deleteLoan = () => {
    console.log('object deleted = ' + targetObj.id);
    axiosInstance
      .delete(`${serverIP}/loan/loans/${targetObj.id}`)
      .then(res => {
        console.log(res.data);
        getAllLoans();
      })
      .catch(err => {
        console.log(err);
      });
    setModalDelete(false);
  };

  return (
    <div>
      {/* Modal Delete Start  */}
      <Modal show={modalDelete} onHide={() => setModalDelete(false)}>
        <Modal.Header style={{ color: 'red' }} closeButton>
          <Modal.Title color="red"> {t('officer.delete')} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('admin.confirmDelete')} <strong> {targetObj.enName} </strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => deleteLoan()}>
            {t('officer.delete')}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Delete End  */}

      {allLoans.length > 0 && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>{t('applicant.externalID')}</th>
              <th>{t('applicant.fullName')}</th>
              <th> የተፈቀደው የብድር መጠን </th>
              <th> ኦፊሰር </th>
              <th> ግምገማ </th>
              <th> ሪፖርት </th>
              <th> አጥፋ </th>
            </tr>
          </thead>
          <tbody>
            {allLoans.map(loan => {
              return (
                <tr>
                  <td>{loan.customer.entityExternalId}</td>
                  <td> {loan.customer.amDisplayName} </td>
                  <td> {loan.r_approvedPrincipal?.toLocaleString()} </td>
                  <td>{loan?.submittedBy?.first_name + '  ' + loan?.submittedBy?.last_name}</td>
                  <td>
                    <Link
                      to={`/loanevaluation/${loan?.customer?.id}/${loan?.id}`}
                      className="font-medium text-blue-400 dark:text-blue-200 underline hover:underline text-xl"
                    >
                      ግምገማ
                    </Link>
                  </td>

                  <td>
                    <Link
                      to={`/reports/${loan?.customer?.id}/${loan?.id}`}
                      className="font-medium text-blue-400 dark:text-blue-200 underline hover:underline text-xl"
                    >
                      ሪፖርት
                    </Link>
                  </td>
                  <td>
                    {loan.auditorapproved ? (
                      <>ያለቀ</>
                    ) : (
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => {
                          setTargetObj(loan);
                          setModalDelete(true);
                        }}
                      >
                        አጥፋ
                      </button>
                    )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export default Loan;
