import * as Yup from "yup";

const HomeCollateralSchema = Yup.object().shape({
  homeType: Yup.number().required("የቤት አይነት ይምረጡ"),
  locationtxt: Yup.string().required("የሚገኝበት ቦታ ያስገቡ"),
  // bldgno: Yup.string().required("የህንጻ ቁጥር ያስገቡ"),
  // floorno: Yup.string().required("የወለል ቁጥር ያስገቡ"),
  // subcity: Yup.string().required("የክ/ከተማ ስም ይምረጡ"),
  // woreda: Yup.string().required("የወረዳ ስም ያስገቡ"),
  
  regionId: Yup.number().required("ክልል ይምረጡ"),
  zoneId: Yup.number().required("ዞን ይምረጡ"),
  woredaId: Yup.number().required("ወረዳ ይምረጡ"),

  houseno: Yup.string().required("የቤት ቁጥር ያስገቡ"),
  uniquenum: Yup.string().required("የካርታ መ.ቁጥር ያስገቡ"),
  amName: Yup.string().required("ለዩ ስም ያስገቡ"),
  homeArea: Yup.number().required("የቤቱ ስፋት ያስገቡ"),
  sunctionedAt: Yup.date().required("እግድ የተሰጠበት ቀን ያስገቡ"),
  serialnum: Yup.string().required("ሴሪ ቁጥር ያስገቡ "),
});

export default HomeCollateralSchema;
