import React, { useState, useEffect } from 'react';
import { Alert, Button, ButtonGroup, Table } from 'react-bootstrap';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../axiosInstance';

function CarModel() {
  const [t] = useTranslation('global');
  const { serverIP } = useSelector(store => store.allsettings);
  const [carModel, setCarModel] = useState([]);
  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);

  const [targetObj, setTargetObj] = useState({});
  const [enName, setEnName] = useState('');

  useEffect(() => {
    getCarModel();
  }, []);

  const getCarModel = () => {
    axiosInstance
      .get(`${serverIP}/car_model/cars`)
      .then(function (response) {
        console.log(response.data);
        setCarModel(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const saveCarModel = () => {
    console.log('save');
    setModalAdd(false);
    axiosInstance
      .post(`${serverIP}/car_model/cars`, {
        enName: enName
      })
      .then(function (response) {
        console.log(response);
        getCarModel();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const editCarModel = () => {
    axiosInstance
      .patch(`${serverIP}/car_model/cars/${targetObj.id}`, {
        enName: enName
      })
      .then(function (response) {
        console.log(response);
        getCarModel();
        setModalEdit(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteCarModel = () => {
    axiosInstance
      .delete(`${serverIP}/car_model/cars/${targetObj.id}`)
      .then(res => {
        console.log(res.data);
        setModalDelete(false);
        getCarModel();
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <div>
      {/* Modal Delete Start  */}
      <Modal show={modalDelete} onHide={() => setModalDelete(false)}>
        <Modal.Header style={{ color: 'red' }} closeButton>
          <Modal.Title color="red"> {t('officer.delete')} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('admin.confirmDelete')} <strong> {targetObj.enName} </strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => deleteCarModel()}>
            {t('officer.delete')}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Delete End  */}
      {/* Modals Start  */}
      <Modal show={modalAdd} onHide={() => setModalAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('officer.add')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">{t('profile.name')}</InputGroup.Text>
            <Form.Control
              placeholder={t('profile.name')}
              aria-describedby="basic-addon1"
              onChange={e => setEnName(e.target.value)}
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => saveCarModel()}>
            {t('officer.save')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modalEdit} onHide={() => setModalEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: 'orange' }}> አስተካክል </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">{t('profile.name')}</InputGroup.Text>
            <Form.Control value={enName} aria-describedby="basic-addon1" onChange={e => setEnName(e.target.value)} />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={() => editCarModel()}>
            {t('officer.edit')}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modals End  */}

      <Alert className="px-3 flex flex-row justify-between py-1 items-center">
        <div> {t('admin.carModel')} </div>
        <Button variant="primary" style={{ margin: 0 }} className="btn btn-sm" onClick={() => setModalAdd(true)}>
          {t('officer.Add')}
        </Button>
      </Alert>

      {carModel.length > 0 && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th> {t('profile.name')}</th>
              <th>{t('auditor.action')}</th>
            </tr>
          </thead>
          <tbody>
            {carModel.map(cmodel => {
              return (
                <tr>
                  <td>{cmodel.id}</td>
                  <td>{cmodel.enName}</td>
                  <td>
                    <ButtonGroup size="sm">
                      <Button variant="danger">
                        <FaTrashAlt
                          onClick={() => {
                            setModalDelete(true);
                            setTargetObj(cmodel);
                          }}
                        />
                      </Button>
                      <Button variant="warning">
                        <FaPencilAlt
                          onClick={() => {
                            setModalEdit(true);
                            setEnName(cmodel.enName);
                            setTargetObj(cmodel);
                          }}
                        />
                      </Button>
                    </ButtonGroup>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export default CarModel;
