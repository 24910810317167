function Sum_Cogs(loanFinanceItems) {
  const costofgoods = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.category_id == 1 ? obj.markValue : 0);
  }, 0);
  return costofgoods;
}

function Sum_Operating_Expenses(loanFinanceItems) {
  const operatingExpense = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.category_id == 2 ? obj.markValue : 0);
  }, 0);
  return operatingExpense;
}

function Sum_Interest_Expenses(loanFinanceItems) {
  const interestExpenses = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.category_id == 3 ? obj.markValue : 0);
  }, 0);
  return interestExpenses;
}

function Sum_Tax_Expenses(loanFinanceItems) {
  const taxExpenses = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.category_id == 4 ? obj.markValue : 0);
  }, 0);
  return taxExpenses;
}

function Sum_Personal_Expenses(loanFinanceItems) {
  const personalExpenses = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.category_id == 5 ? obj.markValue : 0);
  }, 0);
  return personalExpenses;
}

function Sum_Incomes(loanFinanceItems) {
  const incomes = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.category_id == 6 ? obj.markValue : 0);
  }, 0);
  return incomes;
}

function Sum_Assets(loanFinanceItems) {
  const assets = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.category_id == 7 ? obj.markValue : 0);
  }, 0);
  return assets;
}

function Sum_Liabilities(loanFinanceItems) {
  const liabilities = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.category_id == 8 ? obj.markValue : 0);
  }, 0);
  return liabilities;
}

function Sum_Capitals(loanFinanceItems) {
  const capitals = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.category_id == 9 ? obj.markValue : 0);
  }, 0);
  return capitals;
}

function Monthly_Basic_Expenses(loanFinanceItems) {
  const capitals = loanFinanceItems.reduce((accum, obj) => {
    return accum + ((obj.category_id == 1 || obj.category_id == 2 || obj.category_id == 3 || obj.category_id == 4 || obj.category_id == 5) ? obj.markValue : 0);
  }, 0);
  return capitals;
}

function Sum_MaintenanceRelatedExpenses(loanFinanceItems) {
  const capitals = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.category_id == 10 ? obj.markValue : 0);
  }, 0);
  return capitals;
}

function Sum_FinancialExpenses(loanFinanceItems) {
  const capitals = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.category_id == 11 ? obj.markValue : 0);
  }, 0);
  return capitals;
}

function Sum_OtherExpenses(loanFinanceItems) {
  const capitals = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.category_id == 12 ? obj.markValue : 0);
  }, 0);
  return capitals;
}


function calculateIRR(cashFlows) {
  const guess = 0.1; // Initial guess for the IRR
  const maxIterations = 100; // Maximum number of iterations for approximation
  const precision = 1e-6; // Desired precision for IRR

  let rate = guess;
  for (let i = 0; i < maxIterations; i++) {
    const npv = cashFlows.reduce((acc, cf, index) => acc + cf / Math.pow(1 + rate, index), 0);
    const dNpv = cashFlows.reduce(
      (acc, cf, index) => acc - (index * cf) / Math.pow(1 + rate, index + 1),
      0
    );

    const newRate = rate - npv / dNpv;
    if (Math.abs(newRate - rate) < precision) {
      return newRate.toFixed(2); // IRR in decimal form
    }
    rate = newRate;
  }

  throw new Error("IRR did not converge");
}


function DebtServiceCoverageRatio(loanFinanceItems) {

  const capitals = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.id == 19 || obj.id == 24 ? obj.markValue : 0);
  }, 0);
  return capitals.toFixed(2);;
}

function DebtServiceCoverageRatioNew(targetLoan, loanFinanceItems) {

  const capitals = loanFinanceItems.reduce((accum, obj) => {
    return accum + (obj.id == 19 || obj.id == 24 ? obj.markValue : 0);
  }, 0);

  let maintenanceExp = Sum_MaintenanceRelatedExpenses(targetLoan.loan_fonance_items);
  let diff = capitals - maintenanceExp;

  return (diff / targetLoan?.totalDueForPeriod).toFixed(2);
}

function NetProfit(targetLoan) {
  // Q19
  let businessGrossIncome = Sum_Incomes(targetLoan?.loan_fonance_items)
  let totalExpenses = Monthly_Basic_Expenses(targetLoan?.loan_fonance_items)

  return businessGrossIncome - totalExpenses;
}

function Debt_To_Income_Ratio(targetLoan) {
  let incomes = Sum_Incomes(targetLoan?.loan_fonance_items)
  let monthlyExpenses = ((Monthly_Basic_Expenses(targetLoan?.loan_fonance_items) + 5000) * 105) / 100;

  return (targetLoan.totalDueForPeriod * 100 / (incomes - monthlyExpenses)).toFixed(2);;
}

function collateralSum(targetLoan) {
  const sum_car =
    targetLoan?.collateralcar.length > 0
      ? targetLoan?.collateralcar?.reduce((accum, obj) => {
        return (
          accum +
          (obj.insuranceValue +
            (obj?.garageReport[0]?.garageValue ? obj?.garageReport[0]?.garageValue : 0) +
            (obj?.marketvalue[0]?.marketValue ? obj?.marketvalue[0]?.marketValue : 0)) /
          3
        );
      }, 0)
      : 0;

  const sum_home =
    targetLoan?.collateralhome.length > 0
      ? targetLoan?.collateralhome?.reduce((accum, obj) => {
        return accum + obj.homearea * obj.hometype?.pricepercaremeter;
      }, 0)
      : 0;

  const sum_salary =
    targetLoan?.collateralemployee.length > 0
      ? targetLoan?.collateralemployee?.reduce((accum, obj) => {
        return accum + (obj.grossSalary * 100) / 15;
      }, 0)
      : 0;

  const sum_stock =
    targetLoan?.collateralstock.length > 0
      ? targetLoan?.collateralstock?.reduce((accum, obj) => {
        return accum + obj.priceperstock * obj.stockqty;
      }, 0)
      : 0;

  return sum_car + sum_home + sum_salary + sum_stock;
}


function LiquidityRatio(loanFinanceItems) {
  let TotalAsset = Sum_Assets(loanFinanceItems);
  let TotalLiabilities = Sum_Liabilities(loanFinanceItems);

  return TotalAsset / TotalLiabilities;
}

function NetWorth(loanFinanceItems) {
  let TotalAsset = Sum_Assets(loanFinanceItems);
  let TotalLiabilities = Sum_Liabilities(loanFinanceItems);

  return TotalAsset - TotalLiabilities;
}

function Debt_To_Asset_Ratio(loanFinanceItems) {
  let TotalAsset = Sum_Assets(loanFinanceItems);
  let TotalLiabilities = Sum_Liabilities(loanFinanceItems);

  return TotalLiabilities / TotalAsset;
}

function Loan_To_Value_Ratio(targetLoan) {
  // targetLoan.approvedPrincipal
  let collateralvalue = collateralSum(targetLoan);
  return targetLoan.approvedPrincipal / collateralvalue;
}

function CapitalEvaluation(targetLoan) {
  // collateralSum(targetLoan)
  let net_worth = NetWorth(targetLoan?.loan_fonance_items);
  let net_worth_calculated = net_worth > 0 ? 0.1 : 0;

  let debt_to_asset_ratio = Debt_To_Asset_Ratio(targetLoan?.loan_fonance_items);
  let debt_to_asset_ratio_calculated = debt_to_asset_ratio < 0.5 ? 0.3 : 0.1;;

  let ltv = Loan_To_Value_Ratio(targetLoan);
  let ltv_calculated = ltv <= 8 ? 0.5 : 0.2;

  let sum = net_worth_calculated + debt_to_asset_ratio_calculated + ltv_calculated

  return (sum * 100 / 3)?.toFixed(2);
}

function CharacterEvaluation(targetLoan) {
  let tenureInMonths = targetLoan.tenureInMonths > 12 ? 10 : targetLoan.tenureInMonths < 6 ? 3 : 6;
  let previousBusinessTenureInMonths = targetLoan.previousBusinessTenureInMonths > 6 ? 10 : 5;

  // Self Owned = 10, Partnership = 7, Complicated = 0
  let businessOwnership = targetLoan.businessOwnership.markValue
  // Platinium = 10, Gold = 8, Silver = 6
  let savingdecipline = targetLoan.savingdecipline.markValue;
  // Good = 10, Average = -5, Poor = -10
  let referenceinamigos = targetLoan.referenceinamigos?.markValue;
  let lastLoanGoodHistory = targetLoan.lastLoanGoodHistory ? 10 : 7;

  return (40 + tenureInMonths + previousBusinessTenureInMonths + businessOwnership + savingdecipline + referenceinamigos + lastLoanGoodHistory)/20;
}


function min_dir_dscr_irr(targetLoan, loanFinanceItems) {
  let Edited_DIR = 4500 / Debt_To_Income_Ratio(targetLoan);
  let Edited_DSCR = 100 * DebtServiceCoverageRatioNew(targetLoan, loanFinanceItems)
  let Edited_IRR = 10 * calculateIRR([loanFinanceItems[35]?.markValue, loanFinanceItems[36]?.markValue]);

  // return Math.min(Edited_DIR, Edited_DSCR, Edited_IRR)
  return Math.min(Edited_DIR, Edited_DSCR, Edited_IRR)
}

function dir_vs_dscd(targetLoan, loanFinanceItems) {
  let Edited_DIR = 4500 / Debt_To_Income_Ratio(targetLoan);
  let Edited_DSCR = 100 * DebtServiceCoverageRatioNew(targetLoan, loanFinanceItems)

  let result = 0;
  if (Edited_DIR > 100) {
    result = 50; // 50%
  } else if (Edited_DSCR > 100) {
    result = 50; // 50%
  } else {
    result = 0; // 0%
  }
  return result
}


function finalAbilityToPay(targetLoan, loanFinanceItems) {
  let Edited_DIR = 4500 / Debt_To_Income_Ratio(targetLoan);
  let Edited_DSCR = 100 * DebtServiceCoverageRatioNew(targetLoan, loanFinanceItems)
  let Edited_IRR = 10 * calculateIRR([loanFinanceItems[35]?.markValue, loanFinanceItems[36]?.markValue]);

  let result = 0;
  if (Edited_DIR > 100) {
    result = 50; // 50%
  } else if (Edited_DSCR > 100) {
    result = 50; // 50%
  } else {
    result = 0; // 0%
  }

  let min = Math.min(Edited_DIR, Edited_DSCR, Edited_IRR)
  return result + min

}

// const validateIRRPercentage = (initialInvestment, cashFlow, period = 1) => {
//   if (typeof initialInvestment !== "number" || typeof cashFlow !== "number" || typeof period !== "number") {
//     return { isValid: false, message: "Initial investment, cash flow, and period must be numbers." };
//   }

//   if (initialInvestment >= 0) {
//     return { isValid: false, message: "Initial investment must be a negative number." };
//   }

//   if (cashFlow <= 0) {
//     return { isValid: false, message: "Cash flow must be a positive number." };
//   }

//   if (period <= 0) {
//     return { isValid: false, message: "Period must be greater than zero." };
//   }

//   // Calculate IRR
//   const irr = Math.pow(cashFlow / Math.abs(initialInvestment), 1 / period) - 1;

//   if (irr <= 0 || !isFinite(irr)) {
//     return { isValid: false, message: "Cash flow does not produce a valid percentage IRR." };
//   }

//   return { isValid: true, message: `Inputs are valid. IRR is ${(irr * 100).toFixed(2)}%.` };
// };



module.exports = {
  Sum_Cogs,
  Sum_Operating_Expenses,
  Sum_Interest_Expenses,
  Sum_Tax_Expenses,
  Sum_Personal_Expenses,
  Sum_Incomes,
  Sum_Assets,
  Sum_Liabilities,
  Sum_Capitals,
  Monthly_Basic_Expenses,
  Sum_MaintenanceRelatedExpenses,
  Sum_FinancialExpenses,
  Sum_OtherExpenses,
  calculateIRR,
  DebtServiceCoverageRatio,
  DebtServiceCoverageRatioNew,
  LiquidityRatio,
  NetWorth,
  Debt_To_Asset_Ratio,
  Loan_To_Value_Ratio,
  CapitalEvaluation,
  CharacterEvaluation,
  Debt_To_Income_Ratio,
  min_dir_dscr_irr,
  dir_vs_dscd,
  finalAbilityToPay,
};
