import React, { useEffect, useState } from 'react';
import { Alert, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import axiosInstance from '../axiosInstance';

function NearWithdrawDate() {
  const { serverIP } = useSelector(store => store.allsettings);
  const [withdrawals, setWithdrawals] = useState([]);

  useEffect(() => {
    getWithdrawals();
  }, []);

  const getWithdrawals = () => {
    axiosInstance
      .get(`${serverIP}/withdrawrows/withdrawalbydaysleft`)
      .then(res => {
        setWithdrawals(res.data);
        console.log(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  function getDateDifferenceInDays(dateString) {
    // Parse the given date string into a Date object
    const givenDate = new Date(dateString);
    // Get today's date
    const today = new Date();
    // Calculate the difference in time (milliseconds)
    const differenceInTime = givenDate - today;
    // Convert the time difference from milliseconds to days
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
    return differenceInDays;
  }

  return (
    <div>
      <Alert> በቀራቸው ቀናት መሰረት </Alert>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>መጠን</th>
            <th>አመልካች </th>
            <th>ቀሪ ቀናቶች </th>
            <th>ሪፖርት </th>
          </tr>
        </thead>
        <tbody>
          {withdrawals.map(withdraw => {
            return (
              <tr key={withdraw.id}>
                <td>{withdraw?.withdraw_amount?.toLocaleString()}</td>
                <td>{withdraw?.parent?.amDisplayName}</td>
                <td>{getDateDifferenceInDays(withdraw.appointmentDate)}</td>
                <td>
                  <Link
                    to={`/rptwithdraw/${withdraw.id}`}
                    className="font-medium text-blue-600 dark:text-blue-300 underline hover:underline"
                  >
                    ሪፖርት
                  </Link>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default NearWithdrawDate;
