import axiosInstance from '../../axiosInstance';
import React, { useEffect, useState } from 'react';
import { Alert, Tab, Tabs } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import WithdrawOrDisbursePlan from '../Reusables/WithdrawOrDisbursePlan';
import { useSelector } from 'react-redux';

function WithdrawTabs() {
  const { customerId } = useParams();
  const [theCustomer, setTheCustomer] = useState({});
  const { targetWithdraw } = useSelector(store => store.systemLookups);
  const { serverIP } = useSelector(store => store.allsettings);

  useEffect(() => {
    getCustomerWithdrawing(customerId);
  }, []);

  const getCustomerWithdrawing = customerId => {
    console.log(customerId);
    axiosInstance
      .get(`${serverIP}/withdrawcustomers/withdrawcustomers/${customerId}`)
      .then(res => {
        setTheCustomer(res.data);
        console.log(res.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  return (
    <div>
      <Alert>{theCustomer.amDisplayName}</Alert>
      {theCustomer?.tobewithdrawen?.length > 0 ? (
        <Tabs defaultActiveKey="LoanSaving">
          <Tab eventKey="LoanSaving" title="የብድር ተቀማጭ">
            <WithdrawOrDisbursePlan amountTotal={targetWithdraw[0]?.amount} customerId={customerId} arrIndex="0" />
          </Tab>
          <Tab eventKey="VoluntarySaving" title="የፍላጎት ተቀማጭ">
            <WithdrawOrDisbursePlan
              amountTotal={theCustomer?.tobewithdrawen[1]?.amount}
              customerId={customerId}
              arrIndex="1"
            />
          </Tab>
          <Tab eventKey="Funeral" title="ፉነራል ቁጠባ">
            <WithdrawOrDisbursePlan
              amountTotal={theCustomer?.tobewithdrawen[2]?.amount}
              customerId={customerId}
              arrIndex="2"
            />
          </Tab>
          <Tab eventKey="Compulsary" title="ኮምፑልሳሪ ቁጠባ">
            <WithdrawOrDisbursePlan
              amountTotal={theCustomer?.tobewithdrawen[3]?.amount}
              customerId={customerId}
              arrIndex="3"
            />
          </Tab>
          <Tab eventKey="TimeDeposit" title="የግዜ ቁጠባ">
            <WithdrawOrDisbursePlan
              amountTotal={theCustomer?.tobewithdrawen[4]?.amount}
              customerId={customerId}
              arrIndex="4"
            />
          </Tab>
          <Tab eventKey="TotalShare" title="ጠቅላላ የአክስዮን ቁጠባ">
            <WithdrawOrDisbursePlan
              amountTotal={theCustomer?.tobewithdrawen[5]?.amount}
              customerId={customerId}
              arrIndex="5"
            />
          </Tab>
        </Tabs>
      ) : (
        <Alert> No DATA</Alert>
      )}
    </div>
  );
}

export default WithdrawTabs;
