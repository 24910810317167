import React from "react";
import {
  ExtraCompulsarySaved,
  ShareAmountAndEligibilityCheck,
} from "../../Common/modules/WithdrawParameterChecker";

function WithdrawTest() {
  let customer = { memberSince: "2024-04-20" };
  let loan = { totalCompulsary: 150000 };

  return (
    <div>
      <div> WithdrawTest = {ExtraCompulsarySaved(customer, loan)} </div>
      <div>{JSON.stringify(ShareAmountAndEligibilityCheck())}</div>
      {/* <div>{ShareAmountAndEligibilityCheck}</div> */}
    </div>
  );
}

export default WithdrawTest;
