import React from "react";

function WithdrawForm({theWithdraw}) {
  return (
    <div>
      <div className="text-center underline text-4xl">
        የመደበኛ አባላት ወጪ መጠየቅያ ፎርም
      </div>
      <div className="mt-3">ይህ ፎርም በአሚጎስ ሰራተኛ የሚሞላ የወጪ ማዘዣ ፎርም ነው፡፡</div>

      <div className="m-5">
        <div className="flex justify-between">
          <div className="flex">
            የአባሉ ሙሉ ስም
            <div className="border-green-800 border-2 border-opacity-25 ml-2 px-2">
              {theWithdraw?.parent?.amDisplayName}
            </div>
          </div>
          <div className="flex">
            ኮድ
            <div className="border-green-800 border-2 border-opacity-25 ml-2 px-2">
              {theWithdraw?.parent?.entityExternalId}
            </div>
          </div>
        </div>
        <div className="flex justify-between pt-1">
          <div className="flex">
            ስልክ ቁጥር
            <div className="border-green-800 border-2 border-opacity-25 ml-2 px-2">
              {theWithdraw?.parent?.mobileNo}
            </div>
          </div>
          <div className="flex">
            የማህበሩ ስም
            <div className="border-green-800 border-2 border-opacity-25 ml-2 px-2">
              {theWithdraw?.parent?.groupName === null
                ? "Personal "
                : theWithdraw?.parent?.groupName}
            </div>
          </div>
        </div>
      </div>
      <ul class="list-disc list-inside text-gray-700">
            <li class="text-lg font-medium">
              ወጪ የተጠየቀበት ቀን፡ {theWithdraw.plannedDate}
            </li>
            <li class="text-lg font-medium">
              የአሚጎስ አባል የሆኑበት ቀን፡ {theWithdraw.parent?.memberSince}
            </li>
            <li class="text-lg font-medium">
              የመጨረሻ ቁጠባ ገቢ የተደረገበት ቀን፡ {theWithdraw.appointmentDate}
            </li>
          </ul>
          <div>
            <div className="px-6 py-2">
              <ol className="list-none list-inside">
                <li className="text-lg font-medium">
                  1. በዛሬው ቀን ያላቸው የአክስዮን መጠን/ Total Share፡
                  {theWithdraw.amount_share?.toLocaleString()}
                </li>
                <li className="text-lg font-medium">
                  2. በዛሬው ቀን ያላቸው የብድር ቁጠባ መጠን/ Loan Saving፡
                  {theWithdraw.amount_loansaving?.toLocaleString()}
                </li>
                <li className="text-lg font-medium">
                  3. በዛሬው ቀን ያላቸው መደበኛ ቁጠባ መጠን/ Compulsary፡
                  {theWithdraw.amount_compulsary?.toLocaleString()}
                </li>
                <li className="text-lg font-medium">
                  አጠቃላይ ያላቸው ተቀማጭ አክስዮንም ጭምሮ፡
                  {(
                    theWithdraw.amount_share +
                    theWithdraw.amount_loansaving +
                    theWithdraw.amount_compulsary
                  ).toLocaleString()}
                </li>
                <li className="text-lg font-medium"></li>
              </ol>
            </div>

            <div className="py-2 gap-1">
              ስለሆነም ከላይ ስማቸው የተጠቀሱ የድርጅታችን አባል ካላቸው አጠቃላይ ተቀማጭ የ
              <span className="underline">
                {theWithdraw.withdraw_amount?.toLocaleString()}
              </span>
              ብር ወጪ ማድረግ የሚችሉ ስለሆነ ወጪ እንዲደረግላቸው እጠይቃለው፡፡
            </div>

            <div className="py-2 text-xl">
              እኔ ስሜ የተጠቀሰው የማህበሩ አባል ስሆን በድርጅቱ ብድር ለመውሰድ ያስቀመጥኩት ተቀማጭ ብድር የመውሰድ
              ሃሳቤን ስለቀየርኩኝ ከላይ የተጠቀሰው ቁጠባዬ እንዲወጣልኝ እጠይቃለው፡፡
            </div>
          </div>
    </div>
  );
}

export default WithdrawForm;
