import React from 'react';
import { Text, StyleSheet } from '@react-pdf/renderer';

function TextBold(props) {
  const Styles = StyleSheet.create({
    dynamicText: {
      fontFamily: 'ebrimabold'
      // color: props.color ? 'black' : 'green'
    }
  });

  return <Text style={Styles.dynamicText}>{props.text}</Text>;
}

export default TextBold;
