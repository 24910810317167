import React from "react";

function LoanStatus({ loan }) {
  return (
    <div className="flex justify-between">
      <div>
        {loan.auditorapproved
          ? "አለቀ"
          : loan.auditorchecking
          ? "ኦዲተር"
          : loan.teamleaderchecking
          ? "የቡድን መሪ"
          : "ኦፊሰር"}
      </div>
      <div>
        {loan.auditorapproved
          ? "አለቀ"
          : loan.auditorchecking
          ? loan?.auditorid?.first_name
          : loan.teamleaderchecking
          ? loan?.teamleaderid?.first_name
          : loan?.submittedBy?.first_name}
      </div>
    </div>
  );
}

export default LoanStatus;
