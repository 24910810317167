import React, { useState, useEffect } from 'react';
import { Table } from 'react-bootstrap';
import { FaFile } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import axiosInstance from '../axiosInstance';

function TeamLeaderFinishedLoans() {
  const { serverIP } = useSelector(store => store.allsettings);
  const [finishedLns, setFinishedLns] = useState([]);
  const [filteredLoans, setFilteredLoans] = useState([]);

  useEffect(() => {
    getFinishedLoans();
  }, []);

  const getFinishedLoans = async () => {
    await axiosInstance
      .get(`${serverIP}/loan/loans`)
      .then(res => {
        setFinishedLns(res.data);
        setFilteredLoans(res.data);
        console.log(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th> ቁጥር </th>
            <th> ሙሉ ስም </th>
            <th> የተፈቀደው የብድር መጠን </th>
            <th> Doc </th>
            <th> ድርጊት</th>
            <th> ሁኔታ</th>
          </tr>
        </thead>
        <tbody>
          {filteredLoans.map(loan => {
            return (
              <>
                {finishedLns.auditorapproved && (
                  <tr key={loan.id}>
                    <td>{loan.customer.entityExternalId + ' - ' + loan.customer.id}</td>
                    <td> {loan.customer.amDisplayName} </td>
                    <td> {loan.r_approvedPrincipal?.toLocaleString()} </td>
                    <td>
                      <div className="flex justify-center items-center gap-2">
                        <FaFile />
                        <Link
                          className="font-medium text-blue-600 dark:text-blue-500 underline hover:underline text-xl"
                          to={`/requestformdoc/${loan?.customer?.id}/${loan?.id}`}
                        >
                          common.req
                        </Link>
                      </div>
                    </td>
                    <td>
                      {loan.teamleaderchecking ? (
                        ' - '
                      ) : (
                        <Link
                          className="font-medium text-blue-600 dark:text-blue-500 underline hover:underline text-xl"
                          disabled={true}
                          onClick={() =>
                            // redirectPage(loan?.customer?.id, loan?.id)
                            console.log('object')
                          }
                        >
                          ማስታካከያ
                        </Link>
                      )}
                    </td>
                    <td>ሁኔታ</td>
                  </tr>
                )}
              </>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
}

export default TeamLeaderFinishedLoans;
