import React, { useState, useEffect } from 'react';
import { Alert, Button, ButtonGroup, Table } from 'react-bootstrap';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../axiosInstance';

function Zones() {
  const [t] = useTranslation('global');
  const { serverIP } = useSelector(store => store.allsettings);

  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);

  const [targetObj, setTargetObj] = useState({});

  const [allRegions, setallRegions] = useState([]);
  const [allZOnes, setallZOnes] = useState([]);

  const [description, setdescription] = useState('');
  const [amDescription, setamDescription] = useState('');
  const [parent, setParent] = useState(0);

  //   description, amDescription

  useEffect(() => {
    getallRegions();
    getallZOnes();
  }, []);

  const getallRegions = () => {
    axiosInstance
      .get(`${serverIP}/regions/regions`)
      .then(function (response) {
        console.log(response.data);
        setallRegions(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getallZOnes = () => {
    axiosInstance
      .get(`${serverIP}/zones/zone`)
      .then(function (response) {
        console.log(response.data);
        setallZOnes(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const saveallZOnes = () => {
    console.log('save');
    setModalAdd(false);
    axiosInstance
      .post(`${serverIP}/zones/zone`, {
        description: description,
        amDescription: amDescription,
        parent: parent
      })
      .then(function (response) {
        console.log(response);
        getallZOnes();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const editallZOnes = () => {
    axiosInstance
      .patch(`${serverIP}/zones/zone/${targetObj.id}`, {
        description: description,
        amDescription: amDescription
      })
      .then(function (response) {
        console.log(response);
        getallZOnes();
        setModalEdit(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteallZOnes = () => {
    axiosInstance
      .delete(`${serverIP}/zones/zone/${targetObj.id}`)
      .then(res => {
        console.log(res.data);
        setModalDelete(false);
        getallZOnes();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const activateRecord = regid => {
    axiosInstance
      .patch(`${serverIP}/zones/zone/${regid}`, {
        isActive: true
      })
      .then(function (response) {
        console.log(response);
        getallZOnes();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deactivateRecord = regid => {
    axiosInstance
      .patch(`${serverIP}/zones/zone/${regid}`, {
        isActive: false
      })
      .then(function (response) {
        console.log(response);
        getallZOnes();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      {/* Modal Delete Start  */}
      <Modal show={modalDelete} onHide={() => setModalDelete(false)}>
        <Modal.Header style={{ color: 'red' }} closeButton>
          <Modal.Title color="red"> {t('officer.delete')} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('admin.confirmDelete')} <strong> {targetObj.description} </strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => deleteallZOnes()}>
            {t('officer.delete')}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Delete End  */}
      {/* Modals Start  */}
      <Modal show={modalAdd} onHide={() => setModalAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('officer.add')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">ስም</InputGroup.Text>
            <Form.Control placeholder="ስም" onChange={e => setamDescription(e.target.value)} />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">Name</InputGroup.Text>
            <Form.Control placeholder="Name" onChange={e => setdescription(e.target.value)} />
          </InputGroup>
          <select onChange={e => setParent(e.target.value)} className="form-control" value={parent}>
            {/* <option value="0"> ክልል ይምረጡ </option> */}
            {allRegions.map(region => {
              return (
                <option key={region.id} value={region.id}>
                  {region.amDescription}
                </option>
              );
            })}
          </select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => saveallZOnes()}>
            {t('officer.save')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modalEdit} onHide={() => setModalEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: 'orange' }}> አስተካክል </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">ስም</InputGroup.Text>
            <Form.Control value={amDescription} onChange={e => setamDescription(e.target.value)} />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">Name</InputGroup.Text>
            <Form.Control value={description} onChange={e => setdescription(e.target.value)} />
          </InputGroup>
          <select onChange={e => setParent(e.target.value)} className="form-control" value={parent}>
            {/* <option value="0"> ክልል ይምረጡ </option> */}
            {allRegions.map(region => {
              return (
                <option key={region.id} selected={region.id == targetObj?.parent} value={region.id}>
                  {region.amDescription}
                </option>
              );
            })}
          </select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={() => editallZOnes()}>
            {t('officer.edit')}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modals End  */}

      <Alert className="px-3 flex flex-row justify-between py-1 items-center">
        <div> ዞኖች </div>
        <Button variant="primary" style={{ margin: 0 }} className="btn btn-sm" onClick={() => setModalAdd(true)}>
          {t('officer.Add')}
        </Button>
      </Alert>

      {allZOnes.length > 0 && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th> ስም </th>
              <th> Name </th>
              <th> Status </th>
              <th>{t('auditor.action')}</th>
            </tr>
          </thead>
          <tbody>
            {allZOnes.map(reg => {
              return (
                <tr>
                  <td>{reg.id}</td>
                  <td>{reg.amDescription}</td>
                  <td>{reg.description}</td>

                  <td>
                    {reg.isActive ? (
                      <Button
                        className="btn btn-sm btn-danger"
                        onClick={() => {
                          deactivateRecord(reg.id);
                        }}
                      >
                        Deactivate
                      </Button>
                    ) : (
                      <Button
                        className="btn btn-sm btn-primary"
                        onClick={() => {
                          activateRecord(reg.id);
                        }}
                      >
                        Activate
                      </Button>
                    )}
                  </td>
                  <td>
                    <ButtonGroup size="sm">
                      <Button variant="danger">
                        <FaTrashAlt
                          onClick={() => {
                            setModalDelete(true);
                            setTargetObj(reg);
                            console.log(reg);
                          }}
                        />
                      </Button>
                      <Button variant="warning">
                        <FaPencilAlt
                          onClick={() => {
                            setModalEdit(true);
                            setdescription(reg.description);
                            setamDescription(reg.amDescription);
                            setTargetObj(reg);
                            setParent(reg.parent);
                            console.log(reg);
                          }}
                        />
                      </Button>
                    </ButtonGroup>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export default Zones;
