import React, { useEffect, useState } from 'react';
import { Card, ListGroup, ProgressBar, Button, Modal, InputGroup, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { setTargetLoan } from '../Common/redux/systemLookups';
import { FaTelegram } from 'react-icons/fa';
import { numberToWords } from '../Common/modules/EnNumToWord';
import axiosInstance from '../axiosInstance';

function DisburseApplication() {
  const [t, i18n] = useTranslation('global');
  const dispatch = useDispatch();
  const { serverIP } = useSelector(store => store.allsettings);
  const { targetLoan } = useSelector(store => store.systemLookups);
  const { data } = useSelector(store => store.customer);
  const [amount, setAmount] = useState(false);
  const [appointmentDate, setAppointmentDate] = useState(new Date());

  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [modalToAuthorizer, setModalToAuthorizer] = useState(false);
  const [targetObj, setTargetObj] = useState({});

  const [totalOnDisburse, setTotalOnDisburse] = useState(0);
  const [unplanned, setUnplanned] = useState(0);
  const [error, setError] = useState('');

  useEffect(() => {
    const sum = targetLoan?.disbursement_detail?.reduce((sum, item) => sum + item.amount, 0);
    setTotalOnDisburse(sum);
    setUnplanned(targetLoan.approvedPrincipal - sum);
    console.log(targetLoan.disbursement_detail);
  }, [targetLoan?.disbursement_detail?.length]);

  useEffect(() => {
    getLoan();
  }, []);

  const saveDisbursement = () => {
    if (amount <= 1) {
      setError(`Amount cannot be ZERO or Less`);
    } else {
      axiosInstance
        .post(`${serverIP}/disbursements/disbursements`, {
          loan: targetLoan.id,
          amount: amount,
          plannedby: data.id,
          appointmentDate
        })
        .then(res => {
          console.log(res.data);
          setModalAdd(false);
          getLoan();
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const editDisbursement = () => {
    if (amount <= 1) {
      setError(`Amount cannot be ZERO or Less`);
    } else {
      axiosInstance
        .patch(`${serverIP}/disbursements/disbursements/${targetObj.id}`, {
          loan: targetLoan.id,
          amount: amount,
          plannedby: data.id,
          appointmentDate
        })
        .then(res => {
          console.log(res.data);
          setModalEdit(false);
          getLoan();
        })
        .catch(err => {
          console.log(err);
        });
    }
  };

  const sendToAuthorizer = () => {
    axiosInstance
      .patch(`${serverIP}/loan/loans/${targetLoan.id}`, {
        financeCheking: true
        // financeid, financeapproved, financeat
      })
      .then(res => {
        console.log(res.data);
        setModalToAuthorizer(false);
        getLoan();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const deleteDisbursement = () => {
    axiosInstance
      .delete(`${serverIP}/disbursements/disbursements/${targetObj.id}`)
      .then(res => {
        console.log(res.data);
        setModalDelete(false);
        getLoan();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getLoan = async () => {
    try {
      const res = await axiosInstance.get(`${serverIP}/loan/loans/${targetLoan.id}`);
      dispatch(setTargetLoan({ targetLoan: res.data }));
    } catch (err) {
      console.error('Error fetching loan:', err);
    }
  };

  const handleChange = e => {
    const inputValue = e.target.value;
    if (inputValue === '' || Number(inputValue) <= unplanned) {
      setAmount(inputValue);
      setError('');
    } else {
      setError(`Value cannot exceed ${unplanned}`);
    }
  };

  function getDateDifferenceInDays(dateString) {
    // Parse the given date string into a Date object
    const givenDate = new Date(dateString);

    // Get today's date
    const today = new Date();

    // Calculate the difference in time (milliseconds)
    const differenceInTime = givenDate - today;

    // Convert the time difference from milliseconds to days
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));

    return differenceInDays;
  }

  return (
    <div className="row">
      {/* Modals Start  */}
      {/* Modal ADD Start  */}
      <Modal show={modalAdd} onHide={() => setModalAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Disbursement</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label className="p-1">መጠን</label>
          <Form.Control
            placeholder="መጠን"
            type="number"
            required
            aria-describedby="basic-addon1"
            onChange={handleChange}
          />

          {error && <div style={{ color: 'red' }}>{error}</div>}

          <label className="p-1"> የቀጠሮ ቀን </label>
          <Form.Control
            placeholder="የቀጠሮ ቀን"
            type="date"
            required
            aria-describedby="basic-addon1"
            onChange={e => setAppointmentDate(e.target.value)}
          />

          {/* <input type="date" id="start" name="trip-start" value="2018-07-22" min="2018-01-01" max="2018-12-31" /> */}
        </Modal.Body>
        <Modal.Footer>
          {!error && (
            <Button variant="primary" onClick={() => saveDisbursement()}>
              {t('officer.save')}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
      {/* Modal Add End  */}

      {/* Modal Edit Start  */}
      <Modal show={modalEdit} onHide={() => setModalEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: 'orange' }}>{t('officer.edit')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">መጠን </InputGroup.Text>
            <Form.Control
              placeholder="መጠን"
              aria-describedby="basic-addon1"
              value={amount}
              onChange={e => setAmount(e.target.value)}
            />
          </InputGroup>
          <label className="p-1"> የቀጠሮ ቀን </label>
          <Form.Control
            placeholder="የቀጠሮ ቀን"
            type="date"
            required
            value={appointmentDate}
            aria-describedby="basic-addon1"
            onChange={e => setAppointmentDate(e.target.value)}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={() => editDisbursement()}>
            {t('officer.edit')}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Edit End  */}

      {/* Modal Delete Start  */}
      <Modal show={modalDelete} onHide={() => setModalDelete(false)}>
        <Modal.Header style={{ color: 'red' }} closeButton>
          <Modal.Title color="red"> {t('officer.delete')} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('admin.confirmDelete')} <strong> {targetObj.enName} </strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => deleteDisbursement()}>
            {t('officer.delete')}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Delete End  */}

      {/* Modal Send To Authorizer Start  */}
      <Modal show={modalToAuthorizer} onHide={() => setModalToAuthorizer(false)}>
        <Modal.Header closeButton>
          <Modal.Title color="red"> ወደ ፋይናንስ ላክ </Modal.Title>
        </Modal.Header>
        <Modal.Body>የተቀመጠውን የወጪ ዝርዝር ወደ ሚያጸድቀው አካል ይላኩ፡፡</Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => sendToAuthorizer()} className="flex gap-2 items-center">
            ወደ ፋይናንስ <FaTelegram />
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Send To Authorizer End  */}
      {/* Modals End  */}

      <div className="col-sm-9">
        {totalOnDisburse / targetLoan.approvedPrincipal < 0.5 ? (
          <ProgressBar
            animated
            variant="danger"
            label={((totalOnDisburse / targetLoan.approvedPrincipal) * 100)?.toLocaleString() + ' %'}
            now={(totalOnDisburse / targetLoan.approvedPrincipal) * 100}
          />
        ) : (
          <ProgressBar
            animated
            variant="success"
            label={((totalOnDisburse / targetLoan.approvedPrincipal) * 100)?.toLocaleString() + ' %'}
            now={(totalOnDisburse / targetLoan.approvedPrincipal) * 100}
          />
        )}

        <ListGroup as="ul" className="mt-2">
          <ListGroup.Item className="flex justify-between items-center">
            <div className="text-xl">{'ከወጪ ቀሪ፡ ' + unplanned?.toLocaleString()}</div>
            <div>
              {unplanned < 1 ? (
                <>
                  {targetLoan.financeCheking ? (
                    <div> ወደ ፋይናንስ ተልኳል </div>
                  ) : (
                    <button
                      className="btn btn-primary flex gap-2 items-center"
                      onClick={() => setModalToAuthorizer(true)}
                    >
                      ወደ ፋይናንስ ላክ <FaTelegram />
                    </button>
                  )}
                </>
              ) : (
                <button className="btn btn-primary" onClick={() => setModalAdd(true)}>
                  ጨምር
                </button>
              )}
            </div>
          </ListGroup.Item>
        </ListGroup>

        {/* <div>{JSON.stringify(targetLoan?.disbursement_detail)}</div> */}
        {targetLoan?.disbursement_detail?.length > 0 ? (
          <div className="row">
            {targetLoan?.disbursement_detail.map(dis => {
              return (
                <div className="col-sm-4">
                  <Card border="dark" className="mt-2">
                    <Card.Header>{dis.amount?.toLocaleString()}</Card.Header>

                    <ListGroup as="ul" style={{ padding: 0 }}>
                      <ListGroup.Item as="li">
                        {((dis.amount / targetLoan.approvedPrincipal) * 100).toLocaleString() + ' %'}
                      </ListGroup.Item>
                      <ListGroup.Item>{numberToWords(dis.amount)}</ListGroup.Item>
                      <ListGroup.Item className="flex justify-between">
                        <div className="text-green-700 font-bold">{dis.plannedDate}</div>
                        <div className="text-red-700 font-bold">{dis.appointmentDate}</div>
                      </ListGroup.Item>
                      <ListGroup.Item>{getDateDifferenceInDays(dis.appointmentDate) + ' ቀናቶች ይቀራሉ'}</ListGroup.Item>
                      <ListGroup.Item>{'ጠያቂ: ' + dis.plannedby?.first_name}</ListGroup.Item>
                    </ListGroup>

                    {!targetLoan.financeCheking && (
                      <Card.Footer className="flex justify-between">
                        <Button
                          variant="warning"
                          className="btn btn-sm"
                          onClick={() => {
                            setModalEdit(true);
                            setTargetObj(dis);
                          }}
                        >
                          አስተካክል
                        </Button>
                        <Button
                          variant="danger"
                          className="btn btn-sm"
                          onClick={() => {
                            setTargetObj(dis);
                            setModalDelete(true);
                          }}
                        >
                          ሰርዝ
                        </Button>
                      </Card.Footer>
                    )}
                  </Card>
                </div>
              );
            })}
          </div>
        ) : (
          <></>
        )}
      </div>
      <div className="col-sm-3">
        <ListGroup as="ul">
          <ListGroup.Item active as="li">
            Customer Detail
          </ListGroup.Item>
          <ListGroup.Item>
            <div> {targetLoan?.customer?.amDisplayName} </div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div> {targetLoan?.customer?.entityExternalId} </div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div> {targetLoan?.customer?.mobileNo} </div>
          </ListGroup.Item>
        </ListGroup>

        <ListGroup as="ul" className="py-2">
          <ListGroup.Item active as="li">
            Loan Detail
          </ListGroup.Item>

          <ListGroup.Item as="li">
            <div>Saving : {targetLoan.r_totalSaving?.toLocaleString()} </div>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            <div>Share : {targetLoan.r_totalShares?.toLocaleString()} </div>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            <div>Monthy Pay : {targetLoan.r_totalDueForPeriod?.toLocaleString()}</div>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            <div>Principal : {targetLoan.approvedPrincipal?.toLocaleString()}</div>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            <div>Interest : {targetLoan.r_totalInterestPayment?.toLocaleString()}</div>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            <div>Service Charge : {targetLoan.flatServiceCharge?.toLocaleString()}</div>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            <div>Life Insurance : {targetLoan.flatLifeInsurance?.toLocaleString()}</div>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            <div>Tembir : {targetLoan.tembr?.toLocaleString()} </div>
          </ListGroup.Item>
          <ListGroup.Item as="li">
            <div>Disburse :{targetLoan.approvedPrincipalDisbursed?.toLocaleString()}</div>
          </ListGroup.Item>
        </ListGroup>
        {/* {JSON.stringify(targetLoan)} */}

        <ListGroup as="ul">
          <ListGroup.Item active as="li">
            Actors
          </ListGroup.Item>
          <ListGroup.Item>
            <div>ብድሩ የጠየቀው :{targetLoan?.submittedBy?.first_name + ' ' + targetLoan?.submittedBy?.last_name}</div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div>ብድሩ የገመገመው :{targetLoan?.teamleaderid?.first_name + ' ' + targetLoan?.teamleaderid?.last_name}</div>
          </ListGroup.Item>
          <ListGroup.Item>
            <div>ብድሩ ያጸደቀው :{targetLoan?.auditorid?.first_name + ' ' + targetLoan?.auditorid?.last_name}</div>
          </ListGroup.Item>
        </ListGroup>
      </div>
    </div>
  );
}

export default DisburseApplication;
