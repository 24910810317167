import axiosInstance from '../../axiosInstance';
import React, { useEffect, useState } from 'react';
import { Document, Font, Page, Text, View } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import getEthiopicDate from '../../Common/modules/GregorianToEthiopian';

import AmharicFont from '../font/ebrima.ttf';
import AgreementStyles from '../Styles/ReportStyle';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import TextBold from '../Reusables/TextBold';

import Tr from '../Reusables/Tr';
import Trc from '../Reusables/Trc';
import { numberToWords } from '../../Common/modules/TgNumToWord';
import Header from '../Reusables/Header';
import AdditionalSurety from '../Articles/AdditionalSurety';

Font.register({
  family: 'AmharicFont',
  src: AmharicFont
});

Font.register({
  family: 'OpenSans',
  fonts: [
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf'
    },
    {
      src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf',
      fontWeight: 600
    }
  ]
});

function TgAgreementDoc() {
  const { serverIP } = useSelector(store => store.allsettings);
  const { customerId, loanId } = useParams();
  const [customer, setCustomer] = useState([]);
  const [loan, setLoan] = useState([]);

  const curdate = new Date();

  useEffect(() => {
    getCustomer();
    getLoan();
  }, []);

  const getCustomer = () => {
    axiosInstance
      .get(`${serverIP}/customer/customers/${customerId}`)
      .then(res => {
        console.log(res.data);
        setCustomer(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getLoan = () => {
    axiosInstance
      .get(`${serverIP}/loan/loans/${loanId}`)
      .then(res => {
        console.log(res.data);
        setLoan(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <PDFViewer style={{ width: '100%', height: 700 }}>
      <Document title="ናይልቃሕ ውዕሊ ስምምዕነት">
        <Page style={AgreementStyles.body} wrap>
          <Header style={AgreementStyles.subheadingcenter} type="ናይልቃሕ ውዕሊ ስምምዕነት" />
          {/* <Text style={AgreementStyles.author}></Text> */}
          <Trc borders="t0r0b0l0" thekey="የውል ስጪ እና ውል ተቀባይ ኣድራሻ" />
          <Text style={AgreementStyles.text}>
            ከዚህ ቀጥሎ «ተበዳሪ» እየተባሉ የሚጠቀሱት፦
            <TextBold text={customer.amDisplayName} />
          </Text>
          <Text style={AgreementStyles.text}>
            አድራሻ፦
            <TextBold text={customer.regionId + ' - ' + customer.zoneId + ', ' + customer.mobileNo} />
          </Text>
          <Text style={AgreementStyles.text}>ከዚህ ቀጥሎ «ተቋሙ» እየተባለ የሚጠራው፦ አሚጎስ የቁጠባና ብድር ህብረት ስራ ማህበር(ህስማ)</Text>
          <Text style={AgreementStyles.text}>አድራሻ፦ ፖስታሳ.ቁ፦ 20732/1000፤አዲስ አበባ ስልክ ቁጥር፦</Text>
          <Text style={AgreementStyles.text}>
            ይህንን በተወሰነ ጊዜ የሚከፈል ብድር (term loan) ውሉ በቀን{' '}
            {getEthiopicDate(curdate.getDay(), curdate.getMonth() + 1, curdate.getFullYear())}
            ቀጥሎ በተዘረዘሩት የውል ኣንቀጾች ተዋውለናል፡፡
          </Text>
          <Trc borders="t0r0b0l0" thekey="አንቀጽ-1፦ ስለብድር እና ወለድ መጠን በተመለከተ" />
          <Text style={AgreementStyles.text}>
            1.1. በዚህ ውል ውስጥ በተገለጸው ስምምነት መሰረት ከላይ ተበዳሪ ተብለው ስማቸውና ኣድራሻቸው የተገለጹት ኣባል ብድራቸው ከነኣጠቃላይ ወለዱ እንድሚከተለው ቀርበዋል።
          </Text>
          <Text style={AgreementStyles.text}>
            1.1.1. ዋናው (principal) የብድር መጠን
            <TextBold
              text={' ' + loan.approvedPrincipal?.toLocaleString() + ' (' + numberToWords(loan.approvedPrincipal) + ')'}
            />
          </Text>
          <Text style={AgreementStyles.text}>
            1.1.2. የወልድ መጠን
            <TextBold
              text={
                ' ' +
                loan.totalInterestPayment?.toLocaleString() +
                ' (' +
                numberToWords(loan.totalInterestPayment) +
                ')'
              }
            />
          </Text>
          <Text style={AgreementStyles.text}>
            1.1.3. ጠቅላላ ብድርና የወልድ መጠን:
            <TextBold
              text={' ' + loan.approvedPrincipal?.toLocaleString() + ' (' + numberToWords(loan.approvedPrincipal) + ')'}
            />
            ተቋሙ ለተበዳሪ ከላይ የተገለፁትን አበድሯል፡፡ ተበዳሪውም ገንዘቡን መቀበላቸውን አረጋግጠዋል፡፡
          </Text>

          <Text style={AgreementStyles.text}></Text>
          <Trc borders="t0r0b0l0" thekey="አንቀጽ-2፦ የክፍያ ሁኔታ በተመለከተ" />
          <Text style={AgreementStyles.text}>
            2.1. ተበዳሪው ብድሩ ከወስዱበት ጊዜ ጀምሮ ብድሩ ተክፍሎ እስከሚያልቅበት ማለትም{' '}
            <Text style={AgreementStyles.boldText}>
              <TextBold
                text={
                  ' ' + loan.totalDueForPeriod?.toLocaleString() + ' (' + numberToWords(loan.totalDueForPeriod) + ')'
                }
              />
            </Text>{' '}
            የሚከፈል ድምር ወርሀዊ ክፍያ ከነወለድ ጋር ብር
            <Text style={AgreementStyles.boldText}>
              <TextBold
                text={
                  ' ' +
                  loan.r_totalInterestPayment?.toLocaleString() +
                  ' (' +
                  numberToWords(loan.r_totalInterestPayment) +
                  ')'
                }
              />
            </Text>
            እንድሚከፍሉ ተስማምትዋል።
          </Text>
          <Text style={AgreementStyles.text}>
            2.2. እንዲሁም በዚህ ውል መሰረት በየወቅቱ የሚከፈለው ማንኛውም ክፍያ መጀመሪያ ተቋሙ ከብድርሩ ጋር በተገናኘ ያወጣቸውን ልዩ ልዩ ወጪዎች ለመክፈል ፣ቀጥሎ ለወለድ ክፍያ
            ፣በመጨረሻም የብድሩን ዋና ገንዘብ ለመክፈል ይውላል፡፡
          </Text>
          <Trc borders="t0r0b0l0" thekey="አንቀጽ-3፦ ስለ ብድር ወለድ እና ቅጣት እዲሁም ውል ስለማቋረጥ በተመለከተ" />
          <Text style={AgreementStyles.text}>
            3.1. በተሰጠው ብድር ፥ተቛሙ በመቶ
            <TextBold text={loan.annualInterestRate + ' %'} /> በዓመት የብድር ወለድ እንዲሁም
            <TextBold text={loan.prcntLifeInsurance + loan.prcntServiceCharge + ' %'} />
            የኣገልግሎትና የኣኢንሹራንስ ክፍያ ከዋናው (principal) የብድር መጠን የሚያስብ ይሆናል። የወለድ መጠን ተቋሙ በየጊዜው እያሻሻለ በሚያወጣው የወለድ ተመን
            (interest rate) የሚተካ ይሆናል ፡፡
          </Text>
          <Text style={AgreementStyles.text}>
            3.2. ተበዳሪ ወርሀዊ እዳውን ባይከፍል ፣ መክፈል ካልቻለበት ቀን ጀምሮ በወርሃዊው የወለድና የዋናው ብድር ክፍያ ላይ በወር ሃያ በመቶ(20%) ቅጣት ይታሰባል። ነገርግን
            ተበዳሪው ለሁለት ተከታታይ የክፍያ ጊዜዎችን ሳይከፍል ከቀረ ክፍያ እንደተቋረጠና እንደ ተበላሸ ተቆጥሮ በህግ የሚጠይቅ ይሆናል፡፡
          </Text>

          <Text style={AgreementStyles.text}>
            3.3. ተበዳሪ በዚህ ውል የገቡት ግዴታ ካልተወጡ ተቋሙ ለተበዳሪው የ30 ቀን የጽሁፍ ማስጠንቀቂያ ሰጥቶ በማንኛውም ጊዜ ውሉን ለማቋረጥ ይችላል ፡፡
          </Text>
          <Text style={AgreementStyles.text}>3.4. ውሉ በሚቋረጠበት ጊዜ ተበዳሪ ያላቸውን ቀሪ እዳ በአንዴ ይከፍላሉ፡፡</Text>
          <Text style={AgreementStyles.text}>3.5. ተበዳሪ በዚህ ውል የገቡትን ግዴታ እንዲፈጽሙ ተቋሙ የችሮታ ጊዜ እንደማይሰጥ ተስማምተዋል፡፡</Text>
          <Trc borders="t0r0b0l0" thekey="አንቀጽ-4፦ ወጪዎች በተመለከተ" />
          <Text style={AgreementStyles.text}>
            4.1. የቴምብር ቀረጥ፣የውሉ ማስመዝገቢያ ክፍያ (ካለ)፣ ይህንን ውል ለማስፈጸም የሚደረገውን ማንኛውንም ወጪ ሁሉ ተበዳሪ ይከፍላል። እንዲሁም በተበዳሪ ሂሳብ ወስጥ በቂ
            ገነዘብ ባለመኖሩ ወይም በሌላም ምክንያት እንዲህ አይነቱ ወጪ በተቋሙ ከተከፈለ ወጪው ብድር ሂሳብ ላይ ተደምሮ ወለድ ይታሰብበታል ፡፡
          </Text>
          <Trc borders="t0r0b0l0" thekey="አንቀጽ-5፦ ወጪዎች በተመለከተ" />
          <Text style={AgreementStyles.text}>
            5.1. ተበዳሪ በዚህ ውል ውስጥ የተገለጸው ግዴታቸውን ያልፈጸሙ እንደሆነ ፣ ተበዳሪው በማንኛውም ተቋም ውስጥ ወይም በኣሚጎስ የገ/ቁ/ብ ህ/ስ/ማ ወስጥ ያስቀመጡትን
            ቁጠባና ሌሎች ገቢዎችን ወጪ በማድረግ ለዕዳ ማቻቻያ ሊያውለው ይችላል ፡፡
          </Text>
          <Text style={AgreementStyles.text}>
            5.2. በንዑስ አንቀጽ 5.1 እንደተጠቀሰ ፥ በተቋሙ በኩል ለተበዳሪ የሚከፈሉ ቼኮችንና የሚተላለፉ ገንዘቦችን ተቋሙ ለተበዳሪ እዳ ማቻቻያ ለማዋል ይችላል፡፡
          </Text>
          <Trc borders="t0r0b0l0" thekey="አንቀጽ-6፦ የብድር ዋስትና በተመለከተ" />
          <Text style={AgreementStyles.text}>
            6.1. ተበዳሪ ለብድር ክፍያ ዋስትና ይሆን ዘንድ ከዚህ በታች ባለው ሠንጠረዥ ውስጥ የተጠቀሰውን የተበዳሪና የባለቤት አክሲዬን ፣ ቁጠባና ሌላ ተቀማጭ ገንዘብ ለብድሩ
            በማስያዣነት ስጥቷል። ማሳሰቢያ ፡ መያዣው አግባብ ከሌለው በሚመለከተው ሠንጠረዥ ውስጥ ፤አግባግ የለውም ተብሎ ይጠቀሳል።
          </Text>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Trc thekey=" የአመልካች ስም " width="24%" borders="t1r1b1l1" />
            <Trc thekey="መደበኛ ቁጠባ" width="19%" borders="t1r1b1l0" />
            <Trc thekey=" የአክስዮን መጠን " width="19%" borders="t1r1b1l0" />
            <Trc thekey=" የፍቃደኝነት ቁጠባ " width="19%" borders="t1r1b1l0" />
            <Trc thekey=" ድምር መጠን " width="19%" borders="t1r1b1l0" />
          </View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Tr thekey={<Text>{customer.amDisplayName}</Text>} width="24%" borders="t0r1b1l1" />
            <Tr thekey={<Text>{loan.totalSaving?.toLocaleString()}</Text>} width="19%" borders="t0r1b1l0" />
            <Tr thekey={<Text>{loan.totalShares?.toLocaleString()}</Text>} width="19%" borders="t0r1b1l0" />
            <Tr thekey={<Text>{loan.amount_voluntary?.toLocaleString()}</Text>} width="19%" borders="t0r1b1l0" />
            <Tr
              thekey={<Text>{(loan.totalSaving + loan.totalShares)?.toLocaleString()}</Text>}
              width="19%"
              borders="t0r1b1l0"
            />
          </View>

          <View style={{ width: '100%' }}>
            <Trc thekey="6.2. የዋስትና ዝርዝር መግለጫ" borders="t0r0b0l0" />
            {/* Fifth half start ለብድር ዋስትና የቀረቡ ዋስትናዎች */}

            <AdditionalSurety loan={loan} customer={customer} />
          </View>
          <View style={{ width: '100%' }}>
            <Trc thekey="6.3. የተባዳሪ ባለቤት ፍቃድ በተመለከተ" borders="t0r0b0l0" />
          </View>

          <View style={{ paddingTop: 3 }}>
            <Trc
              thekey="በዚህ ውል የተገለጸውን የተበዳሪ ግዴታ ሁሉ ወደውና ተስማምተው መቀበላቸውን በፊርማቸው አረጋግጠዋል።ይህ ውል ከላይ በተጠቀሰው ቀን ና ዓ.ም  ስማቸው ከታች  የተተቀሱት ምስክሮች ባሉበት ተፈራርመው የውሉን አንድ ቅጂ ወስደዋል፡፡"
              borders="t0r0b0l0"
            />
          </View>

          <View style={{ display: 'flex', flexDirection: 'row', paddingTop: 10 }}>
            <Trc thekey="ሀጎስ ገብረ" borders="t0r0b0l0" width="23%" />
            <Trc thekey=" " borders="t0r0b0l0" width="2%" />
            <Trc thekey="" borders="t0r0b0l0" width="23%" />
            <Trc thekey=" " borders="t0r0b0l0" width="2%" />
            <Trc thekey={customer.amDisplayName} borders="t0r0b0l0" width="23%" />
            <Trc thekey=" " borders="t0r0b0l0" width="2%" />
            <Trc thekey="" borders="t0r0b0l0" width="23%" />
          </View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Trc thekey="የተቋሙ ውል ሰጪና አዘጋጅ ስም" borders="t1r0b0l0" width="23%" />
            <Trc thekey=" " borders="t0r0b0l0" width="2%" />
            <Trc thekey="ፊርማ" borders="t1r0b0l0" width="23%" />
            <Trc thekey=" " borders="t0r0b0l0" width="2%" />
            <Trc thekey="የተበዳሪ ስም" borders="t1r0b0l0" width="23%" />
            <Trc thekey=" " borders="t0r0b0l0" width="2%" />
            <Trc thekey="ፊርማ" borders="t1r0b0l0" width="23%" />
          </View>

          <View style={{ paddingTop: 10 }}>
            <Trc thekey="እማኞች" borders="t0r0b0l0" />
          </View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            {loan.loanwitness?.length > 0 &&
              loan.loanwitness.map(witn => {
                return (
                  <>
                    <Trc thekey={witn.amFullName} width="20%" borders="t0r0b0l0" />
                    <Trc thekey=" " width="13%" borders="t0r1b0l0" />
                  </>
                );
              })}
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}

export default TgAgreementDoc;
