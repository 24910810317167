import React, { useEffect, useState } from 'react';
import { Button, Card, Form, InputGroup, ListGroup, Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import WithdrawForm from '../Role_Planner/Reusables/WithdrawForm';
import axiosInstance from '../axiosInstance';

function FinanceWithdrawChecking() {
  const { withdrawId } = useParams();
  const { data } = useSelector(store => store.customer);
  const { serverIP } = useSelector(store => store.allsettings);
  const [theWithdraw, settheWithdraw] = useState({});

  const [modalEdit, setModalEdit] = useState(false);
  const [checkId, setCheckId] = useState('');
  const [checksignedby, setChecksignedby] = useState('');

  useEffect(() => {
    getWithdrawing(withdrawId);
  }, []);

  const getWithdrawing = () => {
    console.log(withdrawId);
    axiosInstance
      .get(`${serverIP}/withdrawrows/withdrawrow/${withdrawId}`)
      .then(res => {
        settheWithdraw(res.data);
        console.log(res.data);
      })
      .catch(error => {
        console.log(error);
      });
  };

  function getDateDifferenceInDays(dateString) {
    // Parse the given date string into a Date object
    const givenDate = new Date(dateString);
    // Get today's date
    const today = new Date();
    // Calculate the difference in time (milliseconds)
    const differenceInTime = givenDate - today;
    // Convert the time difference from milliseconds to days
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
    return differenceInDays;
  }

  const updateCheckDetail = () => {
    // console.log("updateCheckDetail");
    axiosInstance
      .patch(`${serverIP}/withdrawrows/withdrawrow/${withdrawId}`, {
        financePerson: data.id,
        checkId,
        checksignedby,
        checkissuedby: data.id
      })
      .then(res => {
        getWithdrawing();
        toast.success('success');
        console.log(res.data);
        setModalEdit(false);
      })
      .catch(error => {
        toast.error('error');

        console.log(error);
        setModalEdit(false);
      });
  };

  return (
    <div className="container">
      <ToastContainer />

      <Modal show={modalEdit} onHide={() => setModalEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title> የቼክ መረጃ አስተካክል </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">የቼክ ቁጥር </InputGroup.Text>
            <Form.Control
              placeholder="የቼክ ቁጥር"
              aria-describedby="basic-addon1"
              value={checkId}
              onChange={e => setCheckId(e.target.value)}
            />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">ቼክ ፈራሚ ሰዎች </InputGroup.Text>
            <Form.Control
              placeholder="ቼክ ፈራሚ ሰዎች"
              aria-describedby="basic-addon1"
              value={checksignedby}
              onChange={e => setChecksignedby(e.target.value)}
            />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={() => updateCheckDetail()}>
            አስተካክል
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="row">
        {/* {JSON.stringify(theWithdraw)} */}
        <div className="col-sm-8">
          <WithdrawForm theWithdraw={theWithdraw} />
        </div>
        <div className="col-sm-4">
          <Card className="mt-4">
            <Card.Header>Withdrawal Request Detail</Card.Header>
            <Card.Body>
              <ListGroup as="ul" style={{ padding: 0 }}>
                <ListGroup.Item>
                  የተፈቀደው የብር መጠን፡
                  <b>{theWithdraw.withdraw_amount?.toLocaleString() + ' ብር'}</b>
                </ListGroup.Item>
                <ListGroup.Item>
                  የቀረው ቀን፡
                  {/* <b>
                    {theWithdraw.date_difference
                      ? theWithdraw.date_difference + " ቀናቶች"
                      : ""}
                  </b> */}
                  <b>{getDateDifferenceInDays(theWithdraw.appointmentDate) + '  ቀን ቀረው'}</b>
                </ListGroup.Item>
                <ListGroup.Item>
                  የስራክፍሉ ኦፊሰር :
                  <b>
                    {theWithdraw?.plannedby
                      ? theWithdraw?.plannedby?.first_name + ' ' + theWithdraw?.plannedby?.last_name
                      : ''}
                  </b>
                </ListGroup.Item>
                <ListGroup.Item>
                  የአባላት አስተዳደር ሀላፊ :
                  <b>
                    {theWithdraw?.evaluatedby !== null &&
                      theWithdraw?.evaluatedby?.first_name + ' ' + theWithdraw?.evaluatedby?.last_name}
                  </b>
                </ListGroup.Item>
                <ListGroup.Item>
                  የኦዲት ክፍል ሀላፊ :
                  <b>
                    {theWithdraw?.auditorid !== null &&
                      theWithdraw?.auditorid?.first_name + ' ' + theWithdraw?.auditorid?.last_name}
                  </b>
                </ListGroup.Item>
                <ListGroup.Item>
                  የፋይናንስ ክፍል ሀላፊ :
                  <b>
                    {theWithdraw?.financePerson !== null &&
                      theWithdraw?.financePerson?.first_name + ' ' + theWithdraw?.financePerson?.last_name}
                  </b>
                </ListGroup.Item>

                <ListGroup.Item>ወጪ የተፈቀደበት ቀን፡ እኤአ / ዓ.ም</ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>

          <Card className="mt-2">
            <Card.Header>የቼክ መረጃ</Card.Header>
            <Card.Body>
              <ListGroup>
                <ListGroup.Item>የቼክ ቁጥር ፡ {theWithdraw.checkId} </ListGroup.Item>
                <ListGroup.Item>ቼክ የፈረሙ ፡ {theWithdraw.checksignedby} </ListGroup.Item>
                <ListGroup.Item>ቼክ የተቆረጠበት ቀን ፡ {theWithdraw.checkId} </ListGroup.Item>
                <ListGroup.Item>ቼክ የተሰጠበት ቀን ፡ {theWithdraw.checkId} </ListGroup.Item>
              </ListGroup>
            </Card.Body>
            <Card.Footer className="flex justify-between">
              <Button
                variant="warning"
                onClick={() => {
                  setModalEdit(true);
                  setCheckId(theWithdraw.checkId);
                  setChecksignedby(theWithdraw.checksignedby);
                }}
              >
                የቼክ መረጃ አስተካክል{' '}
              </Button>
              {theWithdraw?.financePerson !== null && (
                <Link
                  to={`/rptwithdraw/${withdrawId}`}
                  className="font-medium text-blue-600 dark:text-blue-300 underline hover:underline"
                >
                  ሪፖርት
                </Link>
              )}
            </Card.Footer>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default FinanceWithdrawChecking;
