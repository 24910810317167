import React from "react";
import { Font, View } from "@react-pdf/renderer";
import ebrima from "../font/ebrima.ttf";
import ebrimabold from "../font/ebrimabd.ttf";
import Tr from "../Reusables/Tr";
import ReportStyle from "../Styles/ReportStyle";

Font.register({
  family: "ebrima",
  src: ebrima,
});

Font.register({
  family: "ebrimabold",
  src: ebrimabold,
});

function AdditionalSurety({ loan, customer }) {
  return (
    <View>
      {/* fourth half start  */}
      {/* Fifth half start ለብድር ዋስትና የቀረቡ ዋስትናዎች */}

      <View style={{ display: "flex", flexDirection: "row" }}>
        <Tr
          style={ReportStyle.h5b}
          thekey="የቀረቡ ዋስትናዎች"
          width="15%"
          borders="t1r1b1l1"
        />
        <Tr
          style={ReportStyle.h5b}
          thekey=" የዋስትና አቅራቢ ስም "
          width="30%"
          borders="t1r1b1l0"
        />
        <Tr
          style={ReportStyle.h5b}
          thekey=" የብር መጠን "
          width="12%"
          borders="t1r1b1l0"
        />
        <Tr
          style={ReportStyle.h5b}
          thekey=" ክ/ከተማ "
          width="17%"
          borders="t1r1b1l0"
        />
        <Tr
          style={ReportStyle.h5b}
          thekey=" ወረዳ "
          width="16%"
          borders="t1r1b1l0"
        />
        <Tr
          style={ReportStyle.h5b}
          thekey=" የቤት ቁጥር "
          width="10%"
          borders="t1r1b1l0"
        />
      </View>

      {/* home */}
      {loan.collateralhome?.length > 0 &&
        loan.collateralhome.map((home) => {
          return (
            <View style={{ display: "flex", flexDirection: "row" }}>
              {home.homegp == null ? (
                <>
                  <Tr
                    thekey={home.hometype.amName}
                    width="15%"
                    borders="t0r1b1l1"
                  />
                  <Tr
                    thekey={customer?.amDisplayName}
                    width="30%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    thekey={(
                      home.homearea * home.hometype?.pricepercaremeter
                    ).toFixed(2)}
                    width="12%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    thekey={customer?.zoneId}
                    width="17%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    thekey={customer?.woredaId}
                    width="16%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    thekey={customer.houseNum}
                    width="10%"
                    borders="t0r1b1l0"
                  />
                </>
              ) : (
                <>
                  <Tr
                    thekey={home.hometype.amName}
                    width="15%"
                    borders="t0r1b1l1"
                  />
                  <Tr
                    thekey={home.homegp?.fullname}
                    width="30%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    thekey={(
                      home.homearea * home.hometype?.pricepercaremeter
                    ).toFixed(2)}
                    width="12%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    thekey={home.homegp?.zoneId?.amDescription}
                    width="17%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    thekey={home.homegp?.woredaId?.amDescription}
                    width="16%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    thekey={home.homegp.housenum}
                    width="10%"
                    borders="t0r1b1l0"
                  />
                </>
              )}
            </View>
          );
        })}

      {/* car */}
      {loan.collateralcar?.length > 0 &&
        loan.collateralcar.map((car) => {
          return (
            <View style={{ display: "flex", flexDirection: "row" }}>
              {car.cargp == null ? (
                <>
                  <Tr thekey=" መኪና " width="15%" borders="t0r1b1l1" />
                  <Tr
                    thekey={customer.amDisplayName}
                    width="30%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    thekey={(
                      (car.insuranceValue +
                        car?.marketvalue[0]?.marketValue +
                        car?.garageReport[0]?.garageValue) /
                      3
                    ).toFixed(2)}
                    width="12%"
                    borders="t0r1b1l0"
                  />
                  <Tr thekey={customer.zoneId} width="17%" borders="t0r1b1l0" />
                  <Tr
                    thekey={customer?.woredaId}
                    width="16%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    thekey={customer.houseNum}
                    width="10%"
                    borders="t0r1b1l0"
                  />
                </>
              ) : (
                <>
                  <Tr thekey="መኪና" width="15%" borders="t0r1b1l1" />
                  <Tr
                    thekey={car.cargp?.fullname}
                    width="30%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    thekey={(
                      (car.insuranceValue +
                        car?.marketvalue[0]?.marketValue +
                        car?.garageReport[0]?.garageValue) /
                      3
                    ).toFixed(2)}
                    width="12%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    thekey={car.cargp?.zoneId?.amDescription}
                    width="17%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    thekey={car.cargp?.woredaId?.amDescription}
                    width="16%"
                    borders="t0r1b1l0"
                  />
                  <Tr
                    thekey={car.cargp?.housenum}
                    width="10%"
                    borders="t0r1b1l0"
                  />
                </>
              )}
            </View>
          );
        })}

      {/* Employee Salary */}
      {loan.collateralemployee?.length > 0 &&
        loan.collateralemployee.map((employee) => {
          return (
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Tr thekey=" ደመወዝ " width="15%" borders="t0r1b1l1" />
              <Tr thekey={employee.empAmName} width="30%" borders="t0r1b1l0" />
              <Tr
                thekey={employee.grossSalary * 3.6}
                width="12%"
                borders="t0r1b1l0"
              />
              <Tr thekey={employee.zoneId?.amDescription} width="17%" borders="t0r1b1l0" />
              <Tr thekey={employee.zoneId?.amDescription} width="16%" borders="t0r1b1l0" />
              <Tr thekey={employee.zoneId?.amDescription} width="10%" borders="t0r1b1l0" />
            
            </View>
          );
        })}

      {/* collateralstock  */}
      {loan.collateralstock?.length > 0 &&
        loan.collateralstock.map((stock) => {
          return (
            <View style={{ display: "flex", flexDirection: "row" }}>
              <Tr thekey=" ሼር  " width="15%" borders="t0r1b1l1" />
              <Tr
                thekey={" " + customer.amDisplayName || stock.stockgp?.fullname}
                width="30%"
                borders="t0r1b1l0"
              />
              <Tr
                thekey={stock.stockqty * stock.priceperstock}
                width="12%"
                borders="t0r1b1l0"
              />
              <Tr
                thekey={
                  loan.customer.zoneId ||
                  (stock.stockgp?.subcity == null
                    ? " "
                    : stock.stockgp?.subcity)
                }
                width="17%"
                borders="t0r1b1l0"
              />
              <Tr
                thekey={
                  loan.customer.woredaId ||
                  (stock.stockgp?.woreda == null ? " " : stock.stockgp?.woreda)
                }
                width="16%"
                borders="t0r1b1l0"
              />
              <Tr
                thekey={
                  loan.customer.idNum ||
                  (stock.stockgp?.idnum == null ? " " : stock.stockgp?.idnum)
                }
                width="10%"
                borders="t0r1b1l0"
              />
            </View>
          );
        })}

      {/* fourth half end  */}
      {/* Fifth half start ለብድር ዋስትና የቀረቡ ዋስትናዎች */}
    </View>
  );
}

export default AdditionalSurety;
