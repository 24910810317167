import React from "react";

function TextInputTw({ value, onChange, placeholder }) {
  return (
    <label className="w-full py-1">
      <div className="label">
        <span className="label-text">{placeholder} </span>
      </div>
      <input
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className="input input-bordered w-full form-control"
      />
    </label>
  );
}

export default TextInputTw;
