import React, { useEffect, useState } from 'react';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import CarCollateralSchema from '../Schema/CarCollateralSchema';

import { FaCarAlt, FaFileAlt, FaPencilAlt, FaExchangeAlt, FaTrashAlt } from 'react-icons/fa';
import { Alert, Badge } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { setTargetLoan } from '../Common/redux/systemLookups';
import TextInputTw from '../ReusableFormiks/TextInputTw';
import axiosInstance from '../axiosInstance';

function CollateralCarComp({ successnotify, errornotify }) {
  const { data } = useSelector(store => store.customer);
  const { serverIP } = useSelector(store => store.allsettings);
  const { targetLoan } = useSelector(store => store.systemLookups);

  const dispatch = useDispatch();
  const { loanId } = useParams();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [gpModal, setGpModal] = useState(false);
  const [gpid, setGpid] = useState(null);

  const [carModels, setCarModels] = useState([]);
  const [carManufactureYear, setCarManufactureYear] = useState([]);

  const [regionss, setregionss] = useState([]);
  const [zoness, setzoness] = useState([]);
  const [woredass, setworedass] = useState([]);

  const [targetCar, setTargetCar] = useState([]);

  const [chassisNumber, setchassisNumber] = useState('');
  const [engineNumber, setengineNumber] = useState('');
  const [librebookid, setlibrebookid] = useState('');
  const [carPlate, setcarPlate] = useState('');
  const [carCC, setcarCC] = useState('');
  const [insuranceValue, setinsuranceValue] = useState('');

  const [manufacturedYear, setmanufacturedYear] = useState('');
  const [loan, setloan] = useState('');
  const [checkListId, setcheckListId] = useState('');
  const [model, setmodel] = useState('');

  const [regionId, setregionId] = useState('');
  const [zoneId, setzoneId] = useState('');
  const [woredaId, setworedaId] = useState('');

  const [serialnum, setserialnum] = useState('');
  const [sanctionAddress, setsanctionAddress] = useState('');

  const [sunctionedAt, setsunctionedAt] = useState('');
  const [manufacturedCountry, setmanufacturedCountry] = useState(0);
  const [ddlcoutry, setsddlcoutry] = useState([]);

  useEffect(() => {
    getManufacturedCountry();
    getregions();
    getzones();
    getworedas();
    getCarModels();
    getCarManufactureYears();
  }, []);

  const getManufacturedCountry = () => {
    axiosInstance
      .get(`${serverIP}/ddlcountry/ddlcountries`)
      .then(res => {
        setsddlcoutry(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getregions = () => {
    axiosInstance
      .get(`${serverIP}/regions/regions/activeregions`)
      .then(res => {
        setregionss(res.data);
        console.log(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getzones = () => {
    axiosInstance
      .get(`${serverIP}/zones/zone`)
      .then(res => {
        setzoness(res.data);
        console.log(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getworedas = () => {
    axiosInstance
      .get(`${serverIP}/woredas/woreda`)
      .then(res => {
        setworedass(res.data);
        console.log(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getAllZonesByRegionId = regID => {
    axiosInstance
      .get(`${serverIP}/zones/byparentid/${regID}`)
      .then(res => {
        setzoness(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getAllWoredasByZoneId = zonID => {
    axiosInstance
      .get(`${serverIP}/woredas/byparentid/${zonID}`)
      .then(res => {
        console.log(res.data);
        setworedass(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getCarModels = () => {
    axiosInstance
      .get(`${serverIP}/car_model/cars`)
      .then(res => {
        console.log(res.data);
        setCarModels(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getCarManufactureYears = () => {
    axiosInstance
      .get(`${serverIP}/car_manufacturer/carmanufacture`)
      .then(res => {
        console.log(res.data);
        setCarManufactureYear(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const saveCarCollateral = (values, actions) => {
    console.log(values);
    axiosInstance
      .post(`${serverIP}/collateral_car/collateralcar`, {
        manufacturedYear: values.manufacturedYear,
        chassisNumber: values.chassisNumber,
        engineNumber: values.engineNumber,
        librebookid: values.librebookid,
        carPlate: values.carPlate,
        carCC: values.carCC,
        loan: loanId,
        manufacturedCountry: values.checkListId,
        model: values.model,
        insuranceValue: values.insuranceValue,
        regionId: values.regionId,
        zoneId: values.zoneId,
        woredaId: values.woredaId,
        sunctionedAt: values.sunctionedAt,
        sanctionAddress: values.sanctionAddress,
        serialnum: values.serialnum,
        manufacturedCountry: values.manufacturedCountry
      })
      .then(res => {
        successnotify();
        setShowAddModal(false);
        getLoan();
      })
      .catch(err => {
        errornotify();
      });
    getLoan();
  };

  const editCar = () => {
    axiosInstance
      .patch(`${serverIP}/collateral_car/collateralcar/${targetCar.id}`, {
        manufacturedYear: manufacturedYear,
        chassisNumber: chassisNumber,
        engineNumber: engineNumber,
        librebookid: librebookid,
        carPlate: carPlate,
        carCC: carCC,
        loan: loan,
        manufacturedCountry: checkListId,
        model: model,
        insuranceValue: insuranceValue,
        regionId: regionId,
        zoneId: zoneId,
        woredaId: woredaId,
        sunctionedAt: sunctionedAt,
        sanctionAddress: sanctionAddress,
        serialnum: serialnum
      })
      .then(res => {
        console.log(res.data);
        getLoan();
        setShowEditModal(false);
      })
      .catch(err => {
        console.log(err);
      });
    getLoan();
  };

  const deleteCar = () => {
    axiosInstance
      .delete(`${serverIP}/collateral_car/collateralcar/${targetCar.id}`)
      .then(res => {
        console.log(res.data);
        setShowDeleteModal(false);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const changeGP = () => {
    axiosInstance
      .patch(`${serverIP}/collateral_car/collateralcar/${targetCar.id}`, {
        cargp: gpid
      })
      .then(res => {
        successnotify();
        setGpModal(false);

        getLoan();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getLoan = async () => {
    try {
      const res = await axiosInstance.get(`${serverIP}/loan/loans/${targetLoan.id}`);
      dispatch(setTargetLoan({ targetLoan: res.data }));
    } catch (err) {
      console.error('Error fetching loan:', err);
    }
  };

  const handleNormalize = value => {
    return value.toUpperCase();
  };

  return (
    <div>
      <FaCarAlt
        style={{
          position: 'absolute',
          zIndex: 0,
          backgroundRepeat: 'repeat-x',
          width: '50%',
          height: '50%',
          paddingLeft: '25%',
          opacity: 0.03
        }}
      />
      <Alert className="px-3 flex flex-row justify-between py-1 items-center">
        <div style={{ fontSize: 20, fontWeight: 'bold', color: 'GrayText' }}>የመኪና መያዣ</div>
        {data.groups[0] === 'ROLE_OFFICER' && (
          <Button
            onClick={() => setShowAddModal(true)}
            className="flex flex-row items-center gap-2"
            disabled={targetLoan.teamleaderchecking}
          >
            <FaCarAlt /> ጨምር
          </Button>
        )}
      </Alert>
      {/* change guarantee person modal Start  */}
      <Modal show={gpModal} onHide={() => setGpModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title> ዋስ ግለሰብ ቀይር </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <select
            className="form-select"
            onChange={e => {
              setGpid(e.target.value);
              console.log(e.target.value);
            }}
          >
            <option value="null" selected>
              ተበዳሪ
            </option>
            {targetLoan.gp?.length > 0 &&
              targetLoan.gp.map(gp => {
                return (
                  <option key={gp.id} value={gp.id}>
                    {gp.fullname}
                  </option>
                );
              })}
          </select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning btn-sm" onClick={() => changeGP()}>
            Change
          </Button>
        </Modal.Footer>
      </Modal>
      {/* change guarantee person modal End  */}

      {/* add modal Start  */}
      <Modal show={showAddModal} onHide={() => setShowAddModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title> አዲስ መኪና መያዣ </Modal.Title>
        </Modal.Header>

        <Formik
          initialValues={{
            manufacturedYear: '',
            chassisNumber: '',
            engineNumber: '',
            librebookid: '',
            carPlate: '',
            carCC: '',
            loan: loanId,
            manufacturedCountry: '',
            model: '',
            insuranceValue: '',
            regionId: '',
            zoneId: '',
            woredaId: '',
            sunctionedAt: '',
            serialnum: ''
          }}
          validationSchema={CarCollateralSchema}
          onSubmit={saveCarCollateral}
        >
          {({ errors, setFieldValue }) => (
            <>
              <Form>
                <Modal.Body>
                  <label>የመኪና ሞዴል</label>
                  <Field
                    as="select"
                    name="model"
                    className="form-control"
                    style={{
                      border: errors.model ? '1px solid red' : ''
                    }}
                  >
                    <option value="0"> የመኪና ሞዴል ይምረጡ </option>
                    {carModels.map(model => {
                      return (
                        <option key={model.id} value={model.id}>
                          {model.enName}
                        </option>
                      );
                    })}
                  </Field>

                  <ErrorMessage style={{ color: 'red' }} name="model" component="div" />

                  <div className="flex gap-2">
                    <div className="w-full">
                      <label> የተመረተበት ዘመን</label>
                      <Field
                        as="select"
                        name="manufacturedYear"
                        className="form-control"
                        style={{
                          border: errors.manufacturedYear ? '1px solid red' : ''
                        }}
                      >
                        <option value="0"> የተመረተበት ዘመን ይምረጡ </option>
                        {carManufactureYear.length > 0 &&
                          carManufactureYear.map(manufactureYear => {
                            return (
                              <option key={manufactureYear.id} value={manufactureYear.id}>
                                {manufactureYear.yearRange}
                              </option>
                            );
                          })}
                      </Field>

                      <ErrorMessage style={{ color: 'red' }} name="manufacturedYear" component="div" />
                    </div>

                    <div className="w-full">
                      <label>የተመረተበት አገር</label>
                      <Field
                        as="select"
                        name="manufacturedCountry"
                        className="form-control"
                        style={{
                          border: errors.manufacturedCountry ? '1px solid red' : ''
                        }}
                      >
                        <option value="0"> የተመረተበት አገር ይምረጡ </option>
                        {ddlcoutry.map(ctry => {
                          return (
                            <option key={ctry.id} value={ctry.id}>
                              {ctry.amName}
                            </option>
                          );
                        })}
                      </Field>
                      <ErrorMessage style={{ color: 'red' }} name="manufacturedCountry" component="div" />
                    </div>
                  </div>

                  {/* cascaded address start  */}
                  <div className="flex gap-2">
                    <div className="w-full">
                      <label> ክልል </label>
                      <Field
                        as="select"
                        name="regionId"
                        className="form-control"
                        onChange={e => {
                          setFieldValue('regionId', e.target.value);
                          getAllZonesByRegionId(e.target.value);
                        }}
                        style={{
                          border: errors.regionId ? '1px solid red' : ''
                        }}
                      >
                        <option value="0"> ክልል </option>
                        {regionss.length > 0 &&
                          regionss.map(reg => {
                            return (
                              <option key={reg.id} value={reg.id}>
                                {reg.amDescription}
                              </option>
                            );
                          })}
                      </Field>
                      <ErrorMessage style={{ color: 'red' }} name="regionId" component="div" />
                    </div>
                    <div className="w-full">
                      <label> ዞን </label>
                      <Field
                        as="select"
                        name="zoneId"
                        className="form-control"
                        onChange={e => {
                          setFieldValue('zoneId', e.target.value);
                          getAllWoredasByZoneId(e.target.value);
                          console.log(e.target.value);
                        }}
                        style={{
                          border: errors.zoneId ? '1px solid red' : ''
                        }}
                      >
                        <option value="0"> ዞን </option>
                        {zoness.length > 0 &&
                          zoness.map(zone => {
                            return (
                              <option key={zone.id} value={zone.id}>
                                {zone.amDescription}
                              </option>
                            );
                          })}
                      </Field>
                      <ErrorMessage style={{ color: 'red' }} name="zoneId" component="div" />
                    </div>
                    <div className="w-full">
                      <label> ወረዳ </label>
                      <Field
                        as="select"
                        name="woredaId"
                        className="form-control"
                        style={{
                          border: errors.woredaId ? '1px solid red' : ''
                        }}
                      >
                        <option value="0"> ወረዳ </option>
                        {woredass.length > 0 &&
                          woredass.map(woreda => {
                            return (
                              <option key={woreda.id} value={woreda.id}>
                                {woreda.amDescription}
                              </option>
                            );
                          })}
                      </Field>
                      <ErrorMessage style={{ color: 'red' }} name="woredaId" component="div" />
                    </div>
                  </div>
                  {/* cascaded address end  */}

                  <div className="flex gap-2">
                    <div className="w-full">
                      <label> የሊብሬ ቁጥር </label>
                      <Field
                        className="form-control"
                        placeholder="librebookid"
                        type="text"
                        name="librebookid"
                        style={{
                          border: errors.librebookid ? '1px solid red' : ''
                        }}
                      />
                      <ErrorMessage style={{ color: 'red' }} name="librebookid" component="div" />
                    </div>
                    <div className="w-full">
                      <label>የቻሲስ ቁጥር </label>
                      <Field
                        className="form-control"
                        placeholder="chassisNumber"
                        type="text"
                        name="chassisNumber"
                        normalize={handleNormalize}
                        oninput="this.value = this.value.toUpperCase()"
                        style={{
                          border: errors.chassisNumber ? '1px solid red' : ''
                        }}
                      />
                      <ErrorMessage style={{ color: 'red' }} name="chassisNumber" component="div" />
                    </div>
                    <div className="w-full">
                      <label> የኢንጅን ቁጥር </label>
                      <Field
                        className="form-control"
                        placeholder="engineNumber"
                        type="text"
                        name="engineNumber"
                        style={{
                          border: errors.engineNumber ? '1px solid red' : ''
                        }}
                      />
                      <ErrorMessage style={{ color: 'red' }} name="engineNumber" component="div" />
                    </div>
                  </div>

                  <div className="flex gap-2">
                    <div className="w-full">
                      <label>ታርጋ ቁጥር </label>
                      <Field
                        className="form-control"
                        placeholder="carPlate"
                        type="text"
                        name="carPlate"
                        style={{
                          border: errors.carPlate ? '1px solid red' : ''
                        }}
                      />
                      <ErrorMessage style={{ color: 'red' }} name="carPlate" component="div" />
                    </div>
                    <div className="w-full">
                      <label> ሴሪያል ቁጥር </label>
                      <Field
                        className="form-control"
                        placeholder="ሴሪ ቁጥር"
                        type="number"
                        name="serialnum"
                        style={{
                          border: errors.serialnum ? '1px solid red' : ''
                        }}
                      />
                      <ErrorMessage style={{ color: 'red' }} name="serialnum" component="div" />
                    </div>
                  </div>

                  <label> የኢንሹራንስ ግምት </label>
                  <Field
                    className="form-control"
                    placeholder="የኢንሹራንስ ግምት"
                    type="number"
                    name="insuranceValue"
                    style={{
                      border: errors.insuranceValue ? '1px solid red' : ''
                    }}
                  />
                  <ErrorMessage style={{ color: 'red' }} name="insuranceValue" component="div" />

                  <label> የመኪና CC </label>
                  <Field
                    className="form-control"
                    placeholder="carCC"
                    type="number"
                    name="carCC"
                    style={{
                      border: errors.carCC ? '1px solid red' : ''
                    }}
                  />
                  <ErrorMessage style={{ color: 'red' }} name="carCC" component="div" />

                  <label> እግድ የተሰጠበት ቀን </label>
                  <Field
                    className="form-control"
                    placeholder="sunctionedAt"
                    type="date"
                    name="sunctionedAt"
                    style={{
                      border: errors.sunctionedAt ? '1px solid red' : ''
                    }}
                  />

                  <label> የእግድ አድራሻ </label>
                  <Field
                    className="form-control"
                    placeholder="የእግድ አድራሻ"
                    type="text"
                    name="sanctionAddress"
                    style={{
                      border: errors.sanctionAddress ? '1px solid red' : ''
                    }}
                  />
                  <ErrorMessage style={{ color: 'red' }} name="sanctionAddress" component="div" />

                  <ErrorMessage style={{ color: 'red' }} name="sunctionedAt" component="div" />
                </Modal.Body>
                <Modal.Footer>
                  <Button type="submit" variant="primary btn-sm">
                    አስቀምጥ
                  </Button>
                </Modal.Footer>
              </Form>
            </>
          )}
        </Formik>
      </Modal>
      {/* add modal End  */}

      {/* edit modal Start  */}
      <Modal show={showEditModal} onHide={() => setShowEditModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>የመኪና መረጃ አስተካክል</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <select onChange={e => setmodel(e.target.value)} className="form-control">
            <option value="0"> የመኪና ሞዴል ይምረጡ </option>
            {carModels.length > 0 &&
              carModels.map(model => {
                return (
                  <option key={model.id} selected={model.id === targetCar?.model?.id} value={model.id}>
                    {model.enName}
                  </option>
                );
              })}
          </select>
          <div className="py-2">
            <select onChange={e => setmanufacturedYear(e.target.value)} className="form-control py-2">
              <option value="0"> የተመረተበት ዘመን ይምረጡ </option>
              {carManufactureYear.length > 0 &&
                carManufactureYear.map(manufactureYear => {
                  return (
                    <option
                      selected={manufactureYear.id === targetCar?.manufacturedYear?.id}
                      key={manufactureYear.id}
                      value={manufactureYear.id}
                    >
                      {manufactureYear.yearRange}
                    </option>
                  );
                })}
            </select>
          </div>
          <select onChange={e => setcheckListId(e.target.value)} className="form-control">
            <option value="0"> የተመረተበት አገር ይምረጡ </option>
            {ddlcoutry.map(ctry => {
              return (
                <option selected={ctry.id === targetCar?.checkListId?.id} key={ctry.id} value={ctry.id}>
                  {ctry.amName}
                </option>
              );
            })}
          </select>
          <div className="py-2">
            {/* <select
              onChange={(e) => setsubcity(e.target.value)}
              className="form-control"
            >
              <option value="0"> ሊብሬው የተሰጠበት ክ/ከተማ </option>
              {subcities.map((subc) => {
                return (
                  <option
                    selected={subc.id === targetCar?.subcity?.id}
                    key={subc.id}
                    value={subc.id}
                  >
                    {subc.amName}
                  </option>
                );
              })}
            </select> */}
          </div>

          <TextInputTw placeholder="የሊብሬ ቁጥር" onChange={e => setlibrebookid(e.target.value)} value={librebookid} />
          <TextInputTw placeholder="የቻሲስ ቁጥር" onChange={e => setchassisNumber(e.target.value)} value={chassisNumber} />
          <TextInputTw placeholder="የኢንጅን ቁጥር" onChange={e => setengineNumber(e.target.value)} value={engineNumber} />
          <TextInputTw placeholder="ታርጋ ቁጥር" onChange={e => setcarPlate(e.target.value)} value={carPlate} />
          <TextInputTw
            placeholder="የኢንሹራንስ ግምት"
            onChange={e => setinsuranceValue(e.target.value)}
            value={insuranceValue}
          />
          <TextInputTw placeholder="የመኪና CC" onChange={e => setcarCC(e.target.value)} value={carCC} />

          <TextInputTw placeholder="ሴርያል ቁጥር" onChange={e => setserialnum(e.target.value)} value={serialnum} />

          <label className="w-full py-1">
            <div className="label">
              <span className="label-text"> እግድ የተሰጠበት ቀን </span>
            </div>
            <input
              type="date"
              value={sunctionedAt}
              onChange={e => setsunctionedAt(e.target.value)}
              className="input input-bordered w-full form-control"
            />
          </label>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning btn-sm" onClick={() => editCar()}>
            አስተካከል
          </Button>
        </Modal.Footer>
      </Modal>
      {/* edit modal End  */}

      {/* delete modal Start  */}
      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title> በመያዣነት የቀረበው መኪና አጥፋ </Modal.Title>
        </Modal.Header>
        <Modal.Body>!</Modal.Body>
        <Modal.Footer>
          <Button variant="danger btn-sm" onClick={() => deleteCar()}>
            አጥፋ
          </Button>
        </Modal.Footer>
      </Modal>
      {/* delete modal End  */}

      {Object.keys(targetLoan?.collateralcar)?.length > 0 && (
        <Table striped bordered hover style={{ marginTop: 10 }}>
          <thead>
            <tr>
              <th>Chasis-Engine-Plate</th>
              <th>Insurance</th>
              <th>Model</th>
              {data.groups[0] === 'ROLE_OFFICER' ? <th>Action</th> : <></>}
              <th>Files</th>
              <th>Status</th>
              <th>Owned By</th>
            </tr>
          </thead>
          <tbody>
            {targetLoan.collateralcar?.map(car => {
              return (
                <tr>
                  <td>{car.chassisNumber + ' - ' + car.engineNumber + ' - ' + car.carPlate}</td>
                  <td> {car.insuranceValue?.toLocaleString()} </td>
                  <td>{car.model?.enName + ' - ' + car.manufacturedCountry?.amName} </td>
                  {data.groups[0] === 'ROLE_OFFICER' ? (
                    <td
                      style={{
                        display: 'flex',
                        justifyContent: 'space-evenly'
                      }}
                    >
                      <FaTrashAlt
                        onClick={() => {
                          setShowDeleteModal(true);
                          setTargetCar(car);
                        }}
                        color="red"
                      />
                      &nbsp;
                      <FaPencilAlt
                        onClick={() => {
                          setShowEditModal(true);
                          setTargetCar(car);

                          setchassisNumber(car.chassisNumber);
                          setengineNumber(car.engineNumber);
                          setlibrebookid(car.librebookid);
                          setcarPlate(car.carPlate);
                          setcarCC(car.carCC);
                          setinsuranceValue(car.insuranceValue);

                          setmanufacturedYear(car.manufacturedYear.id);
                          setloan(car.loan?.id);
                          setmodel(car.model?.id);
                          setmanufacturedCountry(car.manufacturedCountry?.id);

                          setsunctionedAt(car.sunctionedAt);
                          setserialnum(car.serialnum);
                        }}
                        color="orange"
                      />
                    </td>
                  ) : (
                    <></>
                  )}
                  <td>
                    <span style={{ color: 'orange' }}>
                      <Link
                        to={`/carcollateralfiles/${targetLoan.id}/${car.id}`}
                        className="flex flex-row items-center gap-2"
                      >
                        <Badge>
                          {car.carfiles?.filter(x => x.isUploaded === true)?.length}/ {car.carfiles?.length}
                        </Badge>
                        <FaFileAlt />
                      </Link>
                    </span>
                  </td>
                  <td>{car.isApproved ? 'Approved' : 'Waiting'}</td>
                  <td>
                    <div className="flex flex-row gap-3 justify-center items-center">
                      {car.cargp !== null ? car.cargp?.fullname : 'የተበዳሪ'}
                      {data.groups[0] === 'ROLE_OFFICER' ? (
                        <span>
                          <FaExchangeAlt
                            color="orange"
                            onClick={() => {
                              setGpModal(true);
                              setTargetCar(car);
                            }}
                          />
                        </span>
                      ) : (
                        <></>
                      )}
                    </div>
                  </td>
                </tr>
              );
            })}
            {/*  */}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export default CollateralCarComp;
