import React from "react";
import Image1 from "../Help/Officer/p1.png";
import Image2 from "../Help/Officer/p2.png";
import Image3 from "../Help/Officer/p3.png";
import Image4 from "../Help/Officer/p4.png";
import Image5 from "../Help/Officer/p5.png";

import { Card, Image } from "react-bootstrap";

function HelpOfficer() {
  return (
    <div className="container">
      <div>
        ይህ ገጽ የብድር ፕሮሰስ ለመጀመር ፣ ለመከታተልና ብድር ለመጠየቅ የምንከተለውን ቅደም ተከተል የሚያሳይ ነው፡፡
      </div>
      <div className="row">
        <div className="col-md-12 col-lg-12 col-sm-12">
          <Card className="mt-2">
            <Card.Header> Step 1 : ዋና ገጽ ላይ ይግቡ </Card.Header>
            <Card.Body>
              <Image src={Image1} />
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-12 col-lg-12 col-sm-12">
          <Card className="mt-2">
            <Card.Header>
              
              Step 2 : የደንበኛ መታወቅያ ቁጥር ያስገቡ (External ID)፣ ፈልግ በመንካት ያስቀጥሉ{" "}
            </Card.Header>
            <Card.Body>
              <Image src={Image2} />
            </Card.Body>
          </Card>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 col-lg-6 col-sm-12">
          <Card className="mt-2">
            <Card.Header> Step 3: መረጃ መገልበጥ </Card.Header>
            <Card.Body>
              <ol className="list-disc list-inside text-gray-700">
                <li className="text-lg font-medium"> የተበዳሪ መረጃ መገልበጥ ቀጥሎ </li>
                <li className="text-lg font-medium"> የብድር መረጃ መገልበጥ </li>
              </ol>
              <Image src={Image3} />
            </Card.Body>
          </Card>
        </div>
        <div className="col-md-12 col-lg-6 col-sm-12">
          <Card className="mt-2">
            <Card.Header> Step 4፡ የደንባኛ ብድሮች ሊንክ በመንካት ወደ ብድር መረጃ ይሂዱ፡፡ </Card.Header>
            <Card.Body>
              <Image src={Image4} />
            </Card.Body>
          </Card>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 col-lg-12 col-sm-12">
          <Card className="mt-2">
            <Card.Header> Step 5፡ የብድር ዳሽቦርድ </Card.Header>
            <Card.Body>
              <Image src={Image5} />
            </Card.Body>
          </Card>
        </div>
        
      </div>
    </div>
  );
}

export default HelpOfficer;
