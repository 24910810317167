import React, { useEffect, useState } from 'react';
import { Button, InputGroup, Modal, Table } from 'react-bootstrap';

import { FaEye, FaFileAlt, FaPencilAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../axiosInstance';

function CarMarketValue() {
  const { serverIP } = useSelector(store => store.allsettings);
  const { data } = useSelector(store => store.customer);

  const [marketValue, setMarketValue] = useState(0);
  const [show, setShow] = useState(false);
  const [editModalFile, setEditModalFile] = useState(false);
  const [editModalPrice, setEditModalPrice] = useState(false);

  const [targetMarketValue, setTargetMarketValue] = useState({});
  const [file, setFile] = useState(null);
  const { carId, loanId } = useParams();
  const [marketEstimatedValue, setMarketEstimatedValue] = useState(0);

  useEffect(() => {
    getMarketValues();
  }, []);

  const getMarketValues = async () => {
    try {
      const theCarFiles = await axiosInstance.get(`${serverIP}/car_marketvalue/bycarid/${carId}`);
      console.log('theCarFiles.data');
      console.log(theCarFiles.data);
      setMarketEstimatedValue(theCarFiles.data);
    } catch (error) {
    } finally {
    }
  };

  const fileEdit = () => {
    if (!file) {
      // toast.error("No File Selected");
      return;
    }

    const fd = new FormData();
    fd.append('file', file);
    fd.append('collateralcar', carId);
    fd.append('fileType', file.type);
    fd.append('fileUrl', file);

    axiosInstance
      .put(`${serverIP}/car_marketvalue/carmarketvalues/${targetMarketValue.id}`, fd, {
        headers: {
          'Custom-Header': 'value'
        }
      })
      .then(res => {
        setEditModalFile(false);
        getMarketValues();
      })
      .catch(err => {});
  };
  const priceEdit = () => {
    axiosInstance
      .put(`${serverIP}/car_marketvalue/carmarketvalues/${targetMarketValue.id}`, {
        marketValue: marketValue,
        collateralcar: carId
      })
      .then(res => {
        console.log(res.data);

        setEditModalPrice(false);
        getMarketValues();
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <div>
      {/* Modal View Start  */}
      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton> {targetMarketValue.amDesc} </Modal.Header>
        <Modal.Body>
          <img src={serverIP + targetMarketValue.fileUrl} style={{ width: '100%', height: '100%' }} />
        </Modal.Body>
      </Modal>
      {/* Modal View End  */}

      {/* Modal Price Edit Start  */}
      <Modal show={editModalPrice} onHide={() => setEditModalPrice(false)}>
        <Modal.Header closeButton>
          <Modal.Title variant="warning"> ማስተካከያ </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="input-group mb-3">
            <span className="input-group-text" id="basic-addon1">
              የጋራጅ ግምት ዋጋ
            </span>
            <input
              type="text"
              className="form-control"
              value={marketValue}
              onChange={e => setMarketValue(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-warning"
            onClick={() => {
              priceEdit();
            }}
          >
            አስተካክል
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Price Edit End  */}

      {/* Modal File Edit Start  */}
      <Modal show={editModalFile} onHide={() => setEditModalFile(false)}>
        <Modal.Header closeButton>
          <Modal.Title variant="warning"> ማስተካከያ </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup style={{ paddingTop: 5 }}>
            <input
              onChange={e => {
                setFile(e.target.files[0]);
                console.log(e.target.files[0]);
              }}
              type="file"
              className="form-control"
            />
            <Button
              variant="outline-warning"
              onClick={() => {
                fileEdit();
              }}
            >
              አስተካክል
            </Button>
          </InputGroup>
        </Modal.Body>
      </Modal>
      {/* Modal File Edit End  */}

      <Table striped bordered hover style={{ margin: 1 }}>
        <thead>
          <tr>
            <th className="text-center">የገበያ ጥናት ዋጋ</th>
            <th className="text-center"> ፋይል </th>
          </tr>
        </thead>
        <tbody>
          {marketEstimatedValue?.length > 0 &&
            marketEstimatedValue.map(upload => {
              return (
                <tr>
                  <td
                    style={{
                      display: 'flex',
                      justifyContent: 'space-around'
                    }}
                    className={upload.marketValue !== 0 ? `` : `table-warning`}
                  >
                    {upload.marketValue?.toLocaleString()}
                    <FaPencilAlt
                      onClick={() => {
                        setTargetMarketValue(upload);
                        setEditModalPrice(true);
                        setMarketValue(upload.marketValue);
                      }}
                      style={{ color: 'orange' }}
                    />
                  </td>

                  {data.groups[0] === 'ROLE_OFFICER' ? (
                    <td>
                      <div className="flex justify-around">
                        {upload.fileType === 'application/pdf' ? (
                          <>
                            <a target="_blank" href={serverIP + upload.fileUrl} rel="noopener noreferrer">
                              <FaFileAlt />
                            </a>
                          </>
                        ) : (
                          <>
                            <FaEye
                              onClick={() => {
                                setTargetMarketValue(upload);
                                setShow(true);
                              }}
                            />
                          </>
                        )}
                        <FaPencilAlt
                          onClick={() => {
                            setTargetMarketValue(upload);
                            setEditModalFile(true);
                            setMarketValue(upload.marketValue);
                          }}
                          style={{ color: 'orange' }}
                        />
                      </div>
                    </td>
                  ) : (
                    <td></td>
                  )}
                </tr>
              );
            })}
        </tbody>
      </Table>
    </div>
  );
}

export default CarMarketValue;
