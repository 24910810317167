import React, { useEffect, useState } from "react";
import { PulseLoader } from "react-spinners";


function LoadingSpinner() {
  const [rotation, setRotation] = useState(0);

  const rotateClockwise = () => {
    setTimeout(() => {
      setRotation((prevRotation) => prevRotation + 5);
    }, 50);
  };

  useEffect(() => {
    rotateClockwise();
  }, [rotation]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
     
      <div
        style={{
          fontSize: "5vh",
          paddingTop: "25vh",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div style={{paddingLeft: "5vh"}}>
          <PulseLoader size={30} color="orange" loading="true" />
        </div>
        <div style={{ color: "#FFA500"}}>Loading ...</div>
      </div>

    </div>
  );
}

export default LoadingSpinner;
