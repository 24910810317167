import React from 'react';
import { Document, Font, Page, Text, StyleSheet, View } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import getEthiopicDate from '../Common/modules/GregorianToEthiopian';
import ebrima from './font/ebrima.ttf';
import ebrimabold from './font/ebrimabd.ttf';
import TextBold from './Reusables/TextBold';
import { numberToWords, numberToWordsPercent } from '../Common/modules/EnNumToWord';
import Header from './Reusables/Header';
import TextBoldUnderlined from './Reusables/TextBoldUnderlined';

const Styles = StyleSheet.create({
  body: {
    flexDirection: 'column',
    padding: 40,
    fontSize: 12,
    height: '100%'
  },
  heading_one: {
    marginTop: 6,
    marginBottom: 3,
    fontSize: 12,
    textAlign: 'center',
    fontFamily: 'ebrimabold',
    fontWeight: 'bold'
  },
  heading_two: {
    marginTop: 6,
    marginBottom: 3,
    fontSize: 9,
    fontFamily: 'ebrima',
    fontWeight: 'bold'
  },
  text_normal: {
    fontSize: 10,
    fontFamily: 'ebrima',
    marginTop: 3
  },
  rightView: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 8,
    fontFamily: 'ebrima',
    justifyContent: 'flex-end'
  },
  textpaddingleft30: {
    marginTop: 1,
    fontSize: 9,
    fontFamily: 'AmharicFont',
    fontWeight: 'bold',
    paddingLeft: 30
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    right: 30,
    fontSize: 10,
    fontFamily: 'AmharicFont',
    color: 'grey'
  }
});

Font.register({
  family: 'ebrima',
  src: ebrima
});

Font.register({
  family: 'ebrimabold',
  src: ebrimabold
});

function AgreementDoc({ customer, loan }) {
  const curdate = new Date();
  const etcurrdate = getEthiopicDate(curdate.getDate(), +' ' + curdate.getMonth() + 1, +' ' + curdate.getFullYear());

  // const changeGtToEt = date => {
  //   let arrDate = date?.split('-');
  //   return getEthiopicDate(Number(arrDate[2]), Number(arrDate[1]), Number(arrDate[0]));
  // };

  return (
    <PDFViewer style={{ width: '100%', height: 700 }}>
      <Document title="የብድር ውል ስምምነት">
        <Page style={Styles.body} wrap>
          <Header type="የብድር ውል ስምምነት" />
          <View>
            <Text style={Styles.heading_one}> የገንዘብ ብድር ውል ስምምነት </Text>
          </View>
          {/* አበዳሪ እና ተበዳሪ አድራሻ start */}
          <Text style={Styles.text_normal}>አበዳሪ :- አሚጎስ የገንዘብ ቁጠባና ብድር ኃ/የተ/ህ/ስ/ማ ከዚህ በኋላ ‘’አበዳሪ’’ እየተባለ የሚጠራ</Text>
          <Text style={Styles.textpaddingleft30}>አድራሻ :- አ.አ አራዳ ክፍለ ከተማ ወረዳ 05 የቤት ቁጥር</Text>
          <Text style={Styles.text_normal}>ተበዳሪ :-ወ/ት/ወ/ሮ/አቶ ከዚህ በኋላ ‘’ተበዳሪ’’ እየተባሉ የሚጠሩ</Text>
          <Text style={Styles.textpaddingleft30}>
            አድራሻ :- ክ/ከተማ <TextBoldUnderlined text={customer.zoneId} /> ወረዳ
            <TextBoldUnderlined text={customer.woredaId} /> የቤት.ቁጥር <TextBoldUnderlined text={customer.houseNum} />
            ስልክ ቁጥር <TextBoldUnderlined text={customer.mobileNo} /> የመታወቂያ ቁጥር
            <TextBoldUnderlined text={customer.idNum} />
            ፡፡
          </Text>
          <Text style={Styles.text_normal}>አበዳሪና ተበዳሪ ከዚህ ቀጥሎ በተዘረዘረው መልኩ ስምምነት አድርገናል፡፡</Text>

          {/* አበዳሪ እና ተበዳሪ አድራሻ end */}

          <View>
            <Text style={Styles.heading_one}> አንቀጽ አንድ የውሉ ዓላማ </Text>
          </View>
          <Text style={Styles.text_normal}>
            ይህ የገንዘብ ብድር ውል ሊደረግ የቻለው ተበዳሪ በቀን
            {/* <TextBoldUnderlined text={changeGtToEt(loan?.applicationDate)} />  */}
            <TextBoldUnderlined text={loan?.etApplicationDate} /> በተጻፈ ማመልከቻ ለ
            <TextBoldUnderlined text={loan?.loanType?.amName} /> አገልግሎት የሚውል የብር
            <TextBoldUnderlined text={loan?.r_approvedPrincipal?.toLocaleString()} />
            <TextBold text={` (${numberToWords(loan.r_approvedPrincipal)}) `} /> ብድር እንዲሰጣቸው የጠየቁ በመሆኑና አበዳሪም ጥያቄውን
            የተቀበለ በመሆኑ ነው፡፡
          </Text>

          <View>
            <Text style={Styles.heading_one}> አንቀጽ ሁለት የብድርና አጠቃላይ የወለድ መጠን </Text>
          </View>
          <Text style={Styles.text_normal}>
            እኔ ተበዳሪው ለ <TextBoldUnderlined text={loan?.loanType?.amName} /> አገልግሎት የሚውል ዋና/principal/ብድር ብር
            <TextBoldUnderlined text={loan?.r_approvedPrincipal?.toLocaleString()} />
            <TextBold text={` (${numberToWords(loan.r_approvedPrincipal)}) `} /> ዓመታዊ የወለድ መጠኑ
            <TextBold text={`${loan.annualInterestRate}%`} />
            <TextBold text={` /${numberToWordsPercent(loan.annualInterestRate)}/`} /> ተሰልቶ በወር ብር
            <TextBoldUnderlined text={loan?.totalDueForPeriod?.toLocaleString()} />
            <TextBold text={` (${numberToWords(loan.totalDueForPeriod)}) `} /> ፣ ጠቅላላ ብድርና የወለድ መጠን ብር
            <TextBoldUnderlined text={loan?.total_loan?.toLocaleString()} />
            <TextBold text={` (${numberToWords(loan.total_loan)}) `} />
            መሆኑን አውቄና ተረድቼ ገንዘቡን የተቀበልኩ መሆኑን በተለመደው ፊርማዬ አረጋግጣለሁ፡፡
          </Text>

          <View>
            <Text style={Styles.heading_one}> አንቀጽ ሶስት የብድሩ ገንዘብ ተከፍሎ ስለሚያበቃበት ጊዜ </Text>
          </View>
          <Text style={Styles.text_normal}>
            ተበዳሪው ይህ ውል ከተፈረመበት ከዛሬ <TextBoldUnderlined text={etcurrdate} /> ጀምሮ በሚቆጠር ተከታታይ
            <TextBoldUnderlined text={loan?.numberOfRepayments} /> ወራት ጊዜ ውስጥ ከፍዬ ለማጠናቀቅ ተስማምቻለሁ፡፡
          </Text>

          <View>
            <Text style={Styles.heading_one}> አንቀጽ አራት የተበዳሪው ግዴታዎች </Text>
          </View>
          <Text style={Styles.text_normal}>
            4.1. ተበዳሪው የተበደሩትን አጠቃላይ ዋና ገንዘብና ወለድ ከላይ በአንቀጽ ሶስት ስር በተጠቀሰው የጊዜ ገደብ ውስጥ ከፍሎ ማጠናቀቅ፤
          </Text>
          <Text style={Styles.text_normal}>
            4.2. የተበዳሪው ወርሃዊ ክፍያ ብር <TextBoldUnderlined text={loan?.totalDueForPeriod?.toLocaleString()} />
            <TextBold text={` (${numberToWords(loan.totalDueForPeriod)}) `} />. በየወሩ ያለምንም ማቋረጥ የመክፈል፤
          </Text>
          <Text style={Styles.text_normal}>
            4.3. ከአገልግሎትና የህይወት መድን ኢንሹራንስ በተጨማሪ ካላቸው ተቀማጭ ላይ የብድሩ ገንዘብ ተከፍሎ እስከሚጠናቀቅ ድረስ በየዓመቱ የዋናው ብድር 2% የብድር ኢንሹራንስ
            መክፈል፤
          </Text>
          <Text style={Styles.text_normal}>
            4.4. ተበዳሪው በዚህ አንቀጽ ንዑስ አንቀጽ 4.2 ስር በተገለጸው አግባብ በየወሩ መክፈል ባይችሉ ክፍያውን ካቋረጡበት ጊዜ ጀምሮ በወርሃዊ የወለድና የዋና ብድር ክፍያ
            ላይ በየወሩ የ20%/ሃያ በመቶ /ቅጣት መክፈል፤
          </Text>
          <Text style={Styles.text_normal}>4.5. ውሉ በሚቋረጥበት ጊዜ ያለባቸውን ቀሪ ዋና ብድር፣ወለድ፣እና ቅጣት አጠቃላይ ክፍያ በአንድ ጊዜ መክፈል፤</Text>
          <Text style={Styles.text_normal}>
            4.6. ተበዳሪው ለሚያገኙት የብድር አገልግሎት ከአጠቃላይ የብድር መጠን ላይ 4%/ በመቶ/ እንዲሁም ለህይወት መድን ዋስትና 2%/ሁለት በመቶ/በቅድሚያ መክፈል እንዲሁም
            ይህንን ውል ለማስፈጸም የሚወጡ የቴምብር ቀረጥ ክፍያ፣የውል ማስመዝገቢያ/ካለ/፣ፍርድ ቤት የሚሰጡ የእግድ ትእዛዞች ማስፈጸሚያ እና የመሳሰሉ ማንኛቸውም ወጪዎች መክፈል፤
          </Text>
          <Text style={Styles.text_normal}>
            4.7. በዚህ አንቀጽ ንዑስ አንቀጽ 4.5 ስር የተደነገገው እንደተጠበቀ ሆኖ ተበዳሪው ሂሳብ ቁጥር ውስጥ የተጠራቀመው ገንዘብ ይህንን ወጪ ለመሸፈን የሚያስችል ካልሆነና
            ይህ ወጪም በአበዳሪ ከተሸፈነ አጠቃላይ የብድር ሂሳብ ላይ ተደምሮ ወለድ ታስቦበት መክፈል፤
          </Text>
          <Text style={Styles.text_normal}>
            4.8. ተበዳሪው በውሉ መሰረት ሳይፈጽሙ ቢቀሩ ወይም በውሉ በተቀመጠው የጊዜ ገደብ ውስጥ መክፈል አቅቷቸው ለተከታታይ 2 ወራት ካቋረጡ ከዚህ በታች አንቀጽ 7 ስር
            በተገለጸው መሰረት ጉዳዩ በሽምግልና ዳኞች ሲታይ አበዳሪ ላይ የሚደርሰውን ወጪ ኪሳራ ለመተካት ይቻል ዘንድ አጠቃላይ ክስ የቀረበበት ገንዘብ መነሻ በማድረግ ኪሳራ መክፈል፤
          </Text>
          <Text style={Styles.text_normal}>
            4.9. ተበዳሪው በዚሁ አንቀጽ ንዑስ አንቀጽ 4.7 በተገለጸው መሰረት ጉዳዩ በሽምግልና ዳኞች ሲቀርብ በአበዳሪ ላይ ለደረሰው ኪሳራ ማካካሻ እንዲሆን አጠቃላይ ክስ
            የቀረበበት ገንዘብ፡-
          </Text>
          <Text style={Styles.textpaddingleft30}>- ከብር 100,000/አንድ መቶ ሺህ ብር/በታች ከሆነ የዚህን ገንዘብ 10%/አስር ከመቶ/፣</Text>
          <Text style={Styles.textpaddingleft30}>
            - ከብር 100,000-499,999/ከአንድ መቶ ሺህ ብር እስከ አራት መቶ ዘጠና ዘጠኝ ሺህ ዘጠኝ መቶ ዘጠና ዘጠኝ ብር/ ከሆነ 9%/ዘጠኝ ከመቶ/
          </Text>
          <Text style={Styles.textpaddingleft30}>
            - ከብር 500,000- 999,999/ከአምስት መቶ ሺህ ብር እስከ ዘጠኝ መቶ ዘጠና ዘጠኝ ሺህ ዘጠኝ መቶ ዘጠና ዘጠኝ ብር/ከሆነ 8%/ስምንት ከመቶ/
          </Text>
          <Text style={Styles.textpaddingleft30}>
            - ከብር 1,000,000-1,500,000/ከአንድ ሚሊዮን እስከ አንድ ሚሊዮን አምስት መቶ ሺህ ብር/ከሆነ 7%/ሰባት ከመቶ/
          </Text>
          <Text style={Styles.textpaddingleft30}>
            - ከብር1,500,000/አንድ ሚሊዮን አምስት መቶ ሺህ ብር/ በላይ ከሆነ 6%/ስድስት ከመቶ/ይከፍላሉ፡፡
          </Text>
          <Text style={Styles.text_normal}>
            4.10. በዚህ አንቀጽ ንዑስ አንቀጽ 4.8 የተገለጸው እንደተጠበቀው ሆኖ ተበዳሪው በሽምግልና ዳኞች የተወሰነባቸውን ገንዘብ ለአፈጻጸም መደበኛ ፍርድ ቤት ከመሄዱ በፊት
            ከከፈሉ ከላይ ከፍብሎ የተጠቀሰው የወጪ ኪሳራ ክፍያ ብቻ የሚከፍሉ ሲሆን ጉዳዩ ለአፈጻጸም መደበኛ ፍርድ ቤት ከቀረበ ግን ከላይ በአንቀጽ 4 ንዑስ አንቀጽ 4.8 ስር
            የተገለጸው ክፍያ እጥፉን የሚከፍሉ ይሆናል፡፡
          </Text>

          <Text style={Styles.footer} render={({ pageNumber, totalPages }) => `ገጽ ${pageNumber} - ${totalPages}`} />
        </Page>
        <Page style={Styles.body} wrap>
          <View>
            <Text style={Styles.heading_one}> አንቀጽ አምስት የአበዳሪው ግዴታዎች </Text>
          </View>
          <Text style={Styles.text_normal}>5.1. ተበዳሪው አስፈላጊውን መስፈርት ማሟላቱን ካረጋጠ በኋላ ብድሩን እንዲያገኝ መፍቀድ፣</Text>
          <Text style={Styles.text_normal}>5.2. ተበዳሪው የብድሩ ሁኔታ ያለበት ደረጃ ማወቅ ሲፈልግ ማሳወቅ፤</Text>
          <Text style={Styles.text_normal}>
            5.3. ተበዳሪው አጠቃላይ የተበደሩትን የብድር ገንዘብ የብድር መክፈያ ዘመኑ ሳያልቅ አጠቃለው ሲከፍሉ ለቀሪው ዘመን ታስቦ የነበረው ወለድ ተቀናሽ ማድረግ፤
          </Text>
          <View>
            <Text style={Styles.heading_one}> አንቀጽ ስድስት የአበዳሪው መብቶች </Text>
          </View>
          <Text style={Styles.text_normal}>6.1. ተበዳሪው እንደውሉ መፈጸም ካቆሙ የ30 ቀናት ማስጠንቀቂያ ብቻ ሰጥቶ ውሉን ማቋረጥ፤</Text>
          <Text style={Styles.text_normal}>
            6.2. ተበዳሪው የብድሩን ገንዘብ በዚህ ውል መሰረት በአግባቡ መክፈል ባይችሉ በአበዳሪ ህ/ስ/ማም ሆነ በሌሎች ማህበራት እንዲሁም የተለያዩ ባንኮች ላይ ያለውን ቁጠባ
            ለእዳ ማቻቻያ ማዋል፤
          </Text>
          <Text style={Styles.text_normal}>
            6.3. ተበዳሪው በተቀመጠው የጊዜ ሰሌዳ መሰረት በአግባቡ መክፈል ቢያቅታቸው በመያዣነት ያስያዙትን የዋሱ ወይም የተበዳሪው ንብረት በቀጥታ በመሸጥ የተበዳሪው እዳ
            እንዲከፈል በማድረግ ቀሪ ገንዘብ ካለ ለተበዳሪው መመለስ፤
          </Text>
          <Text style={Styles.text_normal}>
            6.4. በዚህ አንቀጽ ንዑስ አንቀጽ 6.2 ስር በተገለጸው አግባብ ንብረቱ የተሸጠበት ገንዘብ ተበዳሪው ሊከፍል ከሚገባው ገንዘብ የሚያንስ ሆኖ ቢገኝ ለቀሪው ገንዘብ
            ተበዳሪው ላይ አስፈላውን ክስ የማቅረብ፣
          </Text>
          <Text style={Styles.text_normal}>
            6.5. ተበዳሪው እንደውሉ ባለመፈጸማቸው ምክንያት አበዳሪው ገንዘቡን ለማስመለስ በሚያደርገው ጥረት የሚደርስበትን ወጪና ኪሳራ በዚህ ውል በተገለጸው ሁኔታ ተበዳሪው
            እንዲተካ የመጠየቅ
          </Text>
          <View>
            <Text style={Styles.heading_one}> አንቀጽ ሰባት የግጭት አፈታት ዘዴዎች </Text>
          </View>
          <Text style={Styles.text_normal}>
            7.1. በአበዳሪና በተበዳሪ መካከል የብድር አከፋፈልን አስመልክቶም ሆነ ሌሎች አለመግባባቶች በሚፈጠሩበት ጊዜ በአዋጅ ቁጥር 985/2009 አንቀጽ 61 በተገለጸው መሰረት
            በቅድሚያ በአበዳሪና በተበዳሪ መካከል የተፈጠረ አለመግባባት ግራቀኙ በሚመርጡት አስማሚ ሽማግሌዎች መሰረት ሊስማሙ ይችላሉ፡፡
          </Text>
          <Text style={Styles.text_normal}>
            7.2. ከዚህ በላይ በተገለጸው አግባብ ጉዳዩ በእርቅ መፈታት ካልቻለ በአዋጅ ቁጥር 985/2009 መሰረት በሽምግልና ዳኞች ታይቶ እንዲወሰን ስምምነት አድርገናል፡፡
          </Text>
          <Text style={Styles.text_normal}>
            7.3. በህብረት ስራ ማህበራት አዋጅ ቁጥር 985/2009 አንቀጽ 64 ስር ከተዘረዘሩት ጉዳዮቻችን በተጨማሪ ተበዳሪው በብድር ውሉ ላይ በተገለጸው አግባብ ሳይፈጽም ቢቀር
            ፣በየወሩ መክፈል የሚገባውን ክፍያ ቢያቋርጥ በአጠቃላይ እንደውሉ እንዲፈጸም ፍርድ ቤት ሊቀርብ ይገባው የነበረው ክስ ለሽምግልና ዳኞች እንዲቀርብና ለአፈጻጸም ስልጣኑ
            ለሚፈቅደው መደበኛ ፍርድ ቤት እንቀርብ ስምምነት አድርገናል፡፡
          </Text>
          <View>
            <Text style={Styles.heading_one}> አንቀጽ ስምንት የሽምግልና ዳኞች አመራረጥ </Text>
          </View>
          <Text style={Styles.text_normal}>
            8.1. በአበዳሪና በተበዳሪ መካከል የተፈጠረውን አለመግባባት ለመፍታት ወይም ተበዳሪው በብድር ውሉ መሰረት ሊከፍሉ ያልቻሉትን ገንዘብ እንዲከፍሉ ለማድረግ አበዳሪና ተበዳሪ
            የየግላቸው የሽምግልና ዳኞች መመምረጥ ይችላሉ፡፡
          </Text>
          <Text style={Styles.text_normal}>
            8.2. በዚህ አንቀጽ ንዑስ አንቀጽ 8.1 ስር የተገለጸው ቢኖርም አበዳሪው ወይም ተበዳሪው የራሳቸውን የሽምግልና ዳኛ ሳይመርጡ ቢቀሩ በአበዳሪው ህብረት ስራ ማህበር
            ጠቅላላ ጉባኤ የተመረጡ የሽምግልና ዳኞች ጉዳዩን አይተው ውሳኔ እንዲሰጡ አበዳሪና ተበዳሪ ስምምነት አድርገናል፡፡
          </Text>
          <Text style={Styles.text_normal}>
            8.3. በዚህ አንቀጽ ንዑስ አንቀጽ 8.2 መሰረት በሽምግልና ዳኞች የተሰጠው ውሳኔ በአበዳሪና በተበዳሪ መካከል ገዢ እንደሚሆን ስምምነት አድርገናል፡፡
          </Text>
          <View>
            <Text style={Styles.heading_one}> አንቀጽ ዘጠኝ ስለዋስትና </Text>
          </View>
          <Text style={Styles.text_normal}>
            እኔ ዋስ አቶ/ወ/ሮ/ወ/ት/ ___________________________________ የተባልኩ ግለሰብ ከዚህ በላይ በብድር ውሉ ላይ በሰፈረው አግባብ ከተበዳሪው ጋር
            ባልተነጣጠለ ኃላፊነት በአንድነትና በነጠላ ለመክፈል የተስማማሁ መሆኔን በተለመደው ፊርማዬ አረጋግጣለሁ፡፡
          </Text>
          {customer.isMarried && (
            <Text style={Styles.text_normal}>
              እኔም የተበዳሪ/ባል/ሚስት የሆንኩ አቶ/ወ/ሮ <TextBoldUnderlined text={customer?.spausedetail[0]?.amFullName} /> እዚህ የብድር
              ውል ላይ በተገለጸው ሙሉ የውል ቃል የተስማማሁና እኔም ለብድሩ ተጠያቂ ለመሆን የፈቀድኩ መሆኑን በተለመደው ፊርማዬ አረጋግጣለሁ፡፡
            </Text>
          )}

          <View>
            <Text style={Styles.heading_one}> ይህንን ውል ስንዋዋልና ስንፈራረም የነበሩ ምስክሮች </Text>
          </View>
          <View
            style={{
              marginTop: 10,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <View>
              <Text style={Styles.text_normal}> የአበዳሪ ስምና ፊርማ </Text>
              <Text style={Styles.text_normal}> ____________________________ </Text>
            </View>
            <View>
              <Text style={Styles.text_normal}> የዋስ ስምና ፊርማ </Text>
              <Text style={Styles.text_normal}> ____________________________ </Text>
            </View>
            <View>
              <Text style={Styles.text_normal}> የተበዳሪ ስምና ፊርማ </Text>
              <Text style={Styles.text_normal}> ____________________________ </Text>
            </View>
          </View>
          <View
            style={{
              marginTop: 10,
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <View>
              <Text style={Styles.text_normal}> የዋስ/ሚስት/ባል ስምና ፊርማ </Text>
              <Text style={Styles.text_normal}> ____________________________</Text>
            </View>
            <View>
              <Text style={Styles.text_normal}> የምስክሮች ስምና ፊርማ </Text>
              <Text style={Styles.text_normal}> ____________________________ </Text>
              <Text style={Styles.text_normal}> ____________________________ </Text>
              <Text style={Styles.text_normal}> ____________________________ </Text>
            </View>
            <View>
              <Text style={Styles.text_normal}> የተበዳሪ ባል/ሚስት ስምና ፊርማ </Text>
              <Text style={Styles.text_normal}> ____________________________ </Text>
            </View>
          </View>
          <Text style={Styles.footer} render={({ pageNumber, totalPages }) => `ገጽ ${pageNumber} - ${totalPages}`} />
        </Page>
      </Document>
    </PDFViewer>
  );
}

export default AgreementDoc;
