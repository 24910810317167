import React, { useState, useEffect } from 'react';
import { Alert, Button, ButtonGroup, Table } from 'react-bootstrap';
import { FaPencilAlt, FaTrashAlt } from 'react-icons/fa';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import axiosInstance from '../../axiosInstance';

function Regions() {
  const [t] = useTranslation('global');
  const { serverIP } = useSelector(store => store.allsettings);

  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);

  const [targetObj, setTargetObj] = useState({});

  const [allRegions, setallRegions] = useState([]);

  const [description, setdescription] = useState('');
  const [amDescription, setamDescription] = useState('');

  //   description, amDescription

  useEffect(() => {
    getallRegions();
  }, []);

  const getallRegions = () => {
    axiosInstance
      .get(`${serverIP}/regions/regions`)
      .then(function (response) {
        console.log(response.data);
        setallRegions(response.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const saveallRegions = () => {
    console.log('save');
    setModalAdd(false);
    axiosInstance
      .post(`${serverIP}/regions/regions`, {
        description: description,
        amDescription: amDescription
      })
      .then(function (response) {
        console.log(response);
        getallRegions();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const editallRegions = () => {
    axiosInstance
      .patch(`${serverIP}/regions/regions/${targetObj.id}`, {
        description: description,
        amDescription: amDescription
      })
      .then(function (response) {
        console.log(response);
        getallRegions();
        setModalEdit(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deleteallRegions = () => {
    axiosInstance
      .delete(`${serverIP}/regions/regions/${targetObj.id}`)
      .then(res => {
        console.log(res.data);
        setModalDelete(false);
        getallRegions();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const activateRecord = regid => {
    axiosInstance
      .patch(`${serverIP}/regions/regions/${regid}`, {
        isActive: true
      })
      .then(function (response) {
        console.log(response);
        getallRegions();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const deactivateRecord = regid => {
    axiosInstance
      .patch(`${serverIP}/regions/regions/${regid}`, {
        isActive: false
      })
      .then(function (response) {
        console.log(response);
        getallRegions();
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  return (
    <div>
      {/* Modal Delete Start  */}
      <Modal show={modalDelete} onHide={() => setModalDelete(false)}>
        <Modal.Header style={{ color: 'red' }} closeButton>
          <Modal.Title color="red"> {t('officer.delete')} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {t('admin.confirmDelete')} <strong> {targetObj.description} </strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={() => deleteallRegions()}>
            {t('officer.delete')}
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Delete End  */}
      {/* Modals Start  */}
      <Modal show={modalAdd} onHide={() => setModalAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{t('officer.add')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">ስም</InputGroup.Text>
            <Form.Control placeholder="ስም" onChange={e => setamDescription(e.target.value)} />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">Name</InputGroup.Text>
            <Form.Control placeholder="Name" onChange={e => setdescription(e.target.value)} />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => saveallRegions()}>
            {t('officer.save')}
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={modalEdit} onHide={() => setModalEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: 'orange' }}> አስተካክል </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">ስም</InputGroup.Text>
            <Form.Control value={amDescription} onChange={e => setamDescription(e.target.value)} />
          </InputGroup>
          <InputGroup className="mb-3">
            <InputGroup.Text id="basic-addon1">Name</InputGroup.Text>
            <Form.Control value={description} onChange={e => setdescription(e.target.value)} />
          </InputGroup>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="warning" onClick={() => editallRegions()}>
            {t('officer.edit')}
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modals End  */}

      <Alert className="px-3 flex flex-row justify-between py-1 items-center">
        <div> ክልሎች </div>
        <Button variant="primary" style={{ margin: 0 }} className="btn btn-sm" onClick={() => setModalAdd(true)}>
          {t('officer.Add')}
        </Button>
      </Alert>

      {allRegions.length > 0 && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>#</th>
              <th> ስም </th>
              <th> Name </th>
              <th> Status </th>
              <th>{t('auditor.action')}</th>
            </tr>
          </thead>
          <tbody>
            {allRegions.map(reg => {
              return (
                <tr>
                  <td>{reg.id}</td>
                  <td>{reg.amDescription}</td>
                  <td>{reg.description}</td>

                  <td>
                    {reg.isActive ? (
                      <Button
                        className="btn btn-sm btn-danger"
                        onClick={() => {
                          deactivateRecord(reg.id);
                        }}
                      >
                        Deactivate
                      </Button>
                    ) : (
                      <Button
                        className="btn btn-sm btn-primary"
                        onClick={() => {
                          activateRecord(reg.id);
                        }}
                      >
                        Activate
                      </Button>
                    )}
                  </td>
                  <td>
                    <ButtonGroup size="sm">
                      <Button variant="danger">
                        <FaTrashAlt
                          onClick={() => {
                            setModalDelete(true);
                            setTargetObj(reg);
                            console.log(reg);
                          }}
                        />
                      </Button>
                      <Button variant="warning">
                        <FaPencilAlt
                          onClick={() => {
                            setModalEdit(true);
                            setdescription(reg.description);
                            setamDescription(reg.amDescription);
                            setTargetObj(reg);
                          }}
                        />
                      </Button>
                    </ButtonGroup>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export default Regions;
