import React, { useEffect, useState } from 'react';
import { Alert, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import axiosInstance from '../axiosInstance';

function LoanApplications() {
  const { serverIP } = useSelector(store => store.allsettings);
  const [allApplications, setallApplications] = useState([]);

  useEffect(() => {
    getAllLoanApplications();
  }, []);

  const getAllLoanApplications = async () => {
    let allapp = await axiosInstance.get(`${serverIP}/ssloanrequest/ssloanrequest`);
    setallApplications(allapp.data);
  };

  return (
    <div>
      <Alert> በኦንላይን የተጠየቁ የብድር ማመልከቻዎች </Alert>
      {allApplications.length > 0 && (
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Phone Number</th>
              <th>Total_Saving </th>
              <th>Total_Shares </th>
              <th>Requested_Amount </th>
              <th>Multiplier </th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {allApplications.map(lnapp => {
              return (
                <tr>
                  <td>{lnapp.phonenumber}</td>
                  <td>{lnapp.total_savings}</td>
                  <td>{lnapp.amount_share}</td>
                  <td>{lnapp.requestedAmount}</td>
                  <td>{lnapp.multiplier}</td>
                  <td>
                    <Link
                      to={`/loanapplicationfollowup/${lnapp.id}`}
                      className="font-medium text-orange-400 underline hover:underline text-xl"
                    >
                      መከታተያ
                    </Link>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
}

export default LoanApplications;
