import React from 'react';
import { Text, Font, View, StyleSheet } from '@react-pdf/renderer';
import ebrima from '../font/ebrima.ttf';
import ebrimabold from '../font/ebrimabd.ttf';

import TextBoldUnderlined from '../Reusables/TextBoldUnderlined';
import NumberToWordDisplay from '../Reusables/NumberToWordDisplay';
import getEthiopicDate from '../../Common/modules/GregorianToEthiopian';

import { numberToWords } from '../../Common/modules/EnNumToWord';

const Styles = StyleSheet.create({
  heading_one: {
    marginTop: 6,
    marginBottom: 3,
    fontSize: 12,
    textAlign: 'center',
    fontFamily: 'ebrimabold',
    fontWeight: 'bold'
  },
  heading_two: {
    marginTop: 6,
    marginBottom: 3,
    fontSize: 10,
    fontFamily: 'ebrima',
    fontWeight: 'bold'
  },
  text_normal: {
    fontSize: 9,
    marginBottom: 3,
    fontFamily: 'ebrima'
  },

  rightView: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 8,
    fontFamily: 'ebrima',
    justifyContent: 'flex-end'
  }
});

Font.register({
  family: 'ebrima',
  src: ebrima
});

Font.register({
  family: 'ebrimabold',
  src: ebrimabold
});

function ArticleOne({ loan }) {
  const curdate = new Date();
  const etcurrdate =
    getEthiopicDate(curdate.getDay(), +' ' + curdate.getMonth() + 1, +' ' + curdate.getFullYear()) + ' ዓ.ም';

  const toEthiopic = date => {
    const [year, month, day] = date.split('-');
    return getEthiopicDate(parseInt(day), parseInt(month), parseInt(year));
  };

  return (
    <View>
      <View>
        <Text style={Styles.heading_two}>1.1. በዚህ ውል ውስጥ በተገለጸው ስምምነት መሰረት ተበዳሪው ከድርጅታችን የተበደሩት ብድር፦</Text>
      </View>
      <View>
        <Text style={{ ...Styles.text_normal, paddingLeft: 25 }}>
          1. ዋናው (principal) የብድር መጠን
          <TextBoldUnderlined text={loan.approvedPrincipal?.toLocaleString()} />
          <NumberToWordDisplay text={numberToWords(loan.approvedPrincipal)} />
        </Text>
        <Text style={{ ...Styles.text_normal, paddingLeft: 25 }}>
          2. የወለድ መጠን
          <TextBoldUnderlined text={loan.totalInterestPayment?.toLocaleString()} />
          <NumberToWordDisplay text={numberToWords(loan.totalInterestPayment)} />
        </Text>
        <Text style={{ ...Styles.text_normal, paddingLeft: 25 }}>
          3. ጠቅላላ ብድርና የወለድ መጠን
          <TextBoldUnderlined text={loan.approvedPrincipal?.toLocaleString()} />
          <NumberToWordDisplay text={numberToWords(loan.approvedPrincipal)} />
        </Text>
      </View>
      <View style={{ paddingTop: 10 }}>
        <Text style={Styles.text_normal}>
          እስከ <TextBoldUnderlined text={toEthiopic(loan.lastReturnDate)} /> ወይም ከዝያ ቀንና ዓ/ም በፊት ተከፍሎ ማለቅ ያለበት ብር ተቋሙ
          ለተበዳሪ አበድሯል፡፡ ተበዳሪ ገንዘቡን መቀበላቸውን አረጋግጠዋል፡፡ ተበዳሪው ለወሰዱት ብድር በየወሩ
          {<TextBoldUnderlined text={loan?.totalDueForPeriod?.toLocaleString()} />}
          <NumberToWordDisplay text={numberToWords(loan?.totalDueForPeriod)} />
          እንደሚከፍሉ እና ለብድሩ ዋስትና ይሆን ዘንድ አስያዥ በዚህ ውል ላይ የተጠቀሱት ንብረቶች ለተቋሙ በመያዣ ለመስጠት ስለተስማሙ ፣ ተዋዋዮቹ ከዚህ ቀጥሎ በተገለጸው ዐይነት
          ይሆን የመያዣ ዉል ተዋዉለዋል፡፡
        </Text>
        <Text style={Styles.text_normal}>
          ይህ የመያዣ ውል ተበዳሪ ከተቋሙ ጋር {<TextBoldUnderlined text={etcurrdate} />} የተዋዋሉተ የብድር ውል አንድ አካል ሆኖ ይቆጠራል፡፡ አስያዥ
          በተጠቀሰው የብድር ውል ውስጥ የተዘረዘሩት ገዴታዎች በሚገባ መረዳታቸውን የዉሉን አንድ ቅጂ መቀበላቸውን አረጋግጠዋል፡፡
        </Text>
      </View>
    </View>
  );
}

export default ArticleOne;
