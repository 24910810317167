import React from "react";
import { Field, ErrorMessage } from "formik";

function TextFormikInput(props) {
  return (
    <div style={{ width: "100%", paddingLeft: 5 }}>
      <label>{props.placeholder}</label>
      <Field
        className="form-control"
        placeholder={props.placeholder}
        type={props.type === null ? "text" : props.type}
        name={props.name}
        value={props.value}
      />
      <ErrorMessage
        style={{ color: "red" }}
        name={props.name}
        component="div"
      />
    </div>
  );
}

export default TextFormikInput;
