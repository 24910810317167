import React from 'react';
import { Text, StyleSheet } from '@react-pdf/renderer';

function NumberToWordDisplay(props) {
  const Styles = StyleSheet.create({
    dynamicText: {
      fontFamily: 'ebrimabold',
      color: 'black'
    }
  });

  return (
    <Text>
      <Text style={Styles.dynamicText}> ({props.text}) </Text> &nbsp;
    </Text>
  );
}

export default NumberToWordDisplay;
