import React from 'react';
import { Chart as chartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { Alert } from 'react-bootstrap';

chartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

function PLannerHome() {
  const Ddata = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'ወጪ (ከተፈቀደው ብድር)',
        backgroundColor: 'rgba(75,192,192,0.2)',
        borderColor: 'rgba(75,192,192,1)',
        borderWidth: 1,
        data: [12, 19, 3, 5, 2, 3]
      }
    ]
  };

  const Wdata = {
    labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
    datasets: [
      {
        label: 'ወጪ (ከተቀማጭ)',
        backgroundColor: 'rgba(100,100,100,0.2)',
        borderColor: 'rgba(50,50,50,1)',
        borderWidth: 1,
        data: [5, 8, 3, 5, 12, 9]
      }
    ]
  };

  const options = {};

  return (
    <div className="row">
      <div className="col-sm-6">
        <Alert>ወጪ (ከተፈቀደው ብድር)</Alert>
        <Bar
          style={{
            height: '300px',
            margin: '0 auto',
            width: '95%'
          }}
          data={Ddata}
          options={options}
        ></Bar>
      </div>

      <div className="col-sm-6">
        <Alert>ወጪ (ከተቀማጭ)</Alert>
        <Bar
          style={{
            height: '300px',
            margin: '0 auto',
            width: '95%'
          }}
          data={Wdata}
          options={options}
        ></Bar>
      </div>
    </div>
  );
}

export default PLannerHome;
