import { Font, StyleSheet } from '@react-pdf/renderer';
import ebrimanormal from '../font/ebrima.ttf';
import ebrimabold from '../font/ebrimabd.ttf';

Font.register({
  family: 'ebrimanormal',
  src: ebrimanormal
});

Font.register({
  family: 'ebrimabold',
  src: ebrimabold
});

const ReportStyle = StyleSheet.create({
  body: {
    flexDirection: 'column',
    padding: 20,
    fontSize: 12,
    height: '100%'
  },

  newbody: {
    flexDirection: 'column',
    padding: 40,
    fontSize: 12
  },

  prohibitionbody: {
    flexDirection: 'column',
    padding: 20,
    paddingLeft: 40,
    paddingRight: 40,
    fontSize: 12
  },
  h1: {
    fontFamily: 'ebrimanormal',
    fontSize: 12
  },
  h1b: {
    fontFamily: 'ebrimabold',
    fontSize: 12
  },
  h2: {
    fontFamily: 'ebrimanormal',
    fontSize: 11
  },
  h2b: {
    fontFamily: 'ebrimabold',
    fontSize: 11
  },
  h3: {
    fontFamily: 'ebrimanormal',
    fontSize: 10
  },
  h3b: {
    fontFamily: 'ebrimabold',
    fontSize: 10
  },
  h4: {
    fontFamily: 'ebrimanormal',
    fontSize: 9
  },
  h4b: {
    fontFamily: 'ebrimabold',
    fontSize: 9
  },
  h5: {
    fontFamily: 'ebrimanormal',
    fontSize: 8
  },
  h5b: {
    fontFamily: 'ebrimabold',
    fontSize: 8
  },
  h6: {
    fontFamily: 'ebrimanormal',
    fontSize: 7
  },
  h6b: {
    fontFamily: 'ebrimabold',
    fontSize: 7
  },

  subject: {
    fontSize: 13,
    textAlign: 'left',
    fontFamily: 'ebrimabold'
  },

  subjectCenter: {
    fontSize: 13,
    textAlign: 'center',
    fontFamily: 'ebrimabold'
  },

  rptwithdrawsubject: {
    paddingTop: 10,
    paddingBottom: 10,
    fontSize: 20,
    textAlign: 'center',
    fontFamily: 'ebrimabold',
    textDecoration: 'underline'
  },

  subjectRight: {
    fontSize: 13,
    textAlign: 'right',
    fontFamily: 'ebrimabold'
  },

  fullbox: {
    justifyContent: 'center',
    borderWidth: 1,
    fontSize: 11,
    borderColor: 'black',
    borderStyle: 'solid'
  },
  title: {
    fontSize: 18,
    textAlign: 'center',
    fontFamily: 'AmharicFont'
  },
  fullfreebox: {
    display: 'flex',
    justifyContent: 'flex-start',
    borderWidth: 1,
    fontSize: 11,
    borderColor: 'black',
    borderStyle: 'solid',
    height: 50
  },
  text: {
    marginTop: 1,
    fontSize: 9,
    fontFamily: 'AmharicFont',
    fontWeight: 'bold'
  },
  textpaddingleft30: {
    marginTop: 1,
    fontSize: 9,
    fontFamily: 'AmharicFont',
    fontWeight: 'bold',
    paddingLeft: 30
  },
  textCenter: {
    marginTop: 1,
    fontSize: 9,
    textAlign: 'center',
    fontFamily: 'AmharicFont',
    fontWeight: 'bold'
  },

  // for date and letter num
  textRight: {
    marginTop: 1,
    fontSize: 12,
    textAlign: 'right',
    fontFamily: 'AmharicFont',
    fontWeight: 'bold'
  },

  author: {
    fontSize: 14,
    textAlign: 'center',
    marginBottom: 10,
    fontFamily: 'AmharicFont',
    textDecoration: 'underline'
  },
  subtitle: {
    fontSize: 15,
    margin: 11,
    fontFamily: 'AmharicFont'
  },

  CarProhibition_ReporIimage: {
    height: 45,
    width: '100%'
  },

  // table design

  border_full: {
    fontFamily: 'AmharicFont',
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    paddingLeft: 10
  },

  border_rbl: {
    fontFamily: 'AmharicFont',
    borderTopWidth: 0,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderLeftWidth: 1,
    paddingLeft: 10
  },

  border_rb: {
    fontFamily: 'AmharicFont',
    borderTopWidth: 0,
    borderBottomWidth: 1,
    borderRightWidth: 1,
    borderLeftWidth: 0,
    paddingLeft: 10
  },
  border_less: {
    fontFamily: 'AmharicFont',
    borderTopWidth: 0,
    borderBottomWidth: 0,
    borderRightWidth: 0,
    borderLeftWidth: 0,
    paddingLeft: 10
  },

  subheadingcenter: {
    textAlign: 'center'
  }
});

export default ReportStyle;
