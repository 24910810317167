import React, { useEffect, useState } from 'react';
import { Card, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import axiosInstance from '../axiosInstance';

function FinanceHome() {
  const { serverIP } = useSelector(store => store.allsettings);

  const [withdrawals, setWithdrawals] = useState([]);
  const [disbursements, setDisbursements] = useState([]);

  useEffect(() => {
    getWithdrawals();
    getDisbursements();
  }, []);

  const getDisbursements = () => {
    axiosInstance
      .get(`${serverIP}/loan/disbursementPlannedLoans`)
      .then(res => {
        setDisbursements(res.data);
        console.log('Disbursements');
        console.log(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getWithdrawals = () => {
    axiosInstance
      .get(`${serverIP}/withdrawrows/atfinance`)
      .then(res => {
        setWithdrawals(res.data);
        console.log(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  function getDateDifferenceInDays(dateString) {
    // Parse the given date string into a Date object
    const givenDate = new Date(dateString);
    // Get today's date
    const today = new Date();
    // Calculate the difference in time (milliseconds)
    const differenceInTime = givenDate - today;
    // Convert the time difference from milliseconds to days
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));
    return differenceInDays;
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-sm-12">
          <Card>
            <Card.Header> ወጪ (ከተቀማጭ) </Card.Header>
            <Card.Body>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>መጠን</th>
                    <th> አመልካች </th>
                    <th>ቀሪ ቀናቶች </th>

                    <th>የአባላት አስተዳደር ሀላፊ </th>
                    <th>ፋይናንስ </th>
                    <th>አስተካክል </th>
                    <th>ሪፖርት </th>
                  </tr>
                </thead>
                <tbody>
                  {withdrawals.map(withdraw => {
                    return (
                      <tr key={withdraw.id}>
                        <td>{withdraw?.withdraw_amount?.toLocaleString()}</td>
                        <td>{withdraw?.parent?.amDisplayName}</td>
                        <td>{getDateDifferenceInDays(withdraw.appointmentDate)}</td>

                        <td> {withdraw.toAuditor ? 'ደርሷል' : 'ገና'} </td>
                        <td> {withdraw.toFinance ? 'ደርሷል' : 'ገና'} </td>
                        <td>
                          {withdraw.toEvaluator ? (
                            <Link
                              to={`financewithdrawchecking/${withdraw.id}`}
                              className="font-medium text-blue-600 dark:text-blue-300 underline hover:underline"
                            >
                              አስተካክል
                            </Link>
                          ) : (
                            'ገና'
                          )}
                        </td>
                        <td>
                          {withdraw?.financePerson !== null && (
                            <Link
                              to={`/rptwithdraw/${withdraw.id}`}
                              className="font-medium text-blue-600 dark:text-blue-300 underline hover:underline"
                            >
                              ሪፖርት
                            </Link>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        </div>
      </div>
      <div className="row pt-2">
        <div className="col-sm-12">
          <Card>
            <Card.Header> ወጪ (ከተፈቀደው ብድር) </Card.Header>
            <Card.Body>
              {disbursements.map(disburse => (
                <Card>
                  <Card.Header> {disburse.customer.amDisplayName} </Card.Header>
                  <Card.Body>
                    <Table striped bordered hover>
                      <thead>
                        <tr>
                          <th>መጠን</th>
                          <th>ቀሪ ቀናቶች</th>
                          <th>አስተካክል</th>
                          <th>ሪፖርት</th>
                        </tr>
                      </thead>
                      <tbody>
                        {disburse.disbursement_detail.map(detail => (
                          <tr>
                            <td>{detail.amount}</td>
                            <td>{getDateDifferenceInDays(detail.appointmentDate)}</td>
                            <td>
                              <Link
                                to={`/dfinancechecking/${detail.id}`}
                                className="font-medium text-blue-600 dark:text-blue-300 underline hover:underline"
                              >
                                አስተካክል
                              </Link>
                            </td>

                            <td>
                              {detail?.checkissuedby !== null ? (
                                <Link
                                  to={`/rptwithdraw/${detail.id}`}
                                  className="font-medium text-blue-600 dark:text-blue-300 underline hover:underline"
                                >
                                  ሪፖርት
                                </Link>
                              ) : (
                                <> ቼክ አልተሰጠም </>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              ))}
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
}

export default FinanceHome;
