import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { custLogin } from './Common/redux/customerAuthSlice';
import Logo from './bgno200.png';
import Image from 'react-bootstrap/Image';
import { useTranslation } from 'react-i18next';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import axios from 'axios';
import axiosInstance from './axiosInstance';

const Login = () => {
  const [t, i18n] = useTranslation('global');
  const { langName, serverIP } = useSelector(store => store.allsettings);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = e => {
    console.log('handleLogin object');
    axios
      .post(`${serverIP}/lpsauth/login`, {
        username: username,
        password: password
      })
      .then(function (response) {
        dispatch(custLogin({ responseData: response.data }));
        navigate('/');
      })
      .catch(function (error) {
        toast.error('Check Username & Password');
        console.log('error');
      });
    e.preventDefault();
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
      <ToastContainer autoClose={1000} />
      <div className="max-w-md w-full space-y-4">
        <div className="flex justify-center">
          <Image src={Logo} height="auto" width="250vh" rounded />
        </div>
        <form className="mt-8 space-y-6" onSubmit={handleLogin}>
          <input type="hidden" name="remember" value="true" />
          <div>
            <div>
              <label htmlFor="user name-address" className="font-mono">
                USER NAME
              </label>
              <input
                id="user name-address"
                name="username"
                type="text"
                required
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder="user name address"
                value={username}
                onChange={e => setUsername(e.target.value)}
              />
            </div>
            <div className="py-3">
              <label htmlFor="password" className="font-mono">
                PASSWORD
              </label>

              <div className="relative">
                <input
                  id="password"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  autoComplete="current-password"
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                  placeholder="Password"
                  value={password}
                  onChange={e => setPassword(e.target.value)}
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-0 px-3 py-2"
                >
                  {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
              </div>
            </div>
          </div>

          <div className="flex items-center justify-between">
            <div className="flex items-center">
              <input
                id="remember-me"
                name="remember-me"
                type="checkbox"
                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
              />
              <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
                Remember me
              </label>
            </div>

            <div className="text-sm">
              <Link to="/patchip" key="patchip">
                Patch
              </Link>
            </div>
          </div>

          <div>
            <button
              type="submit"
              className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-orange-500 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {/* {t("auth.login")} */}
              Log In
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
