import React, { useEffect, useState } from 'react';
import { Accordion, Alert, Button, Form, InputGroup, Modal, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setTargetLoan } from '../Common/redux/systemLookups';
import axiosInstance from '../axiosInstance';
import { FaEye, FaFileAlt } from 'react-icons/fa';
import { Link } from 'react-router-dom';

function Fivec({ successnotify, errornotify }) {
  const { targetLoan, targetClient } = useSelector(store => store.systemLookups);
  const { serverIP } = useSelector(store => store.allsettings);

  const [theFiveCData, setTheFiveCData] = useState([]);

  const [file, setFile] = useState(null);
  const [show, setShow] = useState(false);
  const [viewObj, setViewObj] = useState({});

  const [markValue, setmarkValue] = useState(0);
  const [thedatas, setthedatas] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    getFinanceItems();
  }, []);

  useEffect(() => {
    const categoryMap = new Map();
    targetLoan?.loan_fonance_items?.forEach(item => {
      if (!categoryMap.has(item.category)) {
        categoryMap.set(item.category, []);
      }
      categoryMap.get(item.category).push(item);
    });

    const groupedData = Array.from(categoryMap, ([category, items]) => ({ category, items }));
    setthedatas(groupedData);
  }, []);

  const getFinanceItems = () => {
    axiosInstance
      .get(`${serverIP}/loanfinanceitems/loanfinanceitemssloanid/${targetLoan.id}`)
      .then(res => {
        setTheFiveCData(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const loadData = async () => {
    try {
      const fndatas = await axiosInstance.get(`${serverIP}/loanfinancedata/loanfinancedatas`);
      console.log('fndatas');
      console.log(fndatas.data);

      const transformedArray = fndatas?.data.map(item => ({
        amDesc: item.amName,
        category: item.category,
        loan: targetLoan.id
      }));

      console.log('transformedArray');
      console.log(transformedArray);

      await axiosInstance.post(`${serverIP}/loanfinanceitems/loanfinanceitemss/bulkinsert`, transformedArray);
    } catch (error) {
    } finally {
      getLoan();
    }
  };

  const getLoan = () => {
    axiosInstance
      .get(`${serverIP}/loan/loans/${targetLoan.id}`)
      .then(res => {
        dispatch(
          setTargetLoan({
            targetLoan: res.data
          })
        );
      })
      .catch(err => {
        console.log(err);
      });
  };

  function handleUpload(fileid) {
    console.log(fileid);

    if (!file) {
      errornotify();
      return;
    }

    const fd = new FormData();
    fd.append('file', file);
    fd.append('fileType', file.type);
    fd.append('fileUrl', file);
    fd.append('isUploaded', true);

    axiosInstance
      .patch(`${serverIP}/loanfinanceitems/loanfinanceitemss/${fileid}`, fd, {
        headers: {
          'Custom-Header': 'value'
        }
      })
      .then(res => {
        getLoan();
        getFinanceItems();
        console.log('successnotify');
        successnotify();
      })
      .catch(err => {
        console.log('errornotify');
        errornotify();
      });
  }

  const handleDeleteFile = fileid => {
    axiosInstance
      .patch(`${serverIP}/loanfinanceitems/loanfinanceitemss/${fileid}`, {
        isUploaded: false
      })
      .then(res => {
        successnotify();
        getFinanceItems();
        getLoan();
      })
      .catch(err => {
        errornotify();
        console.log(err);
      });
  };

  const saveAmountInBirr = fileid => {
    axiosInstance
      .patch(`${serverIP}/loanfinanceitems/loanfinanceitemss/${fileid}`, {
        markValue: markValue
      })
      .then(res => {
        successnotify();
        getFinanceItems();
        getLoan();
      })
      .catch(err => {
        errornotify();
        console.log(err);
      });
  };

  return (
    <div>
      {/* Modal Show Start  */}
      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title> {viewObj.amDesc} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={serverIP + viewObj.fileUrl} style={{ width: '100%', height: '100%' }} />
        </Modal.Body>
      </Modal>
      {/* Modal Show End  */}

      <Alert className="px-3 flex flex-row justify-between py-1 items-center">
        <div> በደመወዝ ዋስትና የሚሰጥ የብድር ሰነድ ማረጋገጫ ዝርዝር </div>
        <div className="flex gap-3">
          <Link
            to={`/creditanalysis/${targetClient.id}/${targetLoan.id}`}
            className="font-medium text-orange-400 underline hover:underline text-xl"
          >
            ግምገማ
          </Link>
          <Button className="btn-sm" onClick={() => loadData()}>
            ሳብ
          </Button>
        </div>
      </Alert>
      {/* <div>{JSON.stringify(theFiveCData)}</div> */}

      <Accordion defaultActiveKey="1">
        {thedatas.length > 0 &&
          thedatas.map(thedata => {
            return (
              <Accordion.Item eventKey={thedata.category}>
                <Accordion.Header>{thedata.category}</Accordion.Header>
                <Accordion.Body>
                  <Table striped bordered hover style={{ margin: 1 }}>
                    <thead>
                      <tr>
                        <th> የፋይል ስም </th>
                        <th> እይ </th>
                        <th> አስፈላጊነት </th>
                        <th> የብር መጠን </th>
                      </tr>
                    </thead>
                    <tbody>
                      {thedata?.items?.length > 0 &&
                        thedata?.items?.map(item => (
                          <tr key={item.id}>
                            <td>{`${item.amDesc}`}</td>
                            <td>
                              {item.isUploaded ? (
                                <>
                                  {item.fileType === 'application/pdf' ? (
                                    <a target="_blank" href={serverIP + item.fileUrl} rel="noopener noreferrer">
                                      <FaFileAlt />
                                    </a>
                                  ) : (
                                    <FaEye
                                      onClick={() => {
                                        setViewObj(item);
                                        setShow(true);
                                        console.log('set show clicked');
                                      }}
                                    />
                                  )}
                                </>
                              ) : (
                                <></>
                              )}
                            </td>
                            <td style={{ padding: 0 }}>
                              <div className="flex gap-2 p-1">
                                <InputGroup>
                                  <input
                                    onChange={e => {
                                      setFile(e.target.files[0]);
                                      console.log(e.target.files[0]);
                                    }}
                                    type="file"
                                    className="form-control"
                                  />
                                  <Button variant="outline-primary" onClick={() => handleUpload(item.id)}>
                                    {!item.isUploaded ? <> ጫን </> : <>አዘምን</>}
                                  </Button>
                                </InputGroup>
                                {item.isUploaded && (
                                  <Button variant="outline-danger btn-sm" onClick={() => handleDeleteFile(item.id)}>
                                    አጥፋ
                                  </Button>
                                )}
                              </div>
                            </td>
                            <td style={{ padding: 0 }}>
                              <InputGroup className="p-1">
                                <InputGroup.Text>{item?.markValue?.toLocaleString()}</InputGroup.Text>
                                <Form.Control placeholder="" onChange={e => setmarkValue(e.target.value)} />
                                <Button
                                  variant="outline-secondary"
                                  id="button-addon2"
                                  onClick={() => saveAmountInBirr(item.id)}
                                >
                                  update
                                </Button>
                              </InputGroup>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            );
          })}
      </Accordion>
    </div>
  );
}

export default Fivec;
