import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeIP } from "./redux/settingSlice";
import { Link } from "react-router-dom";
// import { Player, Controls } from '@lottiefiles/react-lottie-player';

import Lottie from "lottie-react";
import Setting from "./Lottie/Setting.json";

function PatchIP() {
  const { serverIP } = useSelector((store) => store.allsettings);
  const [ipAddress, setIpAddress] = useState("");
  const dispatch = useDispatch();

  return (
    <div class="min-h-screen flex items-center justify-center bg-gray-100">
      <form class="bg-white p-6 rounded-lg shadow-md w-full max-w-sm">
        <div class="mb-4">
          <Lottie animationData={Setting} />
        </div>
        <div class="mb-4">
          <div> Current Server IP = {serverIP} </div>
        </div>
        <div class="mb-6">
          <input
            type="text"
            className="form-control"
            placeholder="Ip Address"
            onChange={(e) => setIpAddress(e.target.value)}
          />
        </div>
        <div class="mb-4 flex items-center justify-center">
          <button
            className="btn btn-outline-secondary"
            type="button"
            id="button-addon2"
            onClick={() => {
              dispatch(
                changeIP({
                  serverIP: ipAddress,
                })
              );
            }}
          >
            Change IP
          </button>
        </div>
        <div class=" flex items-center justify-center">
          <Link
            to="/"
            className="font-medium text-blue-600 dark:text-blue-300 underline hover:underline text-xl"
          >
            Back to Home
          </Link>
        </div>
      </form>
    </div>
  );
}

export default PatchIP;
