import React from 'react';
import { Text, Font, View, Image, StyleSheet } from '@react-pdf/renderer';
import AmigosLogo from '../Images/new.PNG';
import AmharicFont from '../font/ebrima.ttf';

const styles = StyleSheet.create({
  container: {
    marginTop: -15,
    display: 'flex',
    flexDirection: 'row',
    borderBottomWidth: 2,
    borderBottomColor: '#FF7700',
    borderBottomStyle: 'solid',
    justifyContent: 'space-between',
    paddingBottom: 5
  },
  ReporIimage: {
    height: 35,
    width: '30%'
  },
  name: {
    fontSize: 12,
    fontFamily: 'AmharicFont',
    justifyContent: 'center'
  }
});

Font.register({
  family: 'AmharicFont',
  src: AmharicFont
});

function GuaranteeHeader({ type }) {
  return (
    <View style={styles.container}>
      <Image style={styles.ReporIimage} src={AmigosLogo} />
      <View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Text style={styles.name}> የመያዣ ውል ስምምነት </Text>
          <Text style={styles.name}> {type} </Text>
        </View>
      </View>
      <Image style={styles.ReporIimage} src={AmigosLogo} />
    </View>
  );
}

export default GuaranteeHeader;
