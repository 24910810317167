// Check Extra Compulsary Amount Saved (totalCompulsary - 12,000 + 1000 * (numberofmonths))

function ExtraCompulsarySaved(customer, loan) {
  let noOfMonths = getDateDifferenceInDays(customer.memberSince);

  return loan.totalCompulsary - noOfMonths * 1000 - 12000;
}

// Check for extra Share available, with the following Constraints
// 1. Share must be greater than three years of age
// 2.
// 3.

function ShareAmountAndEligibilityCheck() {
  return {
    agegreaterthanthree: false,
    amount: 20000,
    withdrawamountallowed: 10000,
  };
}

function getDateDifferenceInDays(memberSince) {
  // Parse the given date string into a Date object
  const givenDate = new Date(memberSince);
  // Get today's date
  const today = new Date();
  // Calculate the difference in time (milliseconds)
  const differenceInTime = today - givenDate;
  // Convert the time difference from milliseconds to days
  const differenceInDays = Math.ceil(differenceInTime / (1000 * 60 * 60 * 24));

  const differenceInMonths = Math.ceil(
    differenceInTime / (1000 * 60 * 60 * 24 * 30)
  );

  return differenceInMonths;
}

module.exports = { ExtraCompulsarySaved, ShareAmountAndEligibilityCheck };
