import React, { useEffect, useState } from 'react';
import { Card, CardBody } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setTargetClient } from '../Common/redux/systemLookups';
import axiosInstance from '../axiosInstance';

function CustmerCompNew({ targetClient, successnotify, errornotify }) {
  const { serverIP } = useSelector(store => store.allsettings);
  const dispatch = useDispatch();
  const { customerId } = useParams();

  const [amFirstname, setamFirstname] = useState('');
  const [amMiddlename, setamMiddlename] = useState('');
  const [amLastname, setamLastname] = useState('');

  const [houseNum, sethouseNum] = useState('');
  const [monthlyIncome, setmonthlyIncome] = useState('');
  const [isMarried, setisMarried] = useState('');
  const [characterEvaluation, setCharacterEvaluation] = useState(0);

  const [phoneNum, setphoneNum] = useState('');
  const [idNum, setidNum] = useState('');
  const [memberSince, setmemberSince] = useState('');
  const [monthlySaving, setmonthlySaving] = useState('');
  const [loanType, setloanType] = useState('');

  const [lntypes, setlntypes] = useState([]);
  const [customerStatus, setcustomerStatus] = useState('');

  const [lstRegions, setlstRegions] = useState([]);
  const [lstZones, setlstZones] = useState([]);
  const [lstWoredas, setlstWoredas] = useState([]);


  
  


  const [regionId, setRegionId] = useState(0);
  const [zoneId, setZoneId] = useState(0);
  const [woredaId, setworedaId] = useState(0);

  useEffect(() => {
    getLocalCustomer();
    getLoanTypes();

    getRegions();
    getAllZones();
    getAllWoredas();

   
  }, []);

 

  const getRegions = () => {
    axiosInstance
      .get(`${serverIP}/regions/regions/activeregions`)
      .then(res => {
        setlstRegions(res.data);
        console.log('regions loaded');
        console.log(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getAllZones = regID => {
    axiosInstance
      .get(`${serverIP}/zones/zone`)
      .then(res => {
        setlstZones(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getAllWoredas = zonID => {
    axiosInstance
      .get(`${serverIP}/woredas/woreda`)
      .then(res => {
        console.log(res.data);
        setlstWoredas(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getAllZonesByRegionId = regID => {
    axiosInstance
      .get(`${serverIP}/zones/byparentid/${regID}`)
      .then(res => {
        setlstZones(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getAllWoredasByZoneId = zonID => {
    axiosInstance
      .get(`${serverIP}/woredas/byparentid/${zonID}`)
      .then(res => {
        console.log(res.data);
        setlstWoredas(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getLoanTypes = () => {
    axiosInstance
      .get(`${serverIP}/loantype/loantypes`)
      .then(res => {
        setlntypes(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getLocalCustomer = () => {
    axiosInstance
      .get(`${serverIP}/customer/flatcustomer/${customerId}`)
      .then(res => {
        console.log('Local Customer');
        console.log(res.data);

        setamFirstname(res.data.amFirstname);
        setamMiddlename(res.data.amMiddlename);
        setamLastname(res.data.amLastname);

        sethouseNum(res.data.houseNum);
        setmonthlyIncome(res.data.monthlyIncome);
        setisMarried(res.data.isMarried);

        setphoneNum(res.data.mobileNo);
        setidNum(res.data.idNum);
        setmemberSince(res.data.memberSince);
        setmonthlySaving(res.data.monthlySaving);
        setloanType(res.data.loanType);
        setCharacterEvaluation(res.data.characterEvaluation);

        setcustomerStatus(res.data.customerStatus);

        setRegionId(res.data.regionId.id);
        setZoneId(res.data.zoneId.id);
        setworedaId(res.data.woredaId.id);
        
      })
      .catch(err => {
        console.log(err);
      });
  };

  const updateCustomerRecord = () => {
    axiosInstance
      .patch(`${serverIP}/customer/customers/${targetClient.id}`, {
        amDisplayName: amFirstname + ' ' + amMiddlename + ' ' + amLastname,
        amFirstname: amFirstname,
        amMiddlename: amMiddlename,
        amLastname: amLastname,
        houseNum: houseNum,
        isMarried: isMarried,
        monthlyIncome: monthlyIncome,
        mobileNo: phoneNum,
        idNum: idNum,
        memberSince: memberSince,
        monthlySaving: monthlySaving,
        loanType: loanType,
        characterEvaluation: characterEvaluation,
        customerStatus: customerStatus,
        regionId: regionId,
        zoneId: zoneId,
        woredaId: woredaId,
        
      })
      .then(res => {
        console.log('success notfy');
        successnotify('የተበዳሪ መረጃ ተስተካክሏል!');
        console.log(res.data);
        getCustomer();
      })
      .catch(err => {
        errornotify();
      });
  };

  const getCustomer = async () => {
    try {
      const res = await axiosInstance.get(`${serverIP}/customer/flatcustomer/${customerId}`);
      dispatch(setTargetClient({ client: res.data }));
    } catch (err) {
      console.error('Error fetching customer:', err);
    }
  };

  return (
    <div>
    
   

      <Card>
        <CardBody>

          <div className="row">
            <div className="col-sm-4">
              <label className="form-label">ስም</label>
              <input
                value={amFirstname}
                type="text"
                className="form-control"
                onChange={e => setamFirstname(e.target.value)}
              />
            </div>
            <div className="col-sm-4">
              <label className="form-label">የአባት ስም</label>
              <input
                type="text"
                className="form-control"
                value={amMiddlename}
                onChange={e => setamMiddlename(e.target.value)}
              />
            </div>

            <div className="col-sm-4">
              <label className="form-label">የአያት ስም</label>
              <input
                type="text"
                className="form-control"
                value={amLastname}
                onChange={e => setamLastname(e.target.value)}
              />
            </div>

            {/* new comment start  */}

            <div className="col-sm-4">
              <label className="form-label">ስልክ ቁጥር</label>
              <input
                type="text"
                className="form-control"
                value={phoneNum}
                onChange={e => setphoneNum(e.target.value)}
              />
            </div>

            <div className="col-sm-4">
              <label className="form-label">የመታወቅያ ቁጥር</label>
              <input type="text" className="form-control" value={idNum} onChange={e => setidNum(e.target.value)} />
            </div>

            <div className="col-sm-4">
              <label className="form-label">አባል የሆኑበት ጊዜ</label>
              <input
                type="date"
                className="form-control"
                value={memberSince}
                onChange={e => setmemberSince(e.target.value)}
              />
            </div>

            <div className="col-sm-4">
              <label className="form-label">ወርሀዊ ቁጠባ</label>
              <input
                type="text"
                className="form-control"
                value={monthlySaving}
                onChange={e => setmonthlySaving(e.target.value)}
              />
            </div>

            <div className="col-sm-4">
              <label className="form-label">የብድር አይነት</label>
              <select className="form-control" value={loanType} onChange={e => setloanType(e.target.value)}>
                <option value="0"> የብድር አይነት ይምረጡ </option>
                {lntypes.map(ln => {
                  return (
                    <option key={ln.id} value={ln.id}>
                      {ln.amName}
                    </option>
                  );
                })}
              </select>
            </div>

            {/* new comment end  */}

            <div className="col-sm-4"></div>

            <div className="col-sm-4">
              <label> ክልል </label>

              <select
                as="select"
                onChange={e => {
                  setRegionId(e.target.value);
                  getAllZonesByRegionId(e.target.value);
                  console.log(e.target.value);
                }}
                className="form-control"
              >
                <option value="0"> ክልል ይምረጡ </option>
                {lstRegions.map(region => {
                  return (
                    <option key={region.id} selected={region.id === targetClient.regionId} value={region.id}>
                      {region.amDescription}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="col-sm-4">
              <label> ዞን (ክ/ከተማ) </label>
              <select
                as="select"
                value={zoneId}
                onChange={e => {
                  setZoneId(e.target.value);
                  getAllWoredasByZoneId(e.target.value);
                  console.log(e.target.value);
                }}
                className="form-control"
              >
                <option value="0"> ዞን (ክ/ከተማ) ይምረጡ </option>
                {lstZones.length > 0 &&
                  lstZones.map(zone => {
                    return (
                      <option key={zone.id} selected={zone.id === targetClient.zoneId} value={zone.id}>
                        {zone.amDescription}
                      </option>
                    );
                  })}
              </select>
            </div>

            <div className="col-sm-4">
              <label> ወረዳ </label>
              <select
                as="select"
                value={woredaId}
                onChange={e => {
                  setworedaId(e.target.value);
                }}
                className="form-control"
              >
                <option value="0"> ወረዳ ይምረጡ </option>
                {lstWoredas.length > 0 &&
                  lstWoredas.map(woreda => {
                    return (
                      <option key={woreda.id} value={woreda.id}>
                        {woreda.amDescription}
                      </option>
                    );
                  })}
              </select>
            </div>

            <div className="col-sm-4">
              <label className="form-label">የቤት ቁጥር</label>
              <input
                type="text"
                className="form-control"
                value={houseNum}
                onChange={e => sethouseNum(e.target.value)}
              />
            </div>

            <div className="col-sm-4">
              <label className="form-label">አጠቃላይ ወርሀዊ ገቢ</label>
              <input
                type="text"
                className="form-control"
                value={monthlyIncome}
                onChange={e => setmonthlyIncome(e.target.value)}
              />
            </div>

            <div className="col-sm-4">
              <label className="form-label">character evaluation</label>
              <input
                type="number"
                min="0"
                max="5"
                className="form-control"
                value={characterEvaluation}
                onChange={e => setCharacterEvaluation(e.target.value)}
              />
            </div>

            <div className="col-sm-4">
              <label className="form-label">Customer Status</label>
              <input
                type="number"
                min="0"
                max="5"
                className="form-control"
                value={customerStatus}
                onChange={e => setcustomerStatus(e.target.value)}
              />
            </div>

            <div className="col-sm-4">
              <label>.</label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={isMarried}
                  id="flexCheckDefault"
                  onChange={e => {
                    setisMarried(e.target.checked);
                  }}
                />
                <label className="form-check-label">Is Married</label>
              </div>
            </div>
            <div className="col-sm-4">
              <Button style={{ marginTop: 10 }} className="btn btn-warning" onClick={updateCustomerRecord}>
                አስተካክል
              </Button>
            </div>
          </div>
        </CardBody>
      </Card>
    </div>
  );
}

export default CustmerCompNew;
