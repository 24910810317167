const ones = [
  '',
  'አንድ',
  'ሁለት',
  'ሦስት',
  'አራት',
  'አምስት',
  'ስድስት',
  'ሰባት',
  'ስምንት',
  'ዘጠኝ',
  'አስር',
  'አስራ አንድ',
  'አስራ ሁለት',
  'አስራ ሶስት',
  'አስራ አራት',
  'አስራ አምስት',
  'አስራ ስድስት',
  'አስራ ሰባት',
  'አስራ ስምንት',
  'አስራ ዘጠኝ'
];

const tens = ['', '', 'ሀያ', 'ሰላሳ', 'አርባ', 'ሃምሳ', 'ስልሳ', 'ሰባ', 'ሰማንያ', 'ዘጠና'];

const scales = ['', 'ሺ', 'ሚልየን', 'ቢልየን', 'ትሪልየን'];

function convertHundreds(num) {
  let result = '';
  if (num > 99) {
    result += ones[Math.floor(num / 100)] + ' መቶ ';
    num %= 100;
  }
  if (num > 19) {
    result += tens[Math.floor(num / 10)] + ' ';
    num %= 10;
  }
  if (num > 0) {
    result += ones[num] + ' ';
  }
  return result.trim();
}

function numberToWords(theoriginalnumber) {
  let AmnewText = '';
  function isFloat(n) {
    return n === +n && n !== (n | 0);
  }
  if (isFloat(theoriginalnumber)) {
    let strnum = theoriginalnumber.toString();
    let dotindex = strnum.indexOf('.');

    let firstHalf = strnum.substring(0, dotindex);
    let secondHalf = strnum.substring(dotindex + 1);
    AmnewText = numberConvert(firstHalf);
    AmnewText = AmnewText + ' ብር ከ ' + numberConvert(secondHalf) + ' ሳንቲም ብቻ';
  } else {
    AmnewText = numberConvert(theoriginalnumber) + ' ብር ብቻ';
  }
  return AmnewText;
}

function numberConvert(num) {
  if (num === 0) return 'Zero';
  if (num < 0) return 'Negative ' + numberToWords(-num);

  let result = '';
  let scaleIndex = 0;

  while (num > 0) {
    const chunk = num % 1000;
    if (chunk > 0) {
      result = convertHundreds(chunk) + ' ' + scales[scaleIndex] + ' ' + result;
    }
    num = Math.floor(num / 1000);
    scaleIndex++;
  }

  return result.trim();
}

// percent

function numberToWordsPercent(theoriginalnumber) {
  let AmnewText = '';
  AmnewText = numberConvert(theoriginalnumber) + ' ከመቶ';
  return AmnewText;
}

export { numberToWords, numberToWordsPercent };
