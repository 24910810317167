import axiosInstance from '../axiosInstance';
import React, { useEffect, useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';

import CollateralSalarySchema from '../Schema/CollateralSalarySchema';
import { Accordion, Alert, Badge, Button, Card, InputGroup, Table } from 'react-bootstrap';
import TextFormikInput from '../ReusableFormiks/TextFormikInput';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-bootstrap/Modal';
import { FaEye, FaFileAlt, FaMoneyBillWave } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import { setTargetLoan } from '../Common/redux/systemLookups';
import TextInputTw from '../ReusableFormiks/TextInputTw';

function CollateralSalaryComp({ targetLoan, successnotify, errornotify }) {
  const { data } = useSelector(store => store.customer);
  const { serverIP } = useSelector(store => store.allsettings);

  const dispatch = useDispatch();
  const { loanId } = useParams();
  const [modalAdd, setModalAdd] = useState(false);
  const [modalEdit, setModalEdit] = useState(false);
  const [modalDelete, setModalDelete] = useState(false);
  const [targetObj, setTargetObj] = useState({});

  const [lstRegions, setlstRegions] = useState([]);
  const [lstZones, setlstZones] = useState([]);
  const [lstWoredas, setlstWoredas] = useState([]);

  const [regionId, setRegionId] = useState(0);
  const [zoneId, setZoneId] = useState(0);
  const [woredaId, setworedaId] = useState(0);

  const [show, setShow] = useState(false);
  const [viewObj, setViewObj] = useState([]);
  const [file, setFile] = useState(null);

  // for updates
  const [empName, setempName] = useState('');
  const [empAmName, setempAmName] = useState('');
  const [grossSalary, setgrossSalary] = useState('');
  const [checkListId, setcheckListId] = useState('');
  const [companyname, setcompanyname] = useState('');
  const [companyage, setcompanyage] = useState('');
  const [mobileNo, setmobileNo] = useState('');
  const [empPhoneNum, setempPhoneNum] = useState('');
  const [idNum, setidNum] = useState('');
  const [letterNum, setletterNum] = useState('');
  const [ddlcompanytype, setddlcompanytype] = useState('');

  useEffect(() => {
    getCompanyTypes();

    getRegions();
    getAllZones();
    getAllWoredas();
  }, []);

  const getCompanyTypes = () => {
    axiosInstance
      .get(`${serverIP}/ddlcompanytype/ddlcompanytypes`)
      .then(res => {
        setddlcompanytype(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getRegions = () => {
    axiosInstance
      .get(`${serverIP}/regions/regions/activeregions`)
      .then(res => {
        setlstRegions(res.data);
        console.log('regions loaded');
        console.log(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getAllZones = regID => {
    axiosInstance
      .get(`${serverIP}/zones/zone`)
      .then(res => {
        setlstZones(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getAllWoredas = zonID => {
    axiosInstance
      .get(`${serverIP}/woredas/woreda`)
      .then(res => {
        console.log(res.data);
        setlstWoredas(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getAllZonesByRegionId = regID => {
    axiosInstance
      .get(`${serverIP}/zones/byparentid/${regID}`)
      .then(res => {
        setlstZones(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getAllWoredasByZoneId = zonID => {
    axiosInstance
      .get(`${serverIP}/woredas/byparentid/${zonID}`)
      .then(res => {
        console.log(res.data);
        setlstWoredas(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const saveSalaryCollateral = values => {
    console.log(values);
    axiosInstance
      .post(`${serverIP}/collateral_employee/employees`, {
        empName: values.empAmName,
        empAmName: values.empAmName,
        grossSalary: values.grossSalary,
        loan: loanId,
        employeeCompany: values.checkListId,
        companyname: values.companyname,
        companyage: values.companyage,
        mobileNo: values.mobileNo,
        empPhoneNum: values.empPhoneNum,
        idNum: values.idNum,
        letterNum: values.letterNum,

        regionId: values.regionId,
        zoneId: values.zoneId,
        woredaId: values.woredaId
      })
      .then(res => {
        console.log(res.data);
        setModalAdd(false);
        getLoan();
      })
      .catch(err => {
        console.log(err);
      });
  };

  const deleteEmployeeRecord = () => {
    axiosInstance
      .delete(`${serverIP}/collateral_employee/employees/${targetObj.id}`)
      .then(res => {
        console.log(res.data);
        getLoan();
        setModalDelete(false);
      })
      .catch(err => {
        console.log(err);
      });
  };

  const getLoan = () => {
    axiosInstance
      .get(`${serverIP}/loan/loans/${targetLoan.id}`)
      .then(res => {
        dispatch(
          setTargetLoan({
            targetLoan: res.data
          })
        );
      })
      .catch(err => {
        console.log(err);
      });
  };

  function handleUpload(fileid) {
    if (!file) {
      errornotify();
      return;
    }

    const fd = new FormData();
    fd.append('file', file);
    fd.append('fileType', file.type);
    fd.append('fileUrl', file);
    fd.append('isUploaded', true);

    axiosInstance
      .patch(`${serverIP}/collateral_employee_files/employeefiles/${fileid}`, fd, {
        headers: {
          'Custom-Header': 'value'
        }
      })
      .then(res => {
        getLoan();
        successnotify();
      })
      .catch(err => {
        errornotify();
      });
  }

  const handleDeleteFile = fileid => {
    axiosInstance
      .patch(`${serverIP}/collateral_employee_files/employeefiles/${fileid}`, {
        isUploaded: false
      })
      .then(res => {
        successnotify();
        getLoan();
      })
      .catch(err => {
        errornotify();
        console.log(err);
      });
  };

  const reLoadData = async () => {
    try {
      const deleteOldData = await axiosInstance.delete(
        `${serverIP}/collateral_employee_files/employeefiles/deletebyparent/${targetObj.id}`
      );
    } catch (error) {
    } finally {
      getLoan();
    }
  };

  const loadData = async empId => {
    try {
      const ddlSalaryChecklist = await axiosInstance.get(`${serverIP}/ddlsalary/ddlsalaries`);

      const transformedArray = ddlSalaryChecklist?.data.map(item => ({
        parent: empId,
        user: data.id,
        amDesc: item.amName,
        checkListId: item.id,
        isMandatory: item.isMandatory,
        markValue: item.markValue
      }));

      await axiosInstance.post(`${serverIP}/collateral_employee_files/employeefiles/bulkinsert`, transformedArray);
    } catch (error) {
    } finally {
      getLoan();
    }
  };

  const editSalary = () => {
    axiosInstance
      .patch(`${serverIP}/collateral_employee/employees/${targetObj.id}`, {
        empName: empName,
        empAmName: empAmName,
        grossSalary: grossSalary,
        employeeCompany: checkListId,
        companyname: companyname,
        companyage: companyage,
        mobileNo: mobileNo,
        empPhoneNum: empPhoneNum,
        idNum: idNum,
        letterNum: letterNum,

        regionId: regionId,
        zoneId: zoneId,
        woredaId: woredaId
      })
      .then(res => {
        console.log(res.data);
        setModalEdit(false);
        getLoan();
      })
      .catch(err => {
        console.log(err);
      });
  };

  return (
    <div>
      {/* Modal Start  */}
      <Modal size="lg" show={show} onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title> {viewObj.amDesc} </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <img src={serverIP + viewObj.fileUrl} style={{ width: '100%', height: '100%' }} />
        </Modal.Body>
      </Modal>
      {/* Modal End  */}

      {/* Modals Start  */}
      {/* Modal ADD Start  */}
      <Modal size="lg" show={modalAdd} onHide={() => setModalAdd(false)}>
        <Modal.Header closeButton>
          <Modal.Title> አዲስ የደመውዝ ዋስትና </Modal.Title>
        </Modal.Header>
        <Formik
          initialValues={{
            empAmName: '',
            grossSalary: '',
            checkListId: '',
            companyname: '',
            companyage: '',
            mobileNo: '',
            empPhoneNum: '',
            idNum: '',
            letterNum: ''
            // regionId: "",
            // zoneId: "",
            // woredaId: "",
          }}
          validationSchema={CollateralSalarySchema}
          onSubmit={saveSalaryCollateral}
        >
          {({ errors, setFieldValue }) => (
            <>
              <Form>
                <Modal.Body style={{ width: '100%', display: 'flex' }}>
                  <div style={{ width: '49%' }}>
                    <Card>
                      <Card.Header> ተቀጣሪ </Card.Header>
                      <Card.Body>
                        <TextFormikInput name="empAmName" placeholder="ሙሉ ስም" />
                        <TextFormikInput name="grossSalary" type="number" placeholder="ሙሉ ደመወዝ" />
                        <TextFormikInput name="empPhoneNum" placeholder="ስልክ" />

                        <TextFormikInput name="idNum" placeholder="የመታውቅያ ቁጥር" />

                        <div className="w-full">
                          <label> ክልል </label>
                          <Field
                            as="select"
                            name="regionId"
                            onChange={e => {
                              setFieldValue('regionId', e.target.value);
                              getAllZonesByRegionId(e.target.value);
                            }}
                            className="form-control"
                            style={{
                              border: errors.regionId ? '1px solid red' : ''
                            }}
                          >
                            <option value=""> ክልል ይምረጡ </option>
                            {lstRegions.length > 0 &&
                              lstRegions.map(region => {
                                return (
                                  <option key={region.id} value={region.id}>
                                    {region.amDescription}
                                  </option>
                                );
                              })}
                          </Field>
                          <ErrorMessage style={{ color: 'red' }} name="regionId" component="div" />
                        </div>

                        <div className="w-full">
                          <label> ዞን (ክ/ከተማ) </label>
                          <Field
                            as="select"
                            name="zoneId"
                            className="form-control"
                            onChange={e => {
                              setFieldValue('zoneId', e.target.value);
                              getAllWoredasByZoneId(e.target.value);
                              console.log(e.target.value);
                            }}
                            style={{
                              border: errors.zoneId ? '1px solid red' : ''
                            }}
                          >
                            <option value=""> ዞን (ክ/ከተማ) ይምረጡ </option>
                            {lstZones.length > 0 &&
                              lstZones.map(zone => {
                                return (
                                  <option key={zone.id} value={zone.id}>
                                    {zone.amDescription}
                                  </option>
                                );
                              })}
                          </Field>
                          <ErrorMessage style={{ color: 'red' }} name="zoneId" component="div" />
                          <div className="w-full">
                            <label> ወረዳ </label>
                            <Field
                              as="select"
                              name="woredaId"
                              className="form-control"
                              style={{
                                border: errors.woredaId ? '1px solid red' : ''
                              }}
                            >
                              <option value=""> ወረዳ ይምረጡ </option>
                              {lstWoredas.length > 0 &&
                                lstWoredas.map(woreda => {
                                  return (
                                    <option key={woreda.id} value={woreda.id}>
                                      {woreda.amDescription}
                                    </option>
                                  );
                                })}
                            </Field>
                            <ErrorMessage style={{ color: 'red' }} name="woredaId" component="div" />
                          </div>{' '}
                        </div>

                        <TextFormikInput name="letterNum" placeholder="የደብዳቤ ቁጥር" />
                      </Card.Body>
                    </Card>
                  </div>
                  <div style={{ marginLeft: '2%', width: '49%' }}>
                    <Card>
                      <Card.Header> የቀጣሪ ድርጅት </Card.Header>
                      <Card.Body>
                        <div style={{ paddingTop: 5, paddingLeft: 5 }}>
                          <label> የድርጅት አይነት </label>
                          <Field
                            as="select"
                            name="checkListId"
                            className="form-control"
                            style={{
                              border: errors.checkListId ? '1px solid red' : ''
                            }}
                          >
                            <option value="0"> የድርጅት አይነት ይምረጡ </option>
                            {ddlcompanytype?.length > 0 &&
                              ddlcompanytype.map(compt => {
                                return (
                                  <option key={compt.id} value={compt.id}>
                                    {compt.amName}
                                  </option>
                                );
                              })}
                          </Field>
                          <ErrorMessage style={{ color: 'red' }} name="checkListId" component="div" />
                        </div>

                        <TextFormikInput name="companyname" placeholder="የድርጅት ስም" />
                        <TextFormikInput name="companyage" placeholder="የድርጅት እድሜ" type="number" />
                        <TextFormikInput name="mobileNo" placeholder="ስልክ" />
                      </Card.Body>
                    </Card>
                  </div>
                </Modal.Body>
                <Modal.Footer>
                  <Button type="submit" variant="primary btn-sm">
                    አስቀምጥ
                  </Button>
                </Modal.Footer>
              </Form>
            </>
          )}
        </Formik>
      </Modal>
      {/* Modal Add End  */}

      {/* Modal Edit Start  */}
      <Modal size="lg" show={modalEdit} onHide={() => setModalEdit(false)}>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: 'orange' }}>የደመውዝ ዋስትና ማስተካከያ</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ width: '100%', display: 'flex' }}>
          <div style={{ width: '49%' }}>
            <Card>
              <Card.Header> ተቀጣሪ </Card.Header>
              <Card.Body>
                <TextInputTw placeholder="ሙሉ ስም" onChange={e => setempAmName(e.target.value)} value={empAmName} />
                <TextInputTw placeholder="ሙሉ ደመወዝ" onChange={e => setgrossSalary(e.target.value)} value={grossSalary} />
                <TextInputTw placeholder="ስልክ" onChange={e => setempPhoneNum(e.target.value)} value={empPhoneNum} />
                <TextInputTw placeholder="የመታውቅያ ቁጥር" onChange={e => setidNum(e.target.value)} value={idNum} />

                <div className="w-full">
                  <label>ክልል</label>

                  <select onChange={e => setRegionId(e.target.value)} className="form-control">
                    <option value="0"> ክልል </option>
                    {lstRegions.length > 0 &&
                      lstRegions.map(reg => {
                        return (
                          <option
                            key={reg.id}
                            selected={reg.id === targetLoan?.collateralemployee?.regionId?.id}
                            value={reg.id}
                          >
                            {reg.amDescription}
                          </option>
                        );
                      })}
                  </select>
                </div>

                <div className="w-full">
                  <label>ዞን (ክ/ከተማ) </label>

                  <select onChange={e => setZoneId(e.target.value)} className="form-control">
                    <option value="0"> ዞን (ክ/ከተማ) </option>
                    {lstZones.length > 0 &&
                      lstZones.map(zone => {
                        return (
                          <option
                            key={zone.id}
                            selected={zone.id === targetLoan?.collateralemployee?.zoneId?.id}
                            value={zone.id}
                          >
                            {zone.amDescription}
                          </option>
                        );
                      })}
                  </select>
                </div>

                <div className="w-full">
                  <label>ወረዳ</label>
                  <select onChange={e => setworedaId(e.target.value)} className="form-control">
                    <option value="0"> ወረዳ </option>
                    {lstWoredas.length > 0 &&
                      lstWoredas.map(woreda => {
                        return (
                          <option
                            key={woreda.id}
                            selected={woreda.id === targetLoan?.collateralemployee?.woredaId?.id}
                            value={woreda.id}
                          >
                            {woreda.amDescription}
                          </option>
                        );
                      })}
                  </select>
                </div>

                <TextInputTw placeholder="የደብዳቤ ቁጥር" onChange={e => setletterNum(e.target.value)} value={letterNum} />
              </Card.Body>
            </Card>
          </div>
          <div style={{ marginLeft: '2%', width: '49%' }}>
            <Card>
              <Card.Header> የቀጣሪ ድርጅት </Card.Header>
              <Card.Body>
                <div className="py-2">
                  የድርጅት አይነት ይምረጡ
                  <select onChange={e => setcheckListId(e.target.value)} className="form-control">
                    <option value="0"> የድርጅት አይነት ይምረጡ </option>
                    {ddlcompanytype?.length > 0 &&
                      ddlcompanytype.map(compt => {
                        return (
                          <option selected={compt.id === targetObj?.checkListId} key={compt.id} value={compt.id}>
                            {compt.amName}
                          </option>
                        );
                      })}
                  </select>
                </div>

                <TextInputTw
                  placeholder="የድርጅት ስም"
                  onChange={e => setcompanyname(e.target.value)}
                  value={companyname}
                />
                <TextInputTw placeholder="የድርጅት እድሜ" onChange={e => setcompanyage(e.target.value)} value={companyage} />
                <TextInputTw placeholder="ስልክ" onChange={e => setmobileNo(e.target.value)} value={mobileNo} />
              </Card.Body>
            </Card>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button type="submit" variant="warning btn-sm" onClick={() => editSalary()}>
            አስተካክል
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Edit End  */}

      {/* Modal Delete Start  */}
      <Modal show={modalDelete} onHide={() => setModalDelete(false)}>
        <Modal.Header style={{ color: 'red' }} closeButton>
          <Modal.Title color="red"> አጥፋ </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you wnt to delete <strong> {targetObj.enName} </strong>?
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={deleteEmployeeRecord} variant="danger">
            አጥፋ
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Modal Delete End  */}
      {/* Modals End  */}

      <Alert className="px-3 flex flex-row justify-between py-1 items-center">
        <div style={{ fontSize: 20, fontWeight: 'bold', color: 'GrayText' }}>የደመወዝ መያዣ</div>
        {data.groups[0] === 'ROLE_OFFICER' && (
          <Button
            onClick={() => setModalAdd(true)}
            className="flex flex-row items-center gap-2"
            disabled={targetLoan.teamleaderchecking}
          >
            <FaMoneyBillWave /> ጨምር
          </Button>
        )}
      </Alert>

      <div className="row">
        <div className="col-sm-12" style={{ paddingTop: 5 }}>
          {Object.keys(targetLoan)?.length > 0 ? (
            <Accordion defaultActiveKey="1">
              {targetLoan.collateralemployee.map(sal => {
                return (
                  <Accordion.Item eventKey={sal.id}>
                    <Accordion.Header>
                      <div className="flex flex-row items-center gap-2">
                        <FaFileAlt />{' '}
                        <Badge>
                          {sal.salaryfiles?.filter(x => x.isUploaded === true)?.length}/ {sal.salaryfiles?.length}
                        </Badge>
                        {`${sal.empAmName} @ ${sal.companyname} Gross Salary ${sal.grossSalary?.toLocaleString()}`}
                      </div>
                    </Accordion.Header>
                    <Accordion.Body>
                      <div className="flex flex-row p-2 justify-between bg-slate-500 mb-1 text-white">
                        <div>የደመወዝ ዋስትና</div>
                        <div className="btn-group" role="group" aria-label="Basic example">
                          <button
                            type="button"
                            onClick={() => {
                              setModalEdit(true);
                              console.log(sal);
                              setTargetObj(sal);
                              setempName(sal.empName);
                              setempAmName(sal.empAmName);
                              setgrossSalary(sal.grossSalary);
                              setcheckListId(sal.checkListId);
                              setcompanyname(sal.companyname);
                              setcompanyage(sal.companyage);
                              setmobileNo(sal.mobileNo);
                              setempPhoneNum(sal.empPhoneNum);
                              setidNum(sal.idNum);
                              setletterNum(sal.letterNum);
                            }}
                            className="btn btn-sm btn-warning"
                          >
                            አስተካክል
                          </button>
                          <button
                            onClick={() => {
                              setTargetObj(sal);
                              setModalDelete(true);
                              console.log('object deleted');
                            }}
                            className="btn btn-sm btn-danger"
                          >
                            አጥፋ
                          </button>
                        </div>
                      </div>
                      {sal.salaryfiles?.length > 0 ? (
                        <>
                          <Alert
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between'
                            }}
                            className="mt-2 py-2 items-center"
                          >
                            <div> በደመወዝ ዋስትና የሚሰጥ የብድር ሰነድ ማረጋገጫ ዝርዝር </div>
                            <Button
                              className="btn btn-warning btn-sm"
                              onClick={() => {
                                setTargetObj(sal);
                                reLoadData();
                              }}
                            >
                              የፋይል ዝርዝር አጥፋ
                            </Button>
                          </Alert>

                          <Table striped bordered hover style={{ margin: 1 }}>
                            <thead>
                              <tr>
                                <th> የፋይል ስም </th>
                                <th> እይ </th>
                                <th> አስፈላጊነት </th>
                                <th> ድርጊት </th>
                                <th> አጥፋ </th>
                              </tr>
                            </thead>
                            <tbody>
                              {sal.salaryfiles.map(upload => {
                                return (
                                  <tr className={upload.isUploaded ? `` : `table-warning`}>
                                    <td>{upload.amDesc}</td>
                                    <td>
                                      {upload.isUploaded ? (
                                        <>
                                          {upload.fileType === 'application/pdf' ? (
                                            <a
                                              target="_blank"
                                              href={serverIP + upload.fileUrl}
                                              rel="noopener noreferrer"
                                            >
                                              <FaFileAlt />
                                            </a>
                                          ) : (
                                            <FaEye
                                              onClick={() => {
                                                setViewObj(upload);
                                                setShow(true);
                                              }}
                                            />
                                          )}
                                        </>
                                      ) : (
                                        <></>
                                      )}
                                    </td>
                                    <td>{upload.isMandatory ? 'ግዴታ' : 'አማራጭ'}</td>
                                    <td style={{ width: '30%', padding: 0 }}>
                                      {data.groups[0] === 'ROLE_OFFICER' ? (
                                        <>
                                          <InputGroup style={{ paddingTop: 5 }}>
                                            <input
                                              onChange={e => {
                                                setFile(e.target.files[0]);
                                                console.log(e.target.files[0]);
                                              }}
                                              type="file"
                                              className="form-control"
                                            />
                                            <Button variant="outline-primary" onClick={() => handleUpload(upload.id)}>
                                              {!upload.isUploaded ? <> ጫን </> : <>አዘምን</>}
                                            </Button>
                                          </InputGroup>
                                        </>
                                      ) : (
                                        <>{upload.isUploaded ? 'ተሰቅሏል' : ' '}</>
                                      )}
                                    </td>
                                    <td style={{ width: '10%', padding: 0 }}>
                                      {upload.isUploaded && (
                                        <Button
                                          variant="outline-danger"
                                          onClick={() => {
                                            handleDeleteFile(upload.id);
                                            setModalDelete(true);
                                            console.log('object deleted');
                                          }}
                                        >
                                          አጥፋ
                                        </Button>
                                      )}
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </Table>
                        </>
                      ) : (
                        <Alert className="px-3 flex flex-row justify-between py-1 items-center">
                          <div> በደመወዝ ዋስትና የሚሰጥ የብድር ሰነድ ማረጋገጫ ዝርዝር </div>
                          <Button className="btn-sm" onClick={() => loadData(sal.id)}>
                            ሳብ
                          </Button>
                        </Alert>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>
                );
              })}
            </Accordion>
          ) : (
            <>Bring Files</>
          )}
        </div>
      </div>
    </div>
  );
}

export default CollateralSalaryComp;
