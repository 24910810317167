import React from 'react';
import { Document, Font, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
import ebrima from './font/ebrima.ttf';
import ebrimabold from './font/ebrimabd.ttf';
import GuaranteeHeader from './Reusables/GuaranteeHeader';

import GuaranteeStyles from './Styles/ReportStyle';
import TextBoldUnderlined from './Reusables/TextBoldUnderlined';
import getEthiopicDate from '../Common/modules/GregorianToEthiopian';
import { numberToWords } from '../Common/modules/EnNumToWord';
import TextBold from './Reusables/TextBold';

const Styles = StyleSheet.create({
  heading_one: {
    marginTop: 6,
    marginBottom: 3,
    fontSize: 12,
    textAlign: 'center',
    fontFamily: 'ebrimabold',
    fontWeight: 'bold'
  },
  heading_two: {
    marginTop: 6,
    marginBottom: 3,
    fontSize: 10,
    fontFamily: 'ebrima',
    fontWeight: 'bold'
  },
  text_normal: {
    fontSize: 11,
    marginBottom: 4,
    fontFamily: 'ebrima',
    marginTop: 4
  },

  rightView: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 8,
    fontFamily: 'ebrima',
    justifyContent: 'flex-end'
  }
});

Font.register({
  family: 'ebrima',
  src: ebrima
});

Font.register({
  family: 'ebrimabold',
  src: ebrimabold
});

function RptCarGuarantee({ loan, car }) {
  const curdate = new Date();
  const etcurrdate = getEthiopicDate(curdate.getDate(), +' ' + curdate.getMonth() + 1, +' ' + curdate.getFullYear());

  return (
    <PDFViewer style={{ width: '100%', height: 700 }}>
      <Document title="የመኪና መያዣ">
        <Page style={GuaranteeStyles.body} wrap>
          <GuaranteeHeader type="የመኪና" />
          {Object.keys(loan).length > 0 ? (
            <>
              <View style={{ paddingBottom: 5 }}></View>
              {/* የውል ሰጪ እና ውል ተቀባይ ኣድራሻ START */}
              <View>
                <Text style={Styles.heading_one}>የውሉ ዓላማ </Text>
              </View>
              <View>
                <View>
                  {car?.cargp === null ? (
                    <>
                      <Text style={Styles.text_normal}>
                        ይህ የመያዣ ውል ሊደረግ የቻለው በአበዳሪ አሚጎስ የገንዘብ ቁጠባና ብድር ኃ/የተ/ህ/ስ/ማህበር እና በተበዳሪ
                        {<TextBoldUnderlined text={loan.customer.amDisplayName} />} መካከል በ
                        <TextBoldUnderlined text={etcurrdate} /> ቀን በተፈጸመ የብድር ውል መነሻ ሲሆን ለብደሩ መመለስ ዋስትና ይሆን ዘንድ ንብረትነቱ
                        የ {<TextBoldUnderlined text={loan.customer?.amDisplayName} />} የሆነውን የሰሌዳ ቁጥሩ
                        {<TextBoldUnderlined text={car.carPlate} />} የሆነውን ተሽከርካሪ ለማስያዝ ነው፡፡
                      </Text>
                    </>
                  ) : (
                    <>
                      <Text style={Styles.text_normal}>
                        ይህ የመያዣ ውል ሊደረግ የቻለው በአበዳሪ አሚጎስ የገንዘብ ቁጠባና ብድር ኃ/የተ/ህ/ስ/ማህበር እና በተበዳሪ
                        {<TextBoldUnderlined text={loan.customer.amDisplayName} />} መካከል በ
                        <TextBoldUnderlined text={etcurrdate} /> ቀን በተፈጸመ የብድር ውል መነሻ ሲሆን ለብደሩ መመለስ ዋስትና ይሆን ዘንድ ንብረትነቱ
                        የ <TextBoldUnderlined text={car.cargp?.fullname} /> የሆነውን የሰሌዳ ቁጥሩ
                        {<TextBoldUnderlined text={car.carPlate} />} የሆነውን ተሽከርካሪ ለማስያዝ ነው፡፡
                      </Text>
                    </>
                  )}
                </View>
              </View>
              {/* የውል ሰጪ እና ውል ተቀባይ ኣድራሻ END */}

              <View>
                <Text style={Styles.heading_one}> የዉሉ ዝርዝር </Text>
              </View>

              <View>
                <Text style={Styles.text_normal}>
                  1. እኔ አቶ/ወ/
                  <TextBoldUnderlined
                    text={`${car.cargp === null ? loan?.customer?.amDisplayName : car.cargp.fullname}`}
                  />
                  .ንብረትነቱ የእኔ የሆነ ተሽከርካሪ የሰሌዳ ቁጥሩ {<TextBoldUnderlined text={car.carPlate} />} የሆነ
                  {<TextBoldUnderlined text={car?.model?.enName} />} በመባል የሚጠራ ተሸከርካሪ በአበዳሪ አሚጎስ የገንዘብ ቁጠባና ብድር
                  ኃ/የተ/ህ/ስ/ማህበር <TextBoldUnderlined text={etcurrdate} /> ቀን በተፈጸመ የብድር ውል መሰረት የተበደርኩትን ገንዘብ ለመመለስ ዋስትና
                  ይሆን ዘንድ በመያዣነት እንዲያዝ መፍቀዴን በተለመደው ፊርማዬ አረጋግጣለሁ፡፡
                </Text>
                <Text style={Styles.text_normal}>
                  2. እኔም በአበዳሪ አሚጎስ የገንዘብ ቁጠባና ብድር ኃ/የተ/ህ/ስ/ማህበር ከላይ በተራ ቁጥር 1 ስር ዝርዝር መግለጫው የተጠቀሰው ተሽከርካሪ ተበዳሪው ለተበደሩት
                  ገንዘብ ዋስትና ይሆን ዘንድ በመያዣነት ለመያዝ ተስማምቻለሁ፡፡
                </Text>
                <Text style={Styles.text_normal}>
                  3. ተጠቃሹን ተሸርካሪ በመያዣነት ከተያዘበት ቀን ጀምሮ ብድሩ ተከፍሎ እስከሚጠናቀቅበት ጊዜ ድረስ እንዳይሸጥ ፣ እንዳይለወጥ በሌላ በማንኛውም ምክንያት ወደ 3ኛ
                  ወገን እንዳይተላለፍ እና ምንም ዓይነት ጉዳት ሳይደርስበት እየተንከባከብኩ የመጠበቅ ኃላፊነቱን እኔ ተበዳሪው የወሰድኩ መሆኑን በተለመደው ፊርማዬ አረጋግጣለሁ፡፡
                </Text>
                <Text style={Styles.text_normal}>
                  4. ተበዳሪው ምን አልባት የተበደርኩትን ገንዘብ በወቅቱና በጊዜው መክፈል ቢያቅተኝ ፣ በየወሩ መክፈል የሚጠበቅብኝን የብድር ገንዘብ
                  <TextBoldUnderlined text={loan?.totalDueForPeriod?.toLocaleString()} />
                  <TextBold text={` (${numberToWords(loan.totalDueForPeriod)}) `} /> ለተከታታይ 2/ሁለት/ወራት ሳልከፍል ብቆይ በአበዳሪ
                  አሚጎስ የገንዘብ ቁጠባና ብድር ኃ/የተ/ህ/ስ/ማህበር ተሸከርካሪውን ከሚመለከታቸው የህግ አስከባሪ አካላት/ትራፊክ ፖሊሶች ጋር በመሆን ከተገኘበት ቦታ በመያዝና
                  እንዲቆም በማድግ ለሚፈልገው ወገን በመሸጥ የብድሩን ገንዘብ ወስዶ ቀሪ ተመላሽ ካለ እንዲመልስልኝ የተስማማሁ መሆኔን በተለመደው ፊርማዬ አረጋግጣለሁ፡፡
                </Text>
                <Text style={Styles.text_normal}>
                  5. ከላይ በተራ ቁጥር 4 ስር የተገለጸው እንደተጠበቀ ሆኖ ተሸከርካሪው የተሸጠበት ዋጋ ከብድር ገንዘቡ የሚያንስ ቢሆን አበዳሪው ቀሪውን ገንዘብ ለማስመለስ
                  በተበዳሪው ላይ ክስ የመመስረትም ሆነ የተለያዩ አማራጮችን በመጠቀም ገንዘቡን የማስመለስ መብቱ በሕግ የተጠበቀ ነው፡፡
                </Text>
                <Text style={Styles.text_normal}>
                  6. እኔ አበዳሪ አሚጎስ የገንዘብ ቁጠባና ብድር ኃ/የተ/ህ/ስ/ማህበር ተበዳሪው ከላይ በተራ ቁጥር 4 ስር በተገለጸው አግባብ የተበደሩትን ገንዘብ በወቅቱ መክፈል
                  አቅቷቸው ለተከታታይ 2(ሁለት) ወራት ቢያቋርጡ ተሸከርካሪውን ሽጬ ገንዘቡን ለመውሰድና የቀረ ተመላሽ ገንዘብ የሚኖር ቢሆን ለተበዳሪው ለመመለስ ተስማምቻለሁ፡፡
                </Text>
                <Text style={Styles.text_normal}>
                  7. ይህ የመያዣ ውል በቀን <TextBoldUnderlined text={etcurrdate} /> በግራና ቀኛችን አካል የተፈጸመ የብድር ውል ስምምነት አካል እንዲሆን
                  ስምምነት አድርገናል፡፡
                </Text>
                <Text style={Styles.text_normal}>
                  እኔም የተበዳሪው ባል/ሚስት በብድር ውሉና በዚህ የመያዣ ውል የተስማማሁና ኃላፊነቱን ለመውሰድ የፈቀድኩ መሆኑን በተለመደው ፊርማዬ አረጋግጣለሁ፡፡
                </Text>
                <Text style={Styles.text_normal}>ይህ ውል በፍ/ብ/ሥ/ሥ/ሕ/ቁ 1723/2005 መሰረት በሕግ ፊት የጸና ነው</Text>
              </View>

              <View
                style={{
                  marginTop: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <View>
                  <Text style={Styles.text_normal}> የአበዳሪ ስምና ፊርማ </Text>
                  <Text style={Styles.text_normal}> ____________________________ </Text>
                </View>
                <View>
                  <Text style={Styles.text_normal}> የዋስ ስምና ፊርማ </Text>
                  <Text style={Styles.text_normal}> ____________________________ </Text>
                </View>
                <View>
                  <Text style={Styles.text_normal}> የተበዳሪ ስምና ፊርማ </Text>
                  <Text style={Styles.text_normal}> ____________________________ </Text>
                </View>
              </View>

              <View
                style={{
                  marginTop: 10,
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between'
                }}
              >
                <View>
                  <Text style={Styles.text_normal}> የዋስ/ሚስት/ባል ስምና ፊርማ </Text>
                  <Text style={Styles.text_normal}> ____________________________</Text>
                </View>
                <View>
                  <Text style={Styles.text_normal}> የምስክሮች ስምና ፊርማ </Text>
                  <Text style={Styles.text_normal}> ____________________________ </Text>
                  <Text style={Styles.text_normal}> ____________________________ </Text>
                  <Text style={Styles.text_normal}> ____________________________ </Text>
                </View>
                <View>
                  <Text style={Styles.text_normal}> የተበዳሪ ባል/ሚስት ስምና ፊርማ </Text>
                  <Text style={Styles.text_normal}> ____________________________ </Text>
                </View>
              </View>
            </>
          ) : (
            <></>
          )}
          <View
            style={{
              textAlign: 'right',
              position: 'absolute',
              bottom: 30,
              right: 35,
              fontSize: 10
            }}
          >
            <Text style={Styles.text_normal}>
              ውሉ ያዘጋጀው፦
              <TextBoldUnderlined text="______________________________" />
            </Text>
            <Text style={Styles.text_normal}>ፊርማ: ______________ </Text>
          </View>
        </Page>
      </Document>
    </PDFViewer>
  );
}

export default RptCarGuarantee;
