import React from 'react';
import { Document, Font, Page, Text, View, StyleSheet } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';

import ebrima from './font/ebrima.ttf';
import ebrimabold from './font/ebrimabd.ttf'; // bold
import ReportStyle from './Styles/ReportStyle';

import GuaranteeHeader from './Reusables/GuaranteeHeader';
import GuaranteeStyles from './Styles/ReportStyle';
import TextBoldUnderlined from './Reusables/TextBoldUnderlined';
import getEthiopicDate from '../Common/modules/GregorianToEthiopian';
import Trc from './Reusables/Trc';
import Tr from './Reusables/Tr';
import NumberToWordDisplay from './Reusables/NumberToWordDisplay';

import { numberToWords } from '../Common/modules/EnNumToWord';

const Styles = StyleSheet.create({
  heading_one: {
    marginTop: 6,
    marginBottom: 3,
    fontSize: 12,
    textAlign: 'center',
    fontFamily: 'ebrimabold',
    fontWeight: 'bold'
  },
  heading_two: {
    marginTop: 6,
    marginBottom: 3,
    fontSize: 10,
    fontFamily: 'ebrima',
    fontWeight: 'bold'
  },
  text_normal: {
    fontSize: 9,
    marginBottom: 3,
    fontFamily: 'ebrima'
  },

  rightView: {
    display: 'flex',
    flexDirection: 'row',
    fontSize: 8,
    fontFamily: 'ebrima',
    justifyContent: 'flex-end'
  }
});

Font.register({
  family: 'ebrima',
  src: ebrima
});

Font.register({
  family: 'ebrimabold',
  src: ebrimabold
});

function RptShareGuarantee({ loan, stock }) {
  const curdate = new Date();
  const etcurrdate =
    getEthiopicDate(curdate.getDay(), +' ' + curdate.getMonth() + 1, +' ' + curdate.getFullYear()) + ' ዓ.ም';

  const toEthiopic = date => {
    const [year, month, day] = date.split('-');
    return getEthiopicDate(parseInt(day), parseInt(month), parseInt(year));
  };

  return (
    <PDFViewer style={{ width: '100%', height: 700 }}>
      <Document title="የአክስዮን መያዣ">
        <Page style={GuaranteeStyles.body} wrap>
          <GuaranteeHeader type="የአክስዮን" />
          <View style={{ paddingBottom: 5 }}></View>
          {/* የውል ሰጪ እና ውል ተቀባይ ኣድራሻ START */}
          <View>
            <Text style={Styles.heading_one}>የውል ሰጪ እና ውል ተቀባይ ኣድራሻ</Text>
          </View>
          <View>
            <View>
              {stock?.stockgp === null ? (
                <>
                  <Text style={Styles.text_normal}>
                    ከዚህ ቀጥሎ "አስያዥ" እየተባሉ የሚጠቀሱት
                    {<TextBoldUnderlined text={loan.customer.amDisplayName} />}
                  </Text>
                  <Text style={Styles.text_normal}>
                    ኣድራሻ ክ/ከተማ &nbsp;
                    {<TextBoldUnderlined text={loan.customer.amSubcity} />}
                    ወረዳ
                    {<TextBoldUnderlined text={loan.customer.woreda} />}
                    የቤት/ቁጥር
                    {<TextBoldUnderlined text={loan.customer.houseNum} />}
                    የመታወቅያ/ቁጥር {<TextBoldUnderlined text="የመታወቅያ/ቁጥር" />}
                    ስልክ ቁጥር
                    {<TextBoldUnderlined text={loan.customer.mobileNo} />}
                  </Text>
                </>
              ) : (
                <>
                  <Text style={Styles.text_normal}>
                    ከዚህ ቀጥሎ "አስያዥ" እየተባሉ የሚጠቀሱት
                    {<TextBoldUnderlined text={stock?.stockgp.fullname} />}
                  </Text>
                  <Text style={Styles.text_normal}>
                    ኣድራሻ ክ/ከተማ
                    {<TextBoldUnderlined text={stock?.stockgp.subcity} />}
                    ወረዳ
                    {<TextBoldUnderlined text={stock?.stockgp.woreda} />}
                    የቤት/ቁጥር
                    {<TextBoldUnderlined text={stock?.stockgp.housenum} />}
                    የመታወቅያ/ቁጥር {<TextBoldUnderlined text="የመታወቅያ/ቁጥር" />}
                    ስልክ ቁጥር
                    {<TextBoldUnderlined text={stock?.stockgp.phonenum} />}
                  </Text>
                </>
              )}

              <Text style={Styles.text_normal}>ከዚህ ቀጥሎ "ተቋም" እየተባለ የሚጠራው አሚጎስ የቁጠባና ብድር ህብረት ስራ ማህበር(ህስማ)</Text>
              <Text style={Styles.text_normal}>
                አድራሻ፦ ፖስታሳ.ቁ፦
                <TextBoldUnderlined text="20732/1000፤አዲስ አበባ" />
                ስልክ ቁጥር፦ <TextBoldUnderlined text="+251-111-2676-57" />
              </Text>
              <Text style={Styles.text_normal}>
                ይህንን የመያዣ ውል ዛሬ {<TextBoldUnderlined text={etcurrdate} />}
                ቀጥሎ በተዘረዘረው ዓይነት ተዋውለዋል፡፡
              </Text>
            </View>
          </View>
          {/* የውል ሰጪ እና ውል ተቀባይ ኣድራሻ END */}

          <View>
            <Text style={Styles.heading_one}>አንቀጽ 1 ፡ የብድር መጠን ከነ ውሉ </Text>
          </View>
          <View>
            <Text style={Styles.heading_two}>1.1. በዚህ ውል ውስጥ በተገለጸው ስምምነት መሰረት ተበዳሪው ከድርጅታችን የተበደሩት ብድር፦</Text>
          </View>
          <View>
            <Text style={{ ...Styles.text_normal, paddingLeft: 25 }}>
              1. ዋናው (principal) የብድር መጠን
              <TextBoldUnderlined text={loan.r_totalInterestPayment?.toLocaleString()} />
              <NumberToWordDisplay text={numberToWords(loan.r_totalInterestPayment)} />
            </Text>
            <Text style={{ ...Styles.text_normal, paddingLeft: 25 }}>
              2. የወለድ መጠን
              <TextBoldUnderlined text={(loan.r_totalInterestPayment - loan.r_approvedPrincipal)?.toLocaleString()} />
              <NumberToWordDisplay text={numberToWords(loan.r_totalInterestPayment - loan.r_approvedPrincipal)} />
            </Text>
            <Text style={{ ...Styles.text_normal, paddingLeft: 25 }}>
              3. ጠቅላላ ብድርና የወለድ መጠን
              <TextBoldUnderlined text={loan.r_approvedPrincipal?.toLocaleString()} />
              <NumberToWordDisplay text={numberToWords(loan.r_approvedPrincipal)} />
            </Text>
          </View>
          <View style={{ paddingTop: 10 }}>
            <Text style={Styles.text_normal}>
              እስከ <TextBoldUnderlined text={toEthiopic(loan.lastReturnDate)} /> ወይም ከዝያ ቀንና ዓ/ም በፊት ተከፍሎ ማለቅ ያለበት ብር ተቋሙ
              ለተበዳሪ አበድሯል፡፡ ተበዳሪ ገንዘቡን መቀበላቸውን አረጋግጠዋል፡፡ ተበዳሪው ለወሰዱት ብድር በየወሩ
              {<TextBoldUnderlined text={loan?.totalDueForPeriod?.toLocaleString()} />}
              <NumberToWordDisplay text={numberToWords(loan?.totalDueForPeriod)} />
              እንደሚከፍሉ እና ለብድሩ ዋስትና ይሆን ዘንድ አስያዥ በዚህ ውል ላይ የተጠቀሱት ንብረቶች ለተቋሙ በመያዣ ለመስጠት ስለተስማሙ ፣ ተዋዋዮቹ ከዚህ ቀጥሎ በተገለጸው
              ዐይነት ይሆን የመያዣ ዉል ተዋዉለዋል፡፡
            </Text>
            <Text style={Styles.text_normal}>
              ይህ የመያዣ ውል ተበዳሪ ከተቋሙ ጋር {<TextBoldUnderlined text={etcurrdate} />} የተዋዋሉተ የብድር ውል አንድ አካል ሆኖ ይቆጠራል፡፡ አስያዥ
              በተጠቀሰው የብድር ውል ውስጥ የተዘረዘሩት ገዴታዎች በሚገባ መረዳታቸውን የዉሉን አንድ ቅጂ መቀበላቸውን አረጋግጠዋል፡፡
            </Text>
          </View>

          {/* አንቀጽ 2 ፡ የብድር ዋስትና መጠን START */}
          <View>
            <Text style={Styles.heading_one}>አንቀጽ 2 ፡ የብድር ዋስትና መጠን </Text>
          </View>
          <View>
            <Text style={Styles.text_normal}>
              አስያዥ እስከ
              <TextBoldUnderlined text={(loan.r_totalInterestPayment + loan.r_approvedPrincipal)?.toLocaleString()} />
              <NumberToWordDisplay text={numberToWords(loan.r_totalInterestPayment + loan.r_approvedPrincipal)} />
              ለሚደርስ ተበዳሪ ላልከፈሉት ብድር ለወለድና ለማንኛውም ወጪና ኪሳራ ማስከፈያ ከዚህ ቀጥሎ ባለው ሰንጠረዥ ውስጥ የተጠቀሱት ንብረቶች በመያዣነት ለተቋሙ ሰጥተዋል፡፡
            </Text>
          </View>

          <View>
            <Text style={{ ...Styles.heading_two }}>2.2. በአስያዥ የቀረቡት ዋስትናዎች</Text>
          </View>

          {/* አንቀጽ 2 ፡ የብድር ዋስትና መጠን END  */}

          <View style={{ backgroundColor: 'orange' }}>
            <Text style={{ ...Styles.heading_two, textAlign: 'center' }}>አጠቃላይ ለብድሩ የተያዙ ዋስትናዎች</Text>
          </View>

          <View>
            <Text style={Styles.heading_two}>2.2.1. የተበዳሪው አክስዮን ፣ በተቋሙ ውስጥ ያለ ቁጠባና ወለድ</Text>

            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Trc borders="t1r1b1l1" val="ስም ከነ አያት" width="25%" />
              <Trc borders="t1r1b1l1" val="መደባኛ ቁጠባ" width="20%" />
              <Trc borders="t1r1b1l1" val="የአክስዮን ቁጠባ" width="20%" />
              <Trc borders="t1r1b1l1" val="የፍቃደኝነት ቁጠባ" width="15%" />
              <Trc borders="t1r1b1l1" val="ድምር መጠን" width="20%" />
            </View>
            <View style={{ display: 'flex', flexDirection: 'row' }}>
              <Trc borders="t1r1b1l1" val={loan.customer.amDisplayName} width="25%" />
              <Trc borders="t1r1b1l1" val={loan.totalSaving} width="20%" />
              <Trc borders="t1r1b1l1" val={loan.totalShares} width="20%" />
              <Trc borders="t1r1b1l1" val="val" width="15%" />
              <Trc borders="t1r1b1l1" val={loan.totalSaving + loan.totalShares} width="20%" />
            </View>
          </View>
          <View>
            <Text style={Styles.heading_two}>2.2.2. ተጨማሪ ዋስትናዎች</Text>
          </View>

          {/* Fifth half start ለብድር ዋስትና የቀረቡ ዋስትናዎች */}
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <Tr style={ReportStyle.h5b} thekey=" ለብድር ዋስትና የቀረቡ ዋስትናዎች  " width="25%" borders="t1r1b1l1" />
            <Tr style={ReportStyle.h5b} thekey=" የዋስትና አቅራቢ ስም " width="23%" borders="t1r1b1l0" />
            <Tr style={ReportStyle.h5b} thekey=" የብር መጠን " width="13%" borders="t1r1b1l0" />
            <Tr style={ReportStyle.h5b} thekey=" ክ/ከተማ " width="13%" borders="t1r1b1l0" />
            <Tr style={ReportStyle.h5b} thekey=" ወረዳ " width="13%" borders="t1r1b1l0" />
            <Tr style={ReportStyle.h5b} thekey=" የመ/ቁጥር " width="13%" borders="t1r1b1l0" />
          </View>

          {/* collateralstock  */}
          {loan.collateralstock?.length > 0 &&
            loan.collateralstock.map(stock => {
              return (
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                  <Tr thekey=" ሼር  " width="25%" borders="t0r1b1l1" />
                  <Tr
                    thekey={' ' + loan.customer?.amDisplayName || stock.stockgp?.fullname}
                    width="23%"
                    borders="t0r1b1l0"
                  />
                  <Tr thekey={stock.stockqty * stock.priceperstock} width="13%" borders="t0r1b1l0" />
                  <Tr thekey={stock.stockgp?.subcity || loan.customer?.amSubcity} width="13%" borders="t0r1b1l0" />
                  <Tr thekey={stock.stockgp?.woreda || loan.customer?.woreda} width="13%" borders="t0r1b1l0" />
                  <Tr
                    thekey={stock.stockgp?.idnum || (loan.customer?.idNum == null ? ' ' : loan.customer?.idNum)}
                    width="13%"
                    borders="t0r1b1l0"
                  />
                </View>
              );
            })}

          {/* Fifth half start ለብድር ዋስትና የቀረቡ ዋስትናዎች */}

          {loan.customer?.isMarried && loan.customer?.spausedetail?.length > 0 && (
            <View>
              <Text style={Styles.text_normal}>
                የተበዳሪ ባለቤት (spause) የሆኑት
                {<TextBoldUnderlined text={loan.customer?.spausedetail[0]?.amFullName} />}
                &nbsp; ስልክ ቁጥር
                {<TextBoldUnderlined text={loan.customer?.spausedetail[0]?.mobileNo} />}
              </Text>
              <Text style={Styles.text_normal}>
                ኣድራሻ ክ/ከተማ
                {<TextBoldUnderlined text={loan.customer?.spausedetail[0]?.amSubcity} />}
                &nbsp; ወረዳ
                {<TextBoldUnderlined text={loan.customer?.spausedetail[0]?.woreda} />}
                &nbsp; የቤት/ቁጥር
                {<TextBoldUnderlined text={loan.customer?.spausedetail[0]?.houseNum} />}
                &nbsp; የመታወቅያ/ቁጥር {<TextBoldUnderlined text="የመቁ" />}
              </Text>
            </View>
          )}

          {/* footer start  */}

          <View style={{ paddingTop: 10 }}>
            <Text style={Styles.text_normal}>
              በዚህ ውል የተጠቀሰዉን የተበዳሪ ግዴታ ሁሉ ወደውና ተስማምተው መቀበላቸውን በፊርማቸው አረጋግጠዋል፡፡ ይህ ውል ከላይ በተጠቀሰው ቀንና ዓ.ም ስማቸው ከታች የተጠቀሰው
              ምስክሮች ባሉበት ተፈርሟል፡፡
            </Text>
          </View>

          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <View>
              <Text style={Styles.text_normal}> ________________ </Text>
              <Text style={Styles.text_normal}> የተቋሙ ውል ሰጪ ስምና ፊርማ </Text>
            </View>
            <View>
              <Text style={Styles.text_normal}> ________________ </Text>
              <Text style={Styles.text_normal}> የዋስ ስምና ፊርማ </Text>
            </View>
            <View>
              <Text style={Styles.text_normal}> ________________ </Text>
              <Text style={Styles.text_normal}> የዋስ ባለቤት ስምና ፊርማ </Text>
            </View>
          </View>

          <View style={{ padding: 10 }}>
            <Text style={Styles.text_normal}>
              አንድ ቅጂ ውስጃልው &nbsp; <TextBoldUnderlined text={loan.customer.amDisplayName} />
              &nbsp; ፊርማ፡ ___________________
            </Text>
          </View>

          <View>
            <Text style={Styles.text_normal}>ምስክሮች</Text>
          </View>

          <View
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between'
            }}
          >
            <View>
              <Text style={Styles.text_normal}> ቃለወንጌል አድማሱ </Text>
              <Text style={Styles.text_normal}>ፊርማ፡ ________________</Text>
            </View>
            <View>
              <Text style={Styles.text_normal}> አላዛር ካሳ ሀይሌ </Text>
              <Text style={Styles.text_normal}>ፊርማ፡ ________________</Text>
            </View>
            <View>
              <Text style={Styles.text_normal}> ሰላማዊት ጻዲቅ </Text>
              <Text style={Styles.text_normal}>ፊርማ፡ ________________</Text>
            </View>
          </View>
          <View
            style={{
              textAlign: 'right',
              position: 'absolute',
              bottom: 30,
              right: 35,
              fontSize: 10
            }}
          >
            <Text style={Styles.text_normal}>
              ውሉ ያዘጋጀው፦
              <TextBoldUnderlined text="ቃልኪዳን ደረጄ" />{' '}
            </Text>
            <Text style={Styles.text_normal}>ፊርማ: ______________ </Text>
          </View>
          {/* footer End  */}
        </Page>
      </Document>
    </PDFViewer>
  );
}

export default RptShareGuarantee;
